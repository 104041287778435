import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage,injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ModalP } from '..';
import config from '../../config';
import { PulseSpinner } from "react-spinners-kit";

import css from './ModalLoading.css';

class ModalLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceClose: false,
    }
  }

  render() {
    const {
      rootClassName,
      className,
      containerClassName,
      onManageDisableScrolling,
      showLoadingModal,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const content = (
      <div>
        <PulseSpinner size={70} color="#fcbc04" loading={true} />
      </div>
    );

    return (
      <ModalP
        id="ModalLoading"
        containerClassName={containerClassName}
        isOpen={showLoadingModal}
        disableCloseBtn={true}
        onManageDisableScrolling={onManageDisableScrolling}
        forceClose={false}
      >
        {content}
      </ModalP>
    );
  }
}

ModalLoading.defaultProps = {
  className: null,
  rootClassName: null,
  forceClose: false,
  shareLink: null,
};

ModalLoading.propTypes = {
  className: string,
  rootClassName: string,
  containerClassName: string,
  onManageDisableScrolling: func.isRequired,
};

ModalLoading.displayName = 'ModalLoading';

export default injectIntl(ModalLoading);
