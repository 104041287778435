import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage,injectIntl, intlShape } from '../../util/reactIntl';
import { getShareListingLink, SHARE_TO_WHATSAPP, SHARE_TO_TWITTER, SHARE_TO_LINK, SHARE_TO_EMAIL } from '../../util/shareLinkHelper';
import classNames from 'classnames';
import { Modal } from '..';

import config from '../../config';

import css from './ModalSharingListing.css';
import FacebookLogo from './facebook-letter-logo.svg';
import TwitterLogo from './twitter-logo-silhouette.svg';
import WhatsappLogo from './whatsapp.svg';
import MessengerLogo from './messenger.svg';
import CopyLogo from './link.svg';
import MailLogo from './close-envelope.svg';

const MOBILE_INPUT = 'MOBILE_INPUT';

class ModalSharingListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceClose: false,
    }
  }


  handleChangeMobileNumber() {
    //NOTICE: You can use modalMissingInfomationProps here by 
    //call this.prop.modalMissingInfomationProps
    this.setState({
      showMissingInformationReminder: MOBILE_INPUT
    })
  }



  render() {
    const {
      rootClassName,
      className,
      containerClassName,
      onManageDisableScrolling,
      shareableLink,
      currentListing,
      // onShareFacebookTimeline,
      // onShareFacebookMessenger,
      showShareModal,
      currentUser,
      onClose,
      intl
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const whatsAppLink = getShareListingLink(currentListing, shareableLink, SHARE_TO_WHATSAPP, currentUser);
    const twitterLink = getShareListingLink(currentListing, shareableLink, SHARE_TO_TWITTER, currentUser);
    const mailLink = getShareListingLink(currentListing, shareableLink, SHARE_TO_EMAIL, currentUser);
    const listingLink = getShareListingLink(currentListing, shareableLink, SHARE_TO_LINK, currentUser);

    const clipboard = intl.formatMessage({ id: 'ModalSharingListing.clipboard' });
    const cannotCopy = intl.formatMessage({ id: 'ModalSharingListing.cannotCopy' });
    const copied = intl.formatMessage({ id: 'ModalSharingListing.copied' });

    const content = (
      <div className={css.container}>
        <h1 className={css.header}>
            <FormattedMessage id="ModalSharingListing.shareTitle"/>
        </h1>
        <h2 className={css.message}>
            <FormattedMessage id="ModalSharingListing.description"/>
        </h2>
        {/* <div className={css.text} onClick={() => onShareFacebookTimeline("Check out this awesome car for sharing on Drive lah")}>
          <img className={css.logo} src={FacebookLogo} alt="facebook-logo"/>
          <FormattedMessage id="ModalSharingListing.facebook"/>
        </div> */}
        <div className={css.text} onClick={() => {
          if (window) {
            window.open(twitterLink, '_blank');
          }
        }}>
          <img className={css.logo} src={TwitterLogo} alt="twitter-logo"/>
          <FormattedMessage id="ModalSharingListing.twitter"/>
        </div>
        <div className={css.text} onClick={() => {
          if (window) {
            window.open(whatsAppLink, '_blank');
          }
        }}>
          <img className={css.logo} src={WhatsappLogo} alt="whatsapp-logo"/>
          <FormattedMessage id="ModalSharingListing.whatsapp"/>
        </div>
        <div className={css.text} onClick={() => {
          if (window) {
            window.location.href = mailLink;
          }
        }}>
          <img className={css.logo} src={MailLogo} alt="email-logo"/>
          <FormattedMessage id="ModalSharingListing.email"/>
        </div>
        {/* <div className={css.text} onClick={() => onShareFacebookMessenger()}>
          <img className={css.logo} src={MessengerLogo} alt="messenger-logo"/>
          <FormattedMessage id="ModalSharingListing.messenger"/>
        </div> */}
        <div className={css.tooltip} 
          onClick={() => {
            if (window) {
              if (!navigator.clipboard) {
                let tooltip = document.getElementById("copyTip");
                tooltip.innerHTML = cannotCopy;
                return;
              }
              navigator.clipboard.writeText(listingLink).then(function() {
                let tooltip = document.getElementById("copyTip");
                //tooltip.innerHTML = `Copied: ${shareableLink}`;
                tooltip.innerHTML = copied;
              }, function(err) {
                let tooltip = document.getElementById("copyTip");
                tooltip.innerHTML = cannotCopy;
              });
              
            }
          }}
          onMouseOut={() => {
            let tooltip = document.getElementById("copyTip");
            tooltip.innerHTML = clipboard;
          }}
          >
        <img className={css.logo} src={CopyLogo} alt="messenger-logo"/>
        <span className={css.tooltiptext} id="copyTip">
            <FormattedMessage id="ModalSharingListing.clipboard"/>
        </span>
        <span className={css.text}>
            <FormattedMessage id="ModalSharingListing.copylink"/>
        </span>
        </div>
      </div>
    );


    const closeButtonMessage = <FormattedMessage id="ModalSharingListing.close" />;

    return (
      <Modal
        id="ModelSharingListing"
        containerClassName={containerClassName}
        isOpen={showShareModal}
        disableCloseBtn={false}
        onClose={onClose}
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
        forceClose={false}
      >
        {content}
      </Modal>
    );
  }
}

ModalSharingListing.defaultProps = {
  className: null,
  rootClassName: null,
  forceClose: false,
  shareLink: null,
};

ModalSharingListing.propTypes = {
  className: string,
  rootClassName: string,
  containerClassName: string,
  shareableLink: string.isRequired,

  intl: intlShape.isRequired,
  onManageDisableScrolling: func.isRequired,
  // onShareFacebookTimeline: func.isRequired,
  // onShareFacebookMessenger: func.isRequired,
  onClose: func.isRequired
};

ModalSharingListing.displayName = 'ModalSharingListing';

export default injectIntl(ModalSharingListing);
