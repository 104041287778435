import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { denormalisedResponseEntities } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { verifyTransaction } from '../../util/dotceptApi';
import axios from 'axios';
import { resolve } from 'url';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ScannerPage/SET_INITIAL_STATE';

export const FETCH_TRANSACTION_REQUEST = 'app/ScannerPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/ScannerPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/ScannerPage/FETCH_TRANSACTION_ERROR';

// ================ Reducer ================ //

const initialState = {
  transactionVerification: null,
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
};

const ScannerPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case FETCH_TRANSACTION_REQUEST:
        return { ...state, fetchTransactionInProgress: true, transactionVerification: null, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS:
        return { ...state, fetchTransactionInProgress: false, fetchTransactionError: null, transactionVerification: payload };
    case FETCH_TRANSACTION_ERROR:
        return { ...state, fetchTransactionInProgress: false, transactionVerification: null, fetchTransactionError: payload };

    default:
      return state;
  }
};

export default ScannerPageReducer;

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
export const fetchTransactionSuccess = transactionVerification => ({ type: FETCH_TRANSACTION_SUCCESS, payload: transactionVerification });
export const fetchTransactionError = error => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: error });

export const fetchTransactionData = (verificationCode, userId) => (dispatch) => {
    dispatch(fetchTransactionRequest());

    return verifyTransaction(verificationCode, userId)
    .then(response => {
        dispatch(fetchTransactionSuccess(response.data));
    })
    .catch(e => {
      console.log(e);
        dispatch(fetchTransactionError(storableError(e)));
    });
}

// export const loadData = userId => (dispatch, getState, sdk) => {
//   // Clear state so that previously loaded data is not visible
//   // in case this page load fails.
//   dispatch(setInitialState());
//   return Promise.all([
//     dispatch(fetchCMSData()),
//     dispatch(fetchWeatherData()),
//     dispatch(fetchListings({},NEW_LISTING)),
//     dispatch(fetchListings(PROMOTE_LISTING_SEARCH,PROMOTE_LISTING)),
//   ]);
// };