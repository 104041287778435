export class LoggingAnalyticsHandler {
  trackPageView(url, name, isMobile) {
    console.log('Analytics page view:', url);
    console.log('Analytics page name:', name);
  }

  trackGeneralInformation(type, payload) {

  }
}

export class GoogleAnalyticsHandler {
  constructor(gtag) {
    if (typeof gtag !== 'function') {
      //throw new Error('Variable `ga` missing for Google Analytics');
    }
    this.gtag = gtag;
  }
  trackPageView(url, name, isMobile) {
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications#tracking_virtual_pageviews
    // this.ga('set', 'page', url);
    // this.ga('send', 'pageview');
  }

  trackGeneralInformation(type, payload) {
    // this.gtag('send', payload);
    // this.ga('event', type, payload);
  }
}

export class GoogleTagManagerHandler {
  constructor(dataLayer) {
    if (typeof dataLayer === 'object') {
      this.dataLayer = dataLayer;
    }
    
  }
  trackPageView(url, name, isMobile) {
    if (typeof this.dataLayer !== 'object') {
      return;
    }
    this.dataLayer.push({'pageUrl': url}); 
    this.dataLayer.push({'pageTitle': name}); 
    this.dataLayer.push({'isMobile': isMobile}); 
  }

  trackGeneralInformation(type, payload) {
    // const actionType = type;
    //this.dataLayer.push({type: payload}); 
  }
}

export class MixPanelHandler {
  constructor(mixPanel) {
    if (typeof mixPanel === 'object') {
      this.mixPanel = mixPanel;
    }
    
  }
  trackPageView(url, name, isMobile) {
    if (typeof this.mixPanel !== 'object') {
      return;
    }

  //   this.mixPanel.track(name, {
  //     pageUrl: url,
  //     pageTitle: name,
  //     isMobile: isMobile,
  //  });
    // this.dataLayer.push({'pageUrl': url}); 
    // this.dataLayer.push({'pageTitle': name}); 
    // this.dataLayer.push({'isMobile': isMobile}); 
  }

  trackGeneralInformation(type, payload) {
    // const actionType = type;
    //this.dataLayer.push({type: payload}); 
  }
}
