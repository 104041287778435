import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldTextInput } from '../../components';
import css from './EditListingPricingForm.css';

const { Money } = sdkTypes;
const TITLE_MAX_LENGTH = 20;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({ id: translationKey });
      const pricePlaceholderMessage = intl.formatMessage({ id: 'EditListingPricingForm.priceInputPlaceholder' });
      const priceRequired = validators.required( intl.formatMessage({ id: 'EditListingPricingForm.priceRequired' }));
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage( { id: 'EditListingPricingForm.priceTooLow', }, { minPrice: formatMoney(intl, minPrice), }),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const originalPriceMessage = intl.formatMessage({ id: 'EditListingPricingForm.originalPrice' });
      const originalPricePlaceholderMessage = intl.formatMessage({ id: 'EditListingPricingForm.originalPriceInputPlaceholder' });

      // const addOnPriceRequired = validators.required( intl.formatMessage({ id: 'EditListingPricingForm.addOnPriceRequired'}));
      // const addOnPriceValidators = config.listingMinimumPriceSubUnits
      // ? validators.composeValidators(addOnPriceRequired, minPriceRequired)
      // : addOnPriceRequired;

      // Add-on
      const maxLengthMessage = validators.required( intl.formatMessage(
        { id: 'EditListingPricingForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      ));
      const maxLength20Message = validators.maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const addOnMessage = intl.formatMessage({ id: 'EditListingPricingForm.addOn'});
      const addOnPlaceholderMessage = intl.formatMessage({ id: 'EditListingPricingForm.addOnPlaceholder'});
      const addOn2PlaceholderMessage = intl.formatMessage({ id: 'EditListingPricingForm.addOn2Placeholder'});
      const addOn3PlaceholderMessage = intl.formatMessage({ id: 'EditListingPricingForm.addOn3Placeholder'});
      // const addOnRequiredMessage = validators.composeValidators(validators.required( intl.formatMessage({ id: 'EditListingPricingForm.addOnRequired'})), maxLength20Message);

      const perUnitMessage = intl.formatMessage({ id: 'EditListingPricingForm.perUnit'});
      const perUnitPlaceholderMessage = intl.formatMessage({ id: 'EditListingPricingForm.perUnitPlaceholder'});

      // Add-on Price
      const addOnPriceMessage = intl.formatMessage({ id: 'EditListingPricingForm.addOnPrice'});
      const addOnPricePlaceholderMessage = intl.formatMessage({ id: 'EditListingPricingForm.addOnPricePlaceholder'});

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInput}
            autoFocus
            label={pricePerUnitMessage}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />

          <FieldCurrencyInput
            id="originalPrice"
            name="originalPrice"
            className={css.priceInput}
            label={originalPriceMessage}
            placeholder={originalPricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
          />

          <FieldTextInput
            id="perUnit"
            name="perUnit"
            className={css.priceInput}
            label={perUnitMessage}
            placeholder={perUnitPlaceholderMessage}
            // validate={composeValidators(required(planningRequiredMessage))}
          />

          <p className={css.subHeader}>
            <FormattedMessage id="EditListingPricingForm.addOnHeader" />
          </p>

          <div className={css.formRow}>
            <div className={css.field}>
              <FieldTextInput
                id="addOnTitle1"
                name="addOnTitle1"
                className={css.priceInput}
                type="text"
                label={addOnMessage}
                placeholder={addOnPlaceholderMessage}
                maxLength={TITLE_MAX_LENGTH}
                validate={maxLength20Message}
              />
            </div>
            <div className={css.field}>
              <FieldCurrencyInput
                id="addOnPrice1"
                name="addOnPrice1"
                className={css.priceInput}
                label={addOnPriceMessage}
                placeholder={addOnPricePlaceholderMessage}
                currencyConfig={config.currencyConfig}
              />
            </div>
          </div>

          <div className={css.formRow}>
            <div className={css.field}>
              <FieldTextInput
                id="addOnTitle2"
                name="addOnTitle2"
                className={css.priceInput}
                type="text"
                label={addOnMessage}
                placeholder={addOn2PlaceholderMessage}
                maxLength={TITLE_MAX_LENGTH}
                validate={maxLength20Message}
              />
            </div>
            <div className={css.field}>
              <FieldCurrencyInput
                id="addOnPrice2"
                name="addOnPrice2"
                className={css.priceInput}
                label={addOnPriceMessage}
                placeholder={addOnPricePlaceholderMessage}
                currencyConfig={config.currencyConfig}
              />
            </div>
          </div>

          <div className={css.formRow}>
            <div className={css.field}>
              <FieldTextInput
                id="addOnTitle3"
                name="addOnTitle3"
                className={css.priceInput}
                type="text"
                label={addOnMessage}
                placeholder={addOn3PlaceholderMessage}
                maxLength={TITLE_MAX_LENGTH}
                validate={maxLength20Message}
              />
            </div>
            <div className={css.field}>
              <FieldCurrencyInput
                id="addOnPrice3"
                name="addOnPrice3"
                className={css.priceInput}
                label={addOnPriceMessage}
                placeholder={addOnPricePlaceholderMessage}
                currencyConfig={config.currencyConfig}
              />
            </div>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
