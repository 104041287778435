import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing,ensureUser } from '../../util/data';
import { ListingLink,ExternalLink } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import { getRegion,getMainRegion } from '../../util/publicData';
import { EditListingLocationForm } from '../../forms';
import config from '../../config.js';

import css from './EditListingLocationPanel.css';

class EditListingLocationPanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);

    this.state = {
      initialValues: this.getInitialValues(),
    };
  }

  getInitialValues() {
    const { listing } = this.props;
    const currentListing = ensureOwnListing(listing);
    const currentAuthor = currentListing && currentListing.author ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    const { geolocation, publicData } = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;
    const location = publicData && publicData.location ? publicData.location : {};
    const { address, building } = location;

    const typeOptions = findOptionsForSelectFilter('type', config.custom.filters);
    const experienceType = publicData ? typeOptions.find(c => c.key === publicData.type) : null;
    const isOnlineExperience = experienceType && experienceType.isOnlineExperience ? true:false;
    const defaultOnlineMeetingLink = config.onlineMeetingURL + (ensuredAuthor?.id?.uuid? ensuredAuthor?.id?.uuid:"");

    const googleMap = publicData && publicData.googleMap ? publicData.googleMap: isOnlineExperience? defaultOnlineMeetingLink:null;
    const region = getRegion(publicData);

    return {
      building,
      region,
      googleMap,
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin: geolocation },
          }
        : null,
    };
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      intl,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { publicData } = currentListing.attributes;

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingLocationPanel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingLocationPanel.createListingTitle" />
    );

    const regionOptions = findOptionsForSelectFilter('region', config.custom.hiddenFilters);
    const helpCenterUrl = intl.formatMessage({ id: 'EditListingLocationPanel.helpCenterUrl' });

    const typeOptions = findOptionsForSelectFilter('type', config.custom.filters);
    const experienceType = publicData ? typeOptions.find(c => c.key === publicData.type) : null;
    const isOnlineExperience = experienceType && experienceType.isOnlineExperience ? true:false;

    return (
      <div className={classes}>
        <div className={css.headerContainer}>
          <h1 className={css.title}>{panelTitle}</h1>
          <ExternalLink href={helpCenterUrl} className={css.helpCenter}>
            <FormattedMessage id="EditListingLocationPanel.helpCenterTitle" />
          </ExternalLink>
      </div>
        
        <EditListingLocationForm
          className={css.form}
          initialValues={this.state.initialValues}
          onSubmit={values => {
            const { building = '', location, googleMap,region } = values;
            const {
              selectedPlace: { address, origin },
            } = location;

            const mainRegion = getMainRegion(regionOptions,region);
            const updateValues = {
              geolocation: origin,
              publicData: {
                region:[
                  region,
                  mainRegion
                ],
                googleMap: googleMap,
                location: { address, building },
              },
            };
            this.setState({
              initialValues: {
                region,
                googleMap,
                building,
                location: { search: address, selectedPlace: { address, origin } },
              },
            });
            onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          regionOptions={regionOptions}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          isOnlineExperience={isOnlineExperience}
          fetchErrors={errors}
        />
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingLocationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingLocationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,

  intl: intlShape.isRequired,
};

export default compose(injectIntl)(EditListingLocationPanel);
