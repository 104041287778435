import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionNoticeMaybe = props => {
  const { 
    publicData,
    intl 
  } = props;
  // if (!publicData?.selfService && !publicData?.extraNote ) {
  //   return null;
  // }

  const selfService = publicData?.selfService;
  const extraNote = publicData?.extraNote;
  const cancellation = publicData?.cancellation;

  return (
    <div className={css.sectionRow}>
      <h2 className={css.sectionHeaderTitle}>
        <FormattedMessage id="ListingPage.noticeTitle" />
      </h2>
      <div className={css.noticeContainer}>
        <div className={css.noticeRow}>
          <div className={css.noticeSubRow}>
              <div className={css.noticeTitle}>
                <FormattedMessage id="ListingPage.cancellation" />
              </div>
              <p className={css.noticeDescription}>
              {/* {richText(selfService, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                longWordClass: css.longWord,
              })} */}
              {cancellation ? (
                <React.Fragment>

                  <FormattedMessage 
                    id="ListingPage.cancellationWithMore" 
                    values={{
                      cancellation: cancellation,
                      understandMore: <a href={intl.formatMessage({ id: 'ListingPage.cancellationUrl' })} target="_blank">
                          <FormattedMessage id="ListingPage.understandMore" />
                        </a>
                      }} 
                  />
                </React.Fragment>
              ):(
                <FormattedMessage id="ListingPage.cancellationDetail" />
              )}
                
              </p>
            </div>
        </div>
        <div className={css.noticeRow}>
          <div className={css.noticeSubRow}>
              <div className={css.noticeTitle}>
                <FormattedMessage id="ListingPage.healthTitle" />
              </div>
              <p className={css.noticeDescription}>
              {/* {richText(selfService, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                longWordClass: css.longWord,
              })} */}
              <FormattedMessage id="ListingPage.healthDetail" />
              </p>
            </div>
        </div>
        {selfService? (
          <div className={css.noticeRow}>
          <div className={css.noticeSubRow}>
            <div className={css.noticeTitle}>
              <FormattedMessage id="ListingPage.selfService" />
            </div>
            <p className={css.noticeDescription}>
            {richText(selfService, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
              longWordClass: css.longWord,
            })}
              {/* {selfService} */}
            </p>
          </div>
        </div>
        ):null}
        {extraNote? (
          <div className={css.noticeRow}>
            <div className={css.noticeSubRow}>
              <div className={css.noticeTitle}>
                <FormattedMessage id="ListingPage.extraNote" />
              </div>
              <p className={css.noticeDescription}>
                {extraNote}
              </p>
            </div>
        </div>
        ):null}
      </div>
      {/* <p className={css.sectionDescription}>
        {richText(description, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p> */}
    </div>
  );
};

export default SectionNoticeMaybe;
