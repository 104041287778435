import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink,ExternalLink } from '../../components';

import css from './SectionLocations.css';

import planner from './images/experience-planner.jpg';
import beginner from './images/credit-by-charles-chen.jpg';
import loyalty from './images/loyalty-point.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage, { loadAfterInitialRendering: 1000 });

const locationLink = (title, description, image, urlId, intl) => {
  // const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <ExternalLink href={intl.formatMessage({ id: urlId })} className={css.location}>
      <div className={css.locationWrapper}>
        <div className={css.imageWrapper}>
          <div className={css.aspectWrapper}>
            <LazyImage src={image} className={css.locationImage} />
          </div>
        </div>
        <div className={css.linkText}>
          <div className={css.sectionTitle}>
            <FormattedMessage id={title} />
          </div>
          <div className={css.sectionDescription}>
            <FormattedMessage id={description} />
          </div>
        </div>
      </div>
    </ExternalLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className, intl } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.locationsWrapper}>
        <div className={css.locations}>
          {locationLink(
            'SectionLocations.leftListingTitle',
            'SectionLocations.leftListingDescription',
            beginner,
            'SectionLocations.experienceBeginnerUrl',
            intl
          )}
          {locationLink(
            'SectionLocations.middleListingTitle',
            'SectionLocations.middleListingDescription',
            loyalty,
            'SectionLocations.loyaltyPointUrl',
            intl
          )}
          {locationLink(
            'SectionLocations.rightListingTitle',
            'SectionLocations.rightListingDescription',
            planner,
            'SectionLocations.becomePlannerUrl',
            intl
          )}
        </div>
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { 
  rootClassName: null, 
  className: null 
};

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(SectionLocations);
