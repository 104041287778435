import React, { Component } from 'react';
import { array, string, func, number } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLaptopHouse,faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { ReviewRating } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import { MINUTE,hourToMinute } from '../../util/dates';
import css from './ListingCard.css';

const { Money } = sdkTypes;


const MIN_LENGTH_FOR_LONG_WORDS = 10;
const TOTAL_STAR_DISPLAY = [1];

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 0 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    customFilterConfig,
    setActiveListing,
    totalStar,
  } = props;
  
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage = currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const isValidOriginalPrice = publicData.originalPrice && publicData.originalPrice.amount > price.amount;
  const originalPrice = publicData.originalPrice && publicData.originalPrice.currency? (new Money(publicData.originalPrice.amount, publicData.originalPrice.currency)):null;
  const formattedOriginalPrice = formatMoney(intl, originalPrice?originalPrice:price);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const typeOptions = findOptionsForSelectFilter('type', filtersConfig);
  const experienceType = publicData ? typeOptions.find(c => c.key === publicData.type) : null;
  const isOnlineExperience = experienceType && experienceType.isOnlineExperience ? true:false;
  const onlineExperienceMessage = intl.formatMessage( { id: 'ListingCard.onlineExperience' } );

  const separator = config.custom.separator;
  const selectedServiceOptions = publicData && publicData.service ? publicData.service.join(separator) : null;
  const includedServiceMessage = intl.formatMessage( { id: 'ListingCard.includes' }, { service: selectedServiceOptions });

  const duration = publicData && publicData.duration ? publicData.duration : null;
  const isMinute = (duration && duration < 1);
  const durationConvert = isMinute ? hourToMinute(duration):duration;

  const hourLabel = intl.formatMessage({ id: 'ListingCard.hour' });
  const minuteLabel = intl.formatMessage({ id: 'ListingCard.minute' });

  const durationMessage = intl.formatMessage( 
    { id: 'ListingCard.duration' }, 
    { 
      duration: durationConvert,
      type: isMinute? minuteLabel:hourLabel
    });

  const totalReviews = publicData && publicData.totalReviews ? publicData.totalReviews : null;
  const avgStarRating = publicData && publicData.avgStarRating ? publicData.avgStarRating : null;
  const exectStarRating = publicData && publicData.exectStarRating ? publicData.exectStarRating : null;

  // const totalReview = currentListing.attributes?.totalReviews;
  // const avgStarRating = currentListing.attributes?.avgStarRating;
  // const exectStarRating = currentListing.attributes?.exectStarRating;

  const isNewExperience =  !totalReviews || totalReviews <= 0;
  const newExperienceMessage = intl.formatMessage( { id: 'ListingCard.newExperience' } );
  const authorImage = currentListing.author?.profileImage?.attributes?.variants["square-small"]?.url;

  const totalDisplayStar = totalStar? totalStar:TOTAL_STAR_DISPLAY;
  const locationAreaArray = publicData && publicData.region? publicData.region:null;
  const locationArea = locationAreaArray && locationAreaArray.length > 0? locationAreaArray[0]: null;

  // const unitTranslationKey = isNightly
  //   ? 'ListingCard.perNight'
  //   : isDaily
  //   ? 'ListingCard.perDay'
  //   : 'ListingCard.perUnit';

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div className={css.mainContainer}>
        <div className={css.threeToTwoWrapper} onMouseEnter={() => setActiveListing(currentListing.id)} onMouseLeave={() => setActiveListing(null)} >
          <div className={css.aspectWrapper}>
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
              sizes={renderSizes}
            />
            {isOnlineExperience? (
              <div className={css.experienceContainer}>
                <div className={css.experienceIcon}>
                  <FontAwesomeIcon icon={faLaptopHouse} />
                </div>
                <div className={css.experienceTitle}>
                  {onlineExperienceMessage}
                </div>
              </div>
            ):locationArea? (
              <div className={css.experienceContainer}>
                <div className={css.experienceIcon}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </div>
                <div className={css.experienceTitle}>
                  {locationArea}
                </div>
              </div>
            ):null}
            <div className={css.authorContainer}>
              <img className={css.authorImage} src={authorImage}/>
            </div>
          </div>
        </div>
        <div className={css.info}>
          <div className={css.titleContainer}>
            <div className={css.mainInfo}>
              <div className={css.title}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
            </div>
            <div>
              <p className={css.subTitle}>
                {durationMessage}
                {selectedServiceOptions ? (
                  <React.Fragment>
                    <span aria-hidden="true" className={css.subTitleSeparator}>·</span>
                    {includedServiceMessage}
                  </React.Fragment>
                ):null}
              </p>
            </div>
          </div>

          <div className={css.price}>
            <div className={css.priceContainer}>
              {isValidOriginalPrice? (
              <div className={css.originalPriceValue} title={formattedOriginalPrice}>
                {formattedOriginalPrice}
              </div>
              ):null}
              <div className={css.priceValue} title={priceTitle}>
                {formattedPrice}
              </div>
              {/* <div className={css.perUnit}>
                <FormattedMessage id={unitTranslationKey} />
              </div> */}
            </div>
            {!isNewExperience ? 
              <React.Fragment>
                <div className={css.reviewContainer}>
                  <ReviewRating rating={avgStarRating} reviewStarClassName={css.reviewRatingStar} className={css.reviewRating} totalStar={totalDisplayStar} />
                  <span className={css.reviewCount}><span className={css.reviewAverageCount}>{exectStarRating}</span><span> ({totalReviews})</span></span>
                </div>
                </React.Fragment>
            : null}

            {isNewExperience? (
              <div className={css.newRatingBadge}>
                {newExperienceMessage}
              </div>
            ):null}
          </div>

        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  totalStar: null,
  customFilterConfig: config.custom.hiddenFilters,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  customFilterConfig: array,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  totalStar: array,
  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
