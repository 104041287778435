import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { withViewport } from '../../util/contextHelpers';
import { getReferralLink, SHARE_TO_WHATSAPP, SHARE_TO_TWITTER, SHARE_TO_LINK, SHARE_TO_EMAIL, SHARE_TO_URL } from '../../util/shareLinkHelper';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet, faExchangeAlt, faGift, faCrown, faBirthdayCake } from '@fortawesome/free-solid-svg-icons'
//import { faWallet, faExchangeAlt, faGift, faCheckSquare } from '@fortawesome/free-regular-svg-icons'
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  AvatarLarge,
  NamedLink,
  ListingCard,
  ExternalLink,
} from '../../components';
import { ReferralLetterForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { loadData,sendReferralInvitation } from './LoyaltyPage.duck';
import config from '../../config';

import css from './LoyaltyPage.css';
// import { ReactComponent } from '*.svg';

import FacebookLogo from './facebook-letter-logo.svg';
import TwitterLogo from './twitter-logo-silhouette.svg';
import WhatsappLogo from './whatsapp.svg';
import MessengerLogo from './messenger.svg';
import CopyLogo from './link.svg';
import MailLogo from './close-envelope.svg';

const { UUID } = sdkTypes;

export class LoyaltyPageComponent extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    const {
      scrollingDisabled,
      currentUser,
      currentUserShowError,
      intl,
      sendInvitationInProgress,
      sendInvitationError,
      sendInvitationSuccess,
      onSendReferralInvitation
    } = this.props;

    const user = ensureCurrentUser(currentUser);
    const membership = user.attributes.loyalty? user.attributes.loyalty.membership : null;
    const publicData = user.attributes.profile.publicData;

    const pointRate = membership? membership.pointRate:null;
    const pointTransfer = membership? membership.pointTransfer:null;
    const referralBonus = membership? membership.referralBonus.toLocaleString():null;
    const membershipName = membership? membership.displayName:null;
    const customerId = user.attributes.loyalty? user.id.uuid : null;
    const integrationId = user.attributes.loyalty? user.attributes.profile.privateData.integrationId : null;

    const firstName = membership? user.attributes.profile.firstName:null;
    const loyaltyPoint = membership? user.attributes.loyalty.loyaltyPoint.toLocaleString():null;
    const completedBooking = membership? user.attributes.loyalty.completedBooking:null;
    const bookingCount = membership? membership.bookingCount:null;

    const totalReferral = membership? user.attributes.loyalty.totalReferral:null;
    const totalBonusPoint = membership? user.attributes.loyalty.totalBonusPoint.toLocaleString():null;

    const referralCode = membership? user.attributes.loyalty.referralCode:"";
    const referralUrl = getReferralLink(referralCode, referralBonus, SHARE_TO_URL);
    const referralLink = getReferralLink(referralCode, referralBonus, SHARE_TO_LINK);
    const whatsAppLink = getReferralLink(referralCode, referralBonus, SHARE_TO_WHATSAPP);
    const twitterLink = getReferralLink(referralCode, referralBonus, SHARE_TO_TWITTER);
    const mailLink = getReferralLink(referralCode, referralBonus, SHARE_TO_EMAIL);

    const clipboard = intl.formatMessage({ id: 'LoyaltyPage.clipboard' });
    const cannotCopy = intl.formatMessage({ id: 'LoyaltyPage.cannotCopy' });
    const copied = intl.formatMessage({ id: 'LoyaltyPage.copied' });

    const basicInformation = (
      <div className={css.badge}>
        <FormattedMessage className={css.membershipDisplayName} id="LoyaltyPage.membershipTitle" values={{ membershipName: membershipName }} />
        <span className={css.separator}>|</span>
        <FormattedMessage id="LoyaltyPage.completedBookingTitle" values={{ completedBooking: completedBooking, bookingCount:bookingCount }} />
      </div>
    );

    const asideContent = membership? (
      <div className={css.asideContent}>
        {/* <AvatarLarge className={css.avatar} user={user} disableProfileLink /> */}
        <div className={css.mobileHeading}>
        <FontAwesomeIcon icon={faCrown} className={css.avatar} />
          <div className={css.pointHeader}>
            <FormattedMessage id="LoyaltyPage.mobileHeading" />
          </div>
          <div className={css.mobilePointContainer}>
            <div className={css.tagline}>
              <FormattedMessage id="LoyaltyPage.loyaltyPoint" values={{ loyaltyPoint: loyaltyPoint }} />
            </div>
            {basicInformation}
          </div>
        </div>
        <div className={css.desktopHeading}>
          <FontAwesomeIcon icon={faCrown} className={css.avatar} />
            <div className={css.pointHeader}>
              <FormattedMessage id="LoyaltyPage.mobileHeading" />
            </div>
            <div className={css.tagline}>
              <FormattedMessage id="LoyaltyPage.loyaltyPoint" values={{ loyaltyPoint: loyaltyPoint }} />
            </div>
            {basicInformation}
          </div>
        <div className={css.verificationContainer}>
                <div className={css.verificationHeader}>
                  <FormattedMessage id="LoyaltyPage.membershipHeader" />
                </div>
                <div className={css.basicInfoRow}>
                  <div>
                    <FontAwesomeIcon icon={faWallet} className={css.basicInfoIcon} />
                  </div>
                  <div className={css.basicInfoTitle}>
                  <FormattedMessage id="LoyaltyPage.pointRateTitle" values={{ pointRate:pointRate }} />
                  </div>
                </div>

                <div className={css.basicInfoRow}>
                  <div>
                    <FontAwesomeIcon icon={faExchangeAlt} className={css.basicInfoIcon} />
                  </div>
                  <div className={css.basicInfoTitle}>
                    <FormattedMessage id="LoyaltyPage.pointTransferTitle" values={{ pointTransfer: pointTransfer }} />
                  </div>
                </div>

                <div className={css.basicInfoRow}>
                  <div>
                    <FontAwesomeIcon icon={faGift} className={css.basicInfoIcon} />
                  </div>
                  <div className={css.basicInfoTitle}>
                  <FormattedMessage id="LoyaltyPage.referralBonusTitle" values={{ referralBonus: referralBonus }} />
                  </div>
                </div>
              </div>
      </div>
    ):null;

    const loyaltyDetail = intl.formatMessage({ id: 'LoyaltyPage.loyaltyDetail' });
    const loyaltyDetailUrl = intl.formatMessage({ id: 'LoyaltyPage.loyaltyDetailUrl' });

    const loyaltyDescription = membership? (
      <div>
        <h2 className={css.desktopHeading}>
          <FormattedMessage id="LoyaltyPage.desktopHeading" />
        </h2>
        <div className={css.bioHeader}>
          <FormattedMessage id="LoyaltyPage.introduction" />
        </div>
        <p className={css.bio}>
        <FormattedMessage id="LoyaltyPage.loyaltyDescription" values={{ 
            loyaltyDetail: <a href={loyaltyDetailUrl} target="_blank">{loyaltyDetail}</a>
          }}/>
        </p> 
        <div className={css.membershipIntroContainer}>
          <div className={css.membershipIntro}>
            <FontAwesomeIcon icon={faWallet} className={css.membershipIntroIcon} />
            <p className={css.membershipIntroTitle}>
              <FormattedMessage id="LoyaltyPage.earnPointTitle" />
            </p>
            <p className={css.membershipIntroDescription}>
            <FormattedMessage id="LoyaltyPage.earnPointDescription" values={{ 
              pointRate: <span className={css.highlightedText}>{pointRate}</span>
            }}/>
            </p>
          </div>
          <div className={css.membershipIntro}>
          <FontAwesomeIcon icon={faBirthdayCake} className={css.membershipIntroIcon} />
            <p className={css.membershipIntroTitle}>
              <FormattedMessage id="LoyaltyPage.membershipUpgradeTitle" />
            </p>
            <p className={css.membershipIntroDescription}>
            <FormattedMessage id="LoyaltyPage.membershipUpgradeDescription" values={{ 
              bookingCount: <span className={css.highlightedText}>{bookingCount}</span>
            }}/>
            </p>
          </div>
        </div>
      </div>
    ):null;


    const referralContent = membership? (
      <div className={css.referralContainer}>
        <h1 className={css.referralHeader}>
          <FormattedMessage id="LoyaltyPage.referralIntroduction" />
        </h1>
        <div className={css.referralMobileHeader}>
          <FormattedMessage id="LoyaltyPage.referralIntroduction" />
        </div>
        <p className={css.bio}>
        <FormattedMessage id="LoyaltyPage.referralDescription" values={{ 
            referralBonus: <span className={css.highlightedText}>{referralBonus}</span>
          }}/>
        </p> 

        <div className={css.referralBoardContainer}>
          <div className={css.referralBoard}>
            <p className={css.referralBoardValue}>
            <FormattedMessage id="LoyaltyPage.totalBonusPoint" values={{ 
              totalBonusPoint: <span className={css.highlightedText}>{totalBonusPoint}</span>
            }}/>
            </p>
            <p className={css.referralBoardTitle}>
            <FormattedMessage id="LoyaltyPage.totalBonusPointTitle"/>
            </p>
          </div>
          <div className={css.referralBoard}>
            <p className={css.referralBoardValue}>
            <FormattedMessage id="LoyaltyPage.totalReferral" values={{ 
              totalReferral: <span className={css.highlightedText}>{totalReferral}</span>
            }}/>
            </p>
            <p className={css.referralBoardTitle}>
            <FormattedMessage id="LoyaltyPage.totalReferralTitle"/>
            </p>
          </div>
        </div>

        <ReferralLetterForm
          // onSubmit={handleInvitationSubmit} 
          isInProcess={sendInvitationInProgress}
          isError={sendInvitationError}
          isSubscribed={sendInvitationSuccess}
          onSendReferralInvitation={onSendReferralInvitation}
          customerId={customerId}
          integrationId={integrationId}
          firstName={firstName}
          referralBonus={referralBonus}
          referralLink={referralUrl}
        />

        <div className={css.basicInfoContainer}>
          <div className={css.text} onClick={() => {
            if (window) {
              window.open(twitterLink, '_blank');
            }
          }}>
            <img className={css.logo} src={TwitterLogo} alt="twitter-logo"/>
            <FormattedMessage id="LoyaltyPage.twitter"/>
          </div>
          <div className={css.text} onClick={() => {
            if (window) {
              window.open(whatsAppLink, '_blank');
            }
          }}>
            <img className={css.logo} src={WhatsappLogo} alt="whatsapp-logo"/>
            <FormattedMessage id="LoyaltyPage.whatsapp"/>
          </div>
          <div className={css.text} onClick={() => {
            if (window) {
              window.location.href = mailLink;
            }
          }}>
            <img className={css.logo} src={MailLogo} alt="email-logo"/>
            <FormattedMessage id="LoyaltyPage.email"/>
          </div>
          <div className={css.tooltip} 
            onClick={() => {
              if (window) {
                if (!navigator.clipboard) {
                  let tooltip = document.getElementById("copyTip");
                  tooltip.innerHTML = cannotCopy;
                  return;
                }
                navigator.clipboard.writeText(referralLink).then(function() {
                  let tooltip = document.getElementById("copyTip");
                  //tooltip.innerHTML = `Copied: ${shareableLink}`;
                  tooltip.innerHTML = copied;
                }, function(err) {
                  let tooltip = document.getElementById("copyTip");
                  tooltip.innerHTML = cannotCopy;
                });
                
              }
            }}
            onMouseOut={() => {
              let tooltip = document.getElementById("copyTip");
              tooltip.innerHTML = clipboard;
            }}
            >
          <img className={css.logo} src={CopyLogo} alt="messenger-logo"/>
          <span className={css.tooltiptext} id="copyTip">
              <FormattedMessage id="LoyaltyPage.clipboard"/>
          </span>
          <span className={css.text}>
              <FormattedMessage id="LoyaltyPage.copylink" values={{ referralCode: referralCode }} />
          </span>
          </div>
        </div>

      </div>
    ):null;

    const mainContent = (
      <div>
        {referralContent}
        {loyaltyDescription}
      </div>
    );

    let content;

    if (currentUserShowError) {
      content = (
        <p className={css.error}>
          <FormattedMessage id="LoyaltyPage.loadingDataFailed" />
        </p>
      );
    } else {
      content = mainContent;
    }

    const schemaTitle = intl.formatMessage( { id: 'LoyaltyPage.schemaTitle', }, { siteTitle: config.siteTitle, });

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        title={schemaTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'LoyaltyPage',
          name: schemaTitle,
        }}
      >
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="LoyaltyPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperSideNav className={css.aside}>{asideContent}</LayoutWrapperSideNav>
          <LayoutWrapperMain className={css.mainNavigation}>{content}</LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

LoyaltyPageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  queryListingsError: null,
};

const { bool, arrayOf, number, shape,func } = PropTypes;

LoyaltyPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  onSendReferralInvitation: func.isRequired,
  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { 
    currentUser,
    currentUserShowError
  } = state.user;

  const {
    sendInvitationInProgress,
    sendInvitationError,
    sendInvitationSuccess
  } = state.LoyaltyPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    currentUserShowError,
    sendInvitationInProgress,
    sendInvitationError,
    sendInvitationSuccess
  };
};

const mapDispatchToProps = dispatch => ({
  onSendReferralInvitation: (email, customerId, integrationId, referralLink, firstName, referralBonus) => dispatch(sendReferralInvitation(email, customerId, integrationId, referralLink, firstName, referralBonus)),
});

const LoyaltyPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps),
  withViewport,
  injectIntl
)(LoyaltyPageComponent);

LoyaltyPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return loadData();
};

export default LoyaltyPage;
