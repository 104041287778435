import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import { submitNewsletterSubscription,validateEmail } from '../../util/dotceptApi';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import { NewsletterSubscriptionForm } from '../../forms';

import css from './Footer.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};


class FooterComponent extends Component {
  constructor(props, state) {
    super(props, state);
  }
  
  render() {
    const { 
      rootClassName, 
      className, 
      intl 
    } = this.props;

    const socialMediaLinks = renderSocialMediaLinks(intl);
    const classes = classNames(rootClassName || css.root, className);
    //const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const titleClasses = classNames(css.listTitle);

      return (
        <div className={classes}>
          <div className={css.topBorderWrapper}>
            <div className={css.formSectionContainer}>
              <div className={css.formContentContainer}>
                <div className={css.formDescriptionContainer}>
                  <div className={css.formDescription}>
                  <FormattedMessage id="Footer.subscribeUs" />
                  </div>
                  <div>
                  <FormattedMessage id="Footer.acceptPolicy" />
                  </div>
                </div>
                <div>
                  <NewsletterSubscriptionForm
                    onSubmitNewsletterSubscription={submitNewsletterSubscription}
                  />
                </div>
              </div>
            </div>
            <div className={css.content}>
              <div className={css.someLiksMobile}>{socialMediaLinks}</div>
              <div className={css.links}>
                <div className={css.organization} id="organization">
                  <NamedLink name="LandingPage" className={css.logoLink}>
                    <Logo format="desktop" className={css.logo} />
                  </NamedLink>
                  <div className={css.organizationInfo}>
                    <p className={css.organizationDescription}>
                      <FormattedMessage id="Footer.organizationDescription" />
                    </p>
                    <p className={css.organizationCopyright}>
                      <NamedLink name="LandingPage" className={css.copyrightLink}>
                        <FormattedMessage id="Footer.copyright" />
                      </NamedLink>
                    </p>
                  </div>
                </div>
                <div className={css.infoLinks}>
                  <ul className={css.list}>
                    <li className={css.listItem}>
                      <h3 className={titleClasses}>
                        <FormattedMessage id="Footer.getStartedTitle" />
                      </h3>
                    </li>
                    <li className={css.listItem}>
                      <ExternalLink href={intl.formatMessage({ id: 'Footer.becomePlannerUrl' })} className={css.link}>
                        <FormattedMessage id="Footer.becomePlanner" />
                      </ExternalLink>
                    </li>
                    <li className={css.listItem}>
                      <ExternalLink href={intl.formatMessage({ id: 'Footer.experienceBeginnerUrl' })} className={css.link}>
                        <FormattedMessage id="Footer.experienceBeginner" />
                      </ExternalLink>
                    </li>
                    <li className={css.listItem}>
                      <ExternalLink href={intl.formatMessage({ id: 'Footer.loyaltyPointUrl' })} className={css.link}>
                        <FormattedMessage id="Footer.loyaltyPointTitle" />
                      </ExternalLink>
                    </li>
                    {/* <li className={css.listItem}>
                      <NamedLink
                        name="SearchPage"
                        to={{
                          search:
                            '?address=New%20York%20City%2C%20New%20York%2C%20USA&bounds=40.917576401307%2C-73.7008392055224%2C40.477399%2C-74.2590879797556',
                        }}
                        className={css.link}
                      >
                        <FormattedMessage id="Footer.searchNewYork" />
                      </NamedLink>
                    </li> */}
                  </ul>
                </div>
                <div className={css.searches}>
                  <ul className={css.list}>
                    <li className={css.listItem}>
                      <h3 className={titleClasses}>
                        <FormattedMessage id="Footer.helpTitle" />
                      </h3>
                    </li>
                    <li className={css.listItem}>
                      <ExternalLink href={intl.formatMessage({ id: 'Footer.partnershipCollegeUrl' })} className={css.link}>
                        <FormattedMessage id="Footer.partnershipCollege" />
                      </ExternalLink>
                    </li>
                    <li className={css.listItem}>
                      <ExternalLink href={intl.formatMessage({ id: 'Footer.partnershipBusinessUrl' })} className={css.link}>
                        <FormattedMessage id="Footer.partnershipBusiness" />
                      </ExternalLink>
                    </li>
                    {/* <li className={css.listItem}>
                      <ExternalLink href={intl.formatMessage({ id: 'Footer.csrBusinessUrl' })} className={css.link}>
                        <FormattedMessage id="Footer.csrBusiness" />
                      </ExternalLink>
                    </li> */}
                    <li className={css.listItem}>
                      <ExternalLink href={intl.formatMessage({ id: 'Footer.refundUrl' })} className={css.link}>
                        <FormattedMessage id="Footer.refund" />
                      </ExternalLink>
                    </li>
                    <li className={css.listItem}>
                      <ExternalLink href={intl.formatMessage({ id: 'Footer.supportUrl' })} className={css.link}>
                        <FormattedMessage id="Footer.support" />
                      </ExternalLink>
                    </li>
                    {/* <li className={css.listItem}>
                      <NamedLink
                        name="SearchPage"
                        to={{
                          search:
                            '?address=New%20York%20City%2C%20New%20York%2C%20USA&bounds=40.917576401307%2C-73.7008392055224%2C40.477399%2C-74.2590879797556',
                        }}
                        className={css.link}
                      >
                        <FormattedMessage id="Footer.searchNewYork" />
                      </NamedLink>
                    </li> */}
                  </ul>
                </div>
                <div className={css.searchesExtra}>
                  <ul className={css.list}>
                  <li className={css.listItem}>
                      <h3 className={titleClasses}>
                        <FormattedMessage id="Footer.aboutTitle" />
                      </h3>
                    </li>
                    <li className={css.listItem}>
                      <ExternalLink href={intl.formatMessage({ id: 'Footer.aboutUsUrl' })} className={css.link}>
                        <FormattedMessage id="Footer.aboutUs" />
                      </ExternalLink>
                    </li>
                    <li className={css.listItem}>
                      <ExternalLink className={css.link} onClick={() => {
                        if(typeof window !== 'undefined') {
                          window.openChat();
                        }
                        }}>
                        <FormattedMessage id="Footer.feedback" />
                      </ExternalLink>
                    </li>
                    <li className={css.listItem}>
                      <ExternalLink href={intl.formatMessage({ id: 'Footer.contactUsUrl' })} className={css.link}>
                        <FormattedMessage id="Footer.contactUs" />
                      </ExternalLink>
                    </li>
                    {/* <li className={css.listItem}>
                      <ExternalLink href={intl.formatMessage({ id: 'Footer.trustUrl' })} className={css.link}>
                        <FormattedMessage id="Footer.trust" />
                      </ExternalLink>
                    </li> */}
                    {/* <li className={css.listItem}>
                      <NamedLink name="AboutPage" to={{ hash: '#contact' }} className={css.link}>
                        <FormattedMessage id="Footer.toContactPage" />
                      </NamedLink>
                    </li> */}
                  </ul>
                </div>
                <div className={css.extraLinks}>
                  <div className={css.someLinks}>{socialMediaLinks}</div>
                  <div className={css.legalMatters}>
                    <ul className={css.tosAndPrivacy}>
                      <li>
                        <ExternalLink href={intl.formatMessage({ id: 'Footer.termsUrl' })} className={css.legalLink}>
                          <FormattedMessage id="Footer.termsOfUse" />
                        </ExternalLink>
                      </li>
                      <li>
                        <ExternalLink href={intl.formatMessage({ id: 'Footer.privacyUrl' })} className={css.legalLink}>
                          <FormattedMessage id="Footer.privacyPolicy" />
                        </ExternalLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={css.copyrightAndTermsMobile}>
                <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
                  <FormattedMessage id="Footer.copyright" />
                </NamedLink>
                <div className={css.tosAndPrivacyMobile}>
                  <ExternalLink href={intl.formatMessage({ id: 'Footer.privacyUrl' })} className={css.privacy}>
                    <FormattedMessage id="Footer.privacy" />
                  </ExternalLink>
                  <ExternalLink href={intl.formatMessage({ id: 'Footer.termsUrl' })} className={css.privacy}>
                    <FormattedMessage id="Footer.terms" />
                  </ExternalLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
};

FooterComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

FooterComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const Footer = injectIntl(FooterComponent);

export default Footer;
