import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage,injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '..';
import QRCode from 'qrcode.react';
import config from '../../config';

import css from './ModalQRCodeVerification.css';

class ModalQRCodeVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceClose: false,
    }
  }

  render() {
    const {
      rootClassName,
      className,
      containerClassName,
      onManageDisableScrolling,
      showQRCodeModal,
      onClose,
      verificationCode,
      intl
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const content = (
      <div className={css.container}>
        <h1 className={css.header}>
            <FormattedMessage id="ModalQRCodeVerification.verificationTitle"/>
        </h1>
        <h2 className={css.message}>
            <FormattedMessage id="ModalQRCodeVerification.description"/>
        </h2>
        {verificationCode? (
        <div className={css.verificationCode}>
          <QRCode 
            value={verificationCode} 
            level="H"
            size={256}
          />
        </div>
        ):null}
      </div>
    );

    const closeButtonMessage = <FormattedMessage id="ModalQRCodeVerification.close" />;

    return (
      <Modal
        id="ModalQRCodeVerification"
        containerClassName={containerClassName}
        isOpen={showQRCodeModal}
        disableCloseBtn={false}
        onClose={onClose}
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
        forceClose={false}
      >
        {content}
      </Modal>
    );
  }
}

ModalQRCodeVerification.defaultProps = {
  className: null,
  rootClassName: null,
  forceClose: false,
  shareLink: null,
  verificationCode: null,
};

ModalQRCodeVerification.propTypes = {
  className: string,
  rootClassName: string,
  containerClassName: string,
  // verificationCode: string.isRequired,
  onManageDisableScrolling: func.isRequired,
  intl: intlShape.isRequired,
  onClose: func.isRequired
};

ModalQRCodeVerification.displayName = 'ModalQRCodeVerification';

export default injectIntl(ModalQRCodeVerification);
