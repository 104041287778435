import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { Form,FieldSelect } from '../../components';
import config from '../../config';

import css from './InboxTypeFilterForm.css';
import { validateEmail } from '../../util/dotceptApi';

const identity = v => v;

class InboxTypeFilterFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInProcess: false,
      isSubscribed: false,
      isError: null,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault();
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        onSubmit={this.handleFormSubmit} 
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            intl,
            values,
            form,
            inboxTypeFilterOptions,
            onHandleInboxTypeFilter,
            selectedFilterOption
          } = formRenderProps;

          const classes = classNames(rootClassName, className, css.mainContainer);

          // Type
          const typeMessage = intl.formatMessage({ id: 'InboxTypeFilterForm.type'});
          const typePlaceholderMessage = intl.formatMessage({ id: 'InboxTypeFilterForm.typePlaceholder'});
          // const quantityRequiredMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.quantityRequired'});

          return (
            <Form 
            onSubmit={this.handleFormSubmit} 
            className={classes}
            >
              <div className={css.formContainer}>
              <FieldSelect
                onChange={onHandleInboxTypeFilter}
                selectClassName={css.selectRoot}
                className={css.selectContainer}
                name="type"
                id="type"
                defaultValue={selectedFilterOption}
                // label={typeMessage}
                // validate={required(typeRequiredMessage)}
              >
                <option value="">{typePlaceholderMessage}</option>
                {inboxTypeFilterOptions ?inboxTypeFilterOptions.map(c => (
                  <option key={c.key} value={c.key}>
                    {c.label}
                  </option>
                )):null}
              </FieldSelect>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

InboxTypeFilterFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
  isSubscribed: false,
};

InboxTypeFilterFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  // onSubmit: func.isRequired,
  isMobile: bool,
  isSubscribed: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const InboxTypeFilterForm = injectIntl(InboxTypeFilterFormComponent);

export default InboxTypeFilterForm;
