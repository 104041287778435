import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { createSlug, stringify } from '../../util/urlHelpers';
import { AvatarMedium, NamedLink, ResponsiveImage } from '../../components';
import AddToCalendar from 'react-add-to-calendar';
import css from './TransactionPanel.css';
import QRCodeIcon from './qrcodeIcon.svg';

const createListingLink = (
  listingId,
  label,
  listingDeleted,
  provider,
  searchParams = {},
  className = ''
) => {
  if (!listingDeleted) {
    const params = { id: listingId, slug: createSlug(label) };
    const to = { search: stringify(searchParams) };
    return (
      <NamedLink className={className} name="ListingPage" params={params} to={to}>
        <AvatarMedium user={provider} disableProfileLink />
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

// Functional component as a helper to build AddressLinkMaybe
const DetailCardImage = props => {
  const {
    className,
    rootClassName,
    avatarWrapperClassName,
    listingId,
    listingTitle,
    listingDeleted,
    image,
    provider,
    isCustomer,
    intl,
    transaction,
    googleMap,
    location,
    onOpenQRCodeModal,
    isOnlineExperience,
    isAcceptedTransaction
  } = props;

  const classes = classNames(rootClassName || css.detailCardImageWrapper, className);
  const listingLink = createListingLink(listingId, listingTitle, listingDeleted, provider);
  const { building } = location || {};
  const buildingDescription = building? building: '';

  const addToCalendarEvent = transaction? ({
    title: listingTitle,
    description: buildingDescription,
    location: googleMap,
    startTime: transaction.booking?.attributes?.start,
    endTime: transaction.booking?.attributes?.end
  }):null;

  const calendarItems = [
    { outlook: intl.formatMessage({ id: 'TransactionPanel.outlookMobileCalendar' })  },
    { apple: intl.formatMessage({ id: 'TransactionPanel.appleCalendar' }) },
    { google: intl.formatMessage({ id: 'TransactionPanel.googleCalendar' }) },
    { outlookcom: intl.formatMessage({ id: 'TransactionPanel.outlookCalendar' }) },
    { yahoo: intl.formatMessage({ id: 'TransactionPanel.yahooCalendar' }) },
  ];

  return (
    <React.Fragment>
      <div className={classes}>
        <div className={css.aspectWrapper}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={listingTitle}
            image={image}
            variants={['landscape-crop', 'landscape-crop2x']}
          />
        </div>
        <div className={css.bottomActions}>
          {transaction && isAcceptedTransaction ? (
            <React.Fragment>
            {isCustomer && !isOnlineExperience ? (
              <button className={css.qrcodeButton} onClick={onOpenQRCodeModal}>
                <img className={css.qrcodeIcon} src={QRCodeIcon} />
                <FormattedMessage id="TransactionPanel.qrcode" />
              </button>
            ):null}
            <AddToCalendar 
              event={addToCalendarEvent} 
              listItems={calendarItems}
              buttonLabel={intl.formatMessage({ id: 'TransactionPanel.addToCalendarTitle' })}
              dropdownClass={css.addToCalendarDropDownContainer}
              rootClass={css.addToCalendarContainer} />
            </React.Fragment>
          ):null}
        </div>
      </div>
      {isCustomer ? (
        <div className={avatarWrapperClassName || css.avatarWrapper}>{listingLink}</div>
      ) : null}
    </React.Fragment>
  );
};

DetailCardImage.propTypes = {
  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(DetailCardImage);
