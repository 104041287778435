import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form,FieldSelect,Button,FieldTextInput } from '../../components';
import config from '../../config';

import css from './NewsletterSubscriptionForm.css';
import { validateEmail } from '../../util/dotceptApi';

const identity = v => v;

class NewsletterSubscriptionFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInProcess: false,
      isSubscribed: false,
      isError: null,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault();
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onSubmit={this.handleFormSubmit} 
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            intl,
            values,
            form,
            onSubmitNewsletterSubscription,
          } = formRenderProps;

          const classes = classNames(rootClassName, className, css.mainContainer);
          const subscribeClass = classNames(css.subscribeSuccess, this.state.isSubscribed?null:css.noSubscribe);
          const errorSubscribeClass = classNames(css.subscribeError, this.state.isError?null:css.noSubscribe);

          return (
            <Form 
            onSubmit={this.handleFormSubmit} 
            className={classes}
            >
              <div className={css.formContainer}>
                <Field
                  name="email"
                  render={({ input, meta }) => {
                    return (
                      <input
                        className={css.emailInput}
                        {...input}
                        id="email"
                        type="text"
                        placeholder={intl.formatMessage({ id: 'NewsletterSubscriptionForm.emailPlaceholder' })}
                        autoComplete="off"
                      />
                    );
                  }}
                />
                <Button 
                type="button" 
                className={css.buttonContainer}
                inProgress={this.state.isInProcess}
                disabled={this.state.isInProcess}
                ready={this.state.isSubscribed}
                onClick={() => {
                  const { email } = values;
                  if(!email || email.length <= 0 || !validateEmail(email)) {
                      return;
                  }
                  this.setState({ isInProcess: true,isSubscribed:false, isError:false });
                  onSubmitNewsletterSubscription(email)
                  .then(() => {
                    this.setState({ isInProcess: false,isSubscribed:true, isError:false  });
                    form.change('email', null);
                  })
                  .catch(e => {
                    this.setState({ isInProcess: false,isSubscribed:false, isError:true });
                  });
                }}>
                  <FormattedMessage id="NewsletterSubscriptionForm.subscribe" />
                </Button>
              </div>
              <div className={subscribeClass}>
                <FormattedMessage id="NewsletterSubscriptionForm.subscribeSuccess" />
              </div>
              <div className={errorSubscribeClass}>
                <FormattedMessage id="NewsletterSubscriptionForm.subscribeError" />
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

NewsletterSubscriptionFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
  isSubscribed: false,
};

NewsletterSubscriptionFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  // onSubmit: func.isRequired,
  isMobile: bool,
  isSubscribed: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const NewsletterSubscriptionForm = injectIntl(NewsletterSubscriptionFormComponent);

export default NewsletterSubscriptionForm;
