import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { OwnListingLink } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUtensils, faBus, faHiking, faSnowboarding, faBolt,faThumbsUp } from '@fortawesome/free-solid-svg-icons'
// import { faThumbsUp } from '@fortawesome/free-regular-svg-icons'

import css from './SectionTopValue.css';

  library.add(faUtensils, faBus, faHiking, faSnowboarding, faThumbsUp, faBolt)

  const SectionTopValue = props => {

  const { rootClassName, className, intl } = props;
  const classes = classNames(rootClassName, className, css.sectionRow);
  
  const contentArray = [
    {
      id: 1,
      icon: 'snowboarding',
      title: intl.formatMessage( { id: 'SectionTopValue.leftTitle' } ),
      description: intl.formatMessage( { id: 'SectionTopValue.leftDescription' } )
    },{
      id: 2,
      icon: 'bolt',
      title: intl.formatMessage( { id: 'SectionTopValue.middleTitle' } ),
      description: intl.formatMessage( { id: 'SectionTopValue.middleDescription' } )
    },{
      id: 3,
      icon: 'thumbs-up',
      title: intl.formatMessage( { id: 'SectionTopValue.rightTitle' } ),
      description: intl.formatMessage( { id: 'SectionTopValue.rightDescription' } )
    }];
  return (
    <div className={classes}>
      <div className={css.sectionContentContainer}>
        {contentArray? contentArray.map(obj => (
          <div className={css.contentRow} key={obj.id}>
            <div>
              <FontAwesomeIcon icon={obj.icon} className={css.contentIcon} />
            </div>
            <div>
              <div className={css.contentTitle}>
                {obj.title}
              </div>
              <div className={css.contentDescription}>
                {obj.description}
              </div>
            </div>
        </div>
        )):null}
      </div>
    </div>
  );
};

SectionTopValue.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionTopValue.propTypes = {
  rootClassName: string,
  className: string,

    // from injectIntl
    intl: intlShape.isRequired,
};

export default injectIntl(SectionTopValue);
