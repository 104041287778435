import React, { Component } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape,FormattedMessage } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import {isMobileSafari,isMobile} from 'react-device-detect';
import { getLocalData,saveLocalData } from '../../util/dotceptApi';
import { getMarketplaceEntities,getListingsById } from '../../ducks/marketplaceData.duck';
import { NEW_LISTING, PROMOTE_LISTING, HANDMADE_LISTING, LANDING_CMS_DATA } from './LandingPage.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionTopValue,
  SectionPlatformValue,
  SectionLocations,
  SectionTopTheme,
  SectionExperienceListing,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  WeatherReminder,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import { loadData } from './LandingPage.duck';

import facebookImage from '../../assets/Facebook-1200x630.jpg';
import twitterImage from '../../assets/Twitter-600x314.jpg';
import css from './LandingPage.css';

export class LandingPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
        history,
        intl,
        location,
        scrollingDisabled,
        currentUserListing,
        currentUserListingFetched,
        newListings,
        onlineListings,
        cmsData,
        weatherData,
        promoteListings
      } = this.props;

    // Schema for search engines (helps them to understand what this page is about)
    // http://schema.org
    // We are using JSON-LD format
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
    
    const promoteListingTitle = <FormattedMessage id="LandingPage.promoteListingTitle" />;
    const promoteListingSectionId = 'LandingPage.promoteListingSectionId';
    const promoteListingMinimumLength = 4;
    const promoteAllListings = (promoteListings.length >= 8)? promoteListings: (promoteListings.length >= 4)? promoteListings.slice(0, 4):promoteListings;

    const newListingTitle = <FormattedMessage id="LandingPage.newListingTitle" />
    const newListingSectionId = 'LandingPage.newListingSectionId';
    const newListingMinimumLength = 4;
    const displayNewListings = newListings;

    const handmadeListingTitle = <FormattedMessage id="LandingPage.handmadeListingTitle" />
    const onlineListingSectionId = 'LandingPage.onlineListingSectionId';
    const onlineListingMinimumLength = 3;
    const displayOnlineListings = onlineListings;
    
    const defaultLocation = config.custom.defaultLocationBounds;
    const allSearch = `${defaultLocation}`;
    const promoteSearch = config.custom.promoteListingSearch;
    const hotSearch = config.custom.hotListingSearch;
    const onlineSearch = config.custom.handmadeListingSearch;

    const allThemeOption = findOptionsForSelectFilter('theme', config.custom.filters);
    const themeOptions = allThemeOption? allThemeOption.filter(theme => theme.hot === true):null;

    const localCacheCmsData = getLocalData(LANDING_CMS_DATA);
    const landingCmsData = cmsData? cmsData:localCacheCmsData;
    if(cmsData) {
      saveLocalData(cmsData,LANDING_CMS_DATA);
    }

    const hashtag = landingCmsData? landingCmsData.hashtag:null;
    const sectionHeroTitle = landingCmsData? landingCmsData.title:null;
    const sectionHeroSubtitle = landingCmsData? landingCmsData.subtitle:null;

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
            {landingCmsData? (
              <WeatherReminder
               weatherData={weatherData}
               landingCmsData={landingCmsData}
              />
            ):null}
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.heroContainer}>
              <SectionHero 
              className={css.hero} 
              history={history} 
              location={location}
              hashtag={hashtag}
              sectionHeroTitle = {sectionHeroTitle}
              sectionHeroSubtitle={sectionHeroSubtitle}
              isMobileSafari={isMobileSafari}
              defaultAddress={config.custom.defaultAddress}
              defaultBounds={config.custom.defaultBounds}
              themeOptions={allThemeOption}
              />
            </div>
            <ul className={css.sections}>
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionTopValue />
                </div>
              </li>
              {promoteAllListings && promoteAllListings.length >= promoteListingMinimumLength ? (
                <li className={css.section}>
                  <div className={css.sectionContent}>
                    <SectionExperienceListing
                        title={promoteListingTitle}
                        listings={promoteAllListings}
                        sectionId={promoteListingSectionId}
                        minimumLength={promoteListingMinimumLength}
                        rootSectionHeaderTitleClass={css.listingsTitle}
                        additionalButtonContainerClass={css.buttonContainer}
                        additionalListingsClass={css.listings}
                        additionalTitleContainerClass={css.titleContainer}
                        additionalHeaderTitleClass={css.listingHeaderTitle}
                        additionalPaddingClass={css.paddingContainer}
                        additionalListingClass={css.listing}
                        additionalSectionRowClass={css.sectionRow}
                        additionalReadMoreClass={css.readMoreTitle}
                        // additionalFullPageReadMoreClass={css.fullPageReadMoreContainer}
                        rootFullPageReadMoreClass={css.promoteListingFullSearchContainer}
                        searchLink={hotSearch}
                        // fullPageSearchLink={allSearch}
                    />
                  </div>
                </li> 
              ):null}

              {displayOnlineListings && displayOnlineListings.length >= onlineListingMinimumLength ? (
                <li className={css.section}>
                  <div className={css.sectionContent}>
                    <SectionExperienceListing
                        title={handmadeListingTitle}
                        listings={displayOnlineListings}
                        sectionId={onlineListingSectionId}
                        minimumLength={onlineListingMinimumLength}
                        rootSectionHeaderTitleClass={css.listingsTitle}
                        additionalSectionRowClass={css.sectionRow}
                        additionalListingClass={css.promotelisting}
                        additionalListingsClass={css.promoteListings}
                        additionalTitleContainerClass={css.titleContainer}
                        additionalHeaderTitleClass={css.listingHeaderTitle}
                        additionalPaddingClass={css.paddingContainer}
                        additionalReadMoreClass={css.readMoreTitle}
                        rootFullPageReadMoreClass={css.promoteListingFullSearchContainer}
                        searchLink={onlineSearch}
                        // fullPageSearchLink={allSearch}
                    />
                  </div>
                </li> 
              ):null}
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionTopTheme
                    themeOptions={themeOptions} 
                  />
                </div>
              </li>
              {displayNewListings && displayNewListings.length >= newListingMinimumLength ? (
                <li className={css.section}>
                  <div className={css.sectionContent}>
                    <SectionExperienceListing
                        title={newListingTitle}
                        listings={displayNewListings}
                        sectionId={newListingSectionId}
                        minimumLength={newListingMinimumLength}
                        rootSectionHeaderTitleClass={css.listingsTitle}
                        additionalSectionRowClass={css.sectionRow}
                        additionalListingClass={css.promotelisting}
                        additionalListingsClass={css.promoteListings}
                        additionalTitleContainerClass={css.titleContainer}
                        additionalHeaderTitleClass={css.listingHeaderTitle}
                        additionalPaddingClass={css.paddingContainer}
                        additionalReadMoreClass={css.readMoreTitle}
                        rootFullPageReadMoreClass={css.promoteListingFullSearchContainer}
                        searchLink={allSearch}
                        // fullPageSearchLink={allSearch}
                    />
                  </div>
                </li> 
              ):null}

        
              <li className={classNames(css.section, css.sectionPlatformValueContainer)}>
                <div className={classNames(css.sectionContent, css.sectionContainer)}>
                  <SectionPlatformValue />
                </div>
              </li>
              <li className={css.section}>
                <div className={classNames(css.sectionContent, css.sectionLocationContainer)}>
                  <SectionLocations />
                </div>
              </li>
              {/* <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionHowItWorks
                    currentUserListing={currentUserListing}
                    currentUserListingFetched={currentUserListingFetched}
                  />
                </div>
              </li> */}
            </ul>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, 
    currentUserListingFetched 
  } = state.user;

  const {
    cmsData,
    weatherData,
    newListingRef,
    promoteListingRef,
    onlineListingRef,
  } = state.LandingPage;

  const isValidNewListingRef = newListingRef && newListingRef.length > 0;
  const isValidPromoteListingRef = promoteListingRef && promoteListingRef.length > 0;
  const isValidOnlineListingRef = onlineListingRef && onlineListingRef.length > 0;

  if(isValidNewListingRef) {
    saveLocalData(newListingRef, NEW_LISTING);
  }
  if(isValidPromoteListingRef) {
    saveLocalData(promoteListingRef, PROMOTE_LISTING);
  }
  if(isValidOnlineListingRef) {
    saveLocalData(onlineListingRef, HANDMADE_LISTING);
  }

  const newListingData = isValidNewListingRef? newListingRef:getLocalData(NEW_LISTING);
  const promoteListingData = isValidPromoteListingRef? promoteListingRef:getLocalData(PROMOTE_LISTING);
  const onlineListingData = isValidOnlineListingRef? onlineListingRef:getLocalData(HANDMADE_LISTING);

  const newListings = getListingsById(state, newListingData?newListingData:[]);
  const promoteListings = getListingsById(state, promoteListingData?promoteListingData:[]);
  const onlineListings = getListingsById(state, onlineListingData?onlineListingData:[]);

  // newListings = (newListings && newListings.length > 0)? newListings:localNewListings?localNewListings:[];
  // promoteListings = (promoteListings && promoteListings.length > 0)? promoteListings:localPromoteListings?localPromoteListings:[];

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    newListings,
    onlineListings,
    cmsData,
    weatherData,
    promoteListings,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

LandingPage.loadData = params => {
  // const id = new UUID(params.id);
  return loadData();
};

export default LandingPage;
