import React , { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import classNames from 'classnames';
import ActionBarMaybe from './ActionBarMaybe';
import ShareIcon from './share.svg';

import css from './SectionImages.css';
import loadingImage from './loading2.gif';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
    onOpenSharingModal
  } = props;

  const [imageIndex, setImageIndex] = useState(-1);

  const hasImages = listing.images && listing.images.length > 0;
  const headerVideoSource = listing?.attributes?.publicData?.headerVideoSource;

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div style={{position: 'relative', zIndex: 4}} onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const shareButton = listing.id ? (
    <button className={css.shareButton} onClick={onOpenSharingModal}>
      <img className={css.logo} src={ShareIcon} />
      <FormattedMessage
        id="ListingPage.shareButton"
      />
    </button>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={(e) => {
      setImageIndex(0);
      handleViewPhotosClick(e)
    }}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  const prevButton =
    hasImages ? <button className={css.prev} onClick={(e) => {
      handleViewPhotosClick(e);
      setImageIndex(0);
    }} /> : null;
  const nextButton =
    hasImages ? <button className={css.next} onClick={(e) => {
      handleViewPhotosClick(e);
      setImageIndex(1);
    }} /> : null;


  return (
    <div className={css.sectionImages}>
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper}>
          {actionBar}
          {prevButton}
          {nextButton}

          {!hasImages && (
            <ResponsiveImage
              onClick={(e) => {
                e.stopPropagation();
                handleViewPhotosClick(e);
                setImageIndex(0);
              }}
              rootClassName={classNames(css.rootForImage, css.emptyImage)}
              alt=""
              // alt={title}
              image={null}
              variants={[
                'scaled-large',
              ]}
            />
          )}
          {hasImages && headerVideoSource != null && (
            <video playsInline loop autoPlay muted className={classNames(css.rootForImage, css.mobileImage)} poster={loadingImage}>
              <source src={headerVideoSource} type="video/mp4" />
            </video>
          )}
          {hasImages && headerVideoSource == null && (
            <ResponsiveImage
              onClick={(e) => {
                e.stopPropagation();
                handleViewPhotosClick(e);
                setImageIndex(0);
              }}
              rootClassName={classNames(css.rootForImage, css.mobileImage)}
              alt=""
              image={listing.images[0]}
              variants={[
                'scaled-large',
              ]}
            />
          )}
          {hasImages &&
            <div className={css.bannerWrapper}>
              {/* {headerVideoSource != null? (
              <video 
                onClick={(e) => {
                  e.stopPropagation();
                  handleViewPhotosClick(e);
                  setImageIndex(0);
                }}
                playsInline loop autoPlay muted className={css.rootForImage} poster={loadingImage}>
                  <source src={headerVideoSource} type="video/mp4" />
              </video>
              ):null} */}

              {listing.images.slice(0, 7).map((image, index) => (
                <ResponsiveImage
                  onClick={(e) => {
                    e.stopPropagation();
                    handleViewPhotosClick(e);
                    setImageIndex(index);
                  }}
                  rootClassName={css.rootForImage}
                  alt=""
                  key={index}
                  image={image}
                  variants={[
                    'scaled-large',
                  ]}
                />
              ))}
            </div>
          }
          <div className={css.bottomActions}>
            {shareButton}
            {viewPhotosButton}
          </div>
        </div>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={() => {
          onImageCarouselClose()
          setImageIndex(-1)
        }}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {imageIndex >= 0 &&
          <ImageCarousel images={listing.images} selectedImageIndex={imageIndex} />
        }
      </Modal>
    </div>
  );

  // const hasImages = listing.images && listing.images.length > 0;
  // const firstImage = hasImages ? listing.images[0] : null;

  // // Action bar is wrapped with a div that prevents the click events
  // // to the parent that would otherwise open the image carousel
  // const actionBar = listing.id ? (
  //   <div onClick={e => e.stopPropagation()}>
  //     <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
  //   </div>
  // ) : null;

  // const viewPhotosButton = hasImages ? (
  //   <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
  //     <FormattedMessage
  //       id="ListingPage.viewImagesButton"
  //       values={{ count: listing.images.length }}
  //     />
  //   </button>
  // ) : null;

  // return (
  //   <div className={css.sectionImages}>
  //     <div className={css.threeToTwoWrapper}>
  //       <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
  //         {actionBar}
  //         <ResponsiveImage
  //           rootClassName={css.rootForImage}
  //           alt={title}
  //           image={firstImage}
  //           variants={[
  //             'landscape-crop',
  //             'landscape-crop2x',
  //             'landscape-crop4x',
  //             'landscape-crop6x',
  //           ]}
  //         />
  //         {viewPhotosButton}
  //       </div>
  //     </div>
  //     <Modal
  //       id="ListingPage.imageCarousel"
  //       scrollLayerClassName={css.carouselModalScrollLayer}
  //       containerClassName={css.carouselModalContainer}
  //       lightCloseButton
  //       isOpen={imageCarouselOpen}
  //       onClose={onImageCarouselClose}
  //       usePortal
  //       onManageDisableScrolling={onManageDisableScrolling}
  //     >
  //       <ImageCarousel images={listing.images} />
  //     </Modal>
  //   </div>
  // );

};

export default SectionImages;
