/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const isPopular = 50;
export const defaultAddress = "Hong%20Kong";
export const defaultBounds = "23.40312419,115.13610991,20.82443685,112.92535503";
export const defaultLocationBounds = `?address=${defaultAddress}&bounds=${defaultBounds}`;
export const promoteListingSearch = `${defaultLocationBounds}&pub_promotedListing=landing`;
export const hotListingSearch = `${defaultLocationBounds}&sort=pub_totalReviews`;
export const handmadeListingSearch = `${defaultLocationBounds}&pub_theme=手工DIY`;
export const onlineListingSearch = `${defaultLocationBounds}&pub_type=網上體驗`;

export const separator = "、";
export const unitSeparator = "／";

// If less than this number
export const showAvailableQuantity = 24;

export const listingNamePlaceholder = "{listingName}";
export const listingLocationPlaceholder = "{listingLocation}";
export const providerNamePlaceholder = "{providerName}";
export const listingUrlPlaceholder = "{listingUrl}";
export const shareToWhatsAppLink = `https://wa.me/?text=${listingNamePlaceholder}%0a%0a網址:%20${listingUrlPlaceholder}%0a地址:%20${listingLocationPlaceholder}%0a策劃:%20${providerNamePlaceholder}`;
export const shareToTwitterLink = `https://twitter.com/intent/tweet?text=${listingNamePlaceholder}%0a%0a網址:%20${listingUrlPlaceholder}%0a地址:%20${listingLocationPlaceholder}%0a策劃:%20${providerNamePlaceholder}`;
export const shareToMailLink = `mailto:?subject=Dotcept點諗－${listingNamePlaceholder}&body=${listingNamePlaceholder}%0a%0a網址:%20${listingUrlPlaceholder}%0a地址:%20${listingLocationPlaceholder}%0a策劃:%20${providerNamePlaceholder}`;
export const shareToClipboardLink = `${listingNamePlaceholder}\r\n\r\n網址: ${listingUrlPlaceholder}\r\n地址: ${listingLocationPlaceholder}\r\n策劃: ${providerNamePlaceholder}`;

export const referralSeparator = "%0a=====================%0a";
export const clipboardReferralSeparator = "\r\n=====================\r\n";

export const referralLink = "/signup?referralCode=";
export const referralBonusPlaceholder = "{referralBonus}";
export const shareToWhatsAppReferralLink = `https://wa.me/?text=登記Dotcept領取${referralBonusPlaceholder}體驗積分，在預訂體驗時當作現金折扣。是時候動一動，讓體驗豐富生活！%0a%20`;
export const shareToTwitterReferralLink = `https://twitter.com/intent/tweet?text=登記Dotcept領取${referralBonusPlaceholder}體驗積分，在預訂體驗時當作現金折扣。是時候動一動，讓體驗豐富生活！%20`;
export const shareToMailReferralLink = `mailto:?subject=登記Dotcept領取${referralBonusPlaceholder}體驗積分&body=登記Dotcept領取${referralBonusPlaceholder}體驗積分，在預訂體驗時當作現金折扣。是時候動一動，讓體驗豐富生活！`;
export const shareToClipboardReferralLink = `登記Dotcept領取${referralBonusPlaceholder}體驗積分，在預訂體驗時當作現金折扣。是時候動一動，讓體驗豐富生活！\r\n`;

export const marketplaceColor = "#FCBC04";

export const allTheme = { key: '', label: '更多好玩的', image: 'all', id: 20, hot:true };

export const isHideCancelButton = true;

export const voucherCode = 'voucher';
export const userCredit = 'loyalty';
export const paymePayment = 'line-item/payme服務費-*';
export const fpsPayment = 'line-item/fps服務費-*';
export const atmPayment = 'line-item/atm服務費-*';
export const thirdPartyPayment = [paymePayment, fpsPayment, atmPayment];

export const customerCommission = 'line-item/customer-commission';

export const mixpanelAction = {
  selectBookingButton:'bookingButton',
  goToCheckOutButton: 'goToCheckOutButton',
  fetchTransactionLineItems: 'fetchTransactionLineItems',
  selectBookingDate:'selectListingDate',
  selectBookingTime:'selectBookingTime',
  selectDiscountChoice: 'selectDiscountChoice',
};

export const searchShortCutConfig = [
  {
    key: 'promotedListing',
    display: 'Dotcept 推薦',
    label: 'Dotcept 推薦',
    icon: 'users-cog',
    search: { pub_promotedListing: 'landing' },
  },
];

export const hasAnySearchMode = 'has_any';

export const filters = [
  {
    id: 'dates-length',
    label: '日期',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates','minDuration'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/GMT-8',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: '任何時段' },
        { key: '60', label: '1小時以上', shortLabel: '1小時以上' },
        { key: '120', label: '2小時以上', shortLabel: '2小時以上' },
        { key: '180', label: '3小時以上', shortLabel: '3小時以上' },
        { key: '300', label: '半日', shortLabel: '半日' },
        { key: '1440', label: '全日', shortLabel: '全日' },
      ],
    },
  },
  {
    id: 'price',
    label: '價格',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 5000,
      step: 20,
    },
  },
  {
    id: 'keyword',
    label: '關鍵字',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'theme',
    label: '主題',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_theme'],
    config: {
      searchMode: 'has_any',
      options: [
        { key: '手工DIY', label: 'DIY工作坊',id: 3, image:'design', hot:true },
        { key: '運動與戶外', label: '運動與戶外',id: 6, image:'sport', hot:true},
        // { key: '親子家庭樂', label: '親子家庭樂',id: 9, image:'family', hot:true },
        { key: '攝影與風格', label: '拍攝體驗',id: 10, image:'lifestyle',hot:true },
        // { key: '生活和休閒', label: '生活和休閒',id: 7, image:'outdoor', hot:true },
        { key: '本地旅遊團', label: '本地旅遊團 (即將推出)',id: 1, image:'history',hot:true },
        { key: '主題樂園', label: '主題樂園 (即將推出)',id: 2, image:'theme',hot:true },
        // { key: '娛樂／比賽', label: '娛樂／比賽',id: 8, image:'game', hot:true },
        { key: '飲食／養生', label: '飲食／心靈',id: 5, image:'food', hot:true },
        { key: '音樂／舞蹈', label: '音樂／舞蹈',id: 4, image:'dance', hot:true },

        // { key: 'cultureAndHistory', label: '文化與歷史' },
        // { key: 'lifeAndStyle', label: '生活與風格'},
        // { key: 'artAndDesign', label: '藝術和設計' },
        // { key: 'musicOrDance', label: '音樂／舞蹈' },
        // { key: 'foodOrHealth', label: '美食／養生' },
        // { key: 'sportsAndFitness', label: '運動與健身' },
        // { key: 'outdoorAndLeisure', label: '戶外和休閒' },
        // { key: 'entertainmentOrGames', label: '娛樂／比賽' }
      ],
    },
  },
  {
    id: 'quantity',
    label: '人數',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_quantity'],
    config: {
      rangeMode: true,
      options: [
        { key: '1', label: '1-2人', range: '900000' },
        { key: '3', label: '3-5人', range: '900000' },
        { key: '6', label: '6-8人', range: '900000' },
        { key: '9', label: '9-11人', range: '900000' },
        { key: '12', label: '12-14人', range: '900000' },
        { key: '15', label: '15人以上', range: '900000' },
      ],
    },
  },
  {
    id: 'region',
    label: '熱門地點',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_region'],
    config: {
      searchMode: 'has_any',
      options: [
        { key: '九龍區', label: '九龍區' },
        { key: '觀塘', label: '觀塘' },
        { key: '旺角', label: '旺角' },
        // { key: '新蒲崗', label: '新蒲崗' },
        // { key: '荔枝角', label: '荔枝角' },
        // { key: '尖沙咀', label: '尖沙咀' },
        { key: '新界區', label: '新界區'},
        { key: '沙田', label: '沙田' },
        { key: '西貢', label: '西貢' },
        { key: '港島區', label: '港島區'},
        // { key: '中環', label: '中環' },
        { key: '灣仔', label: '灣仔' },
        { key: '銅鑼灣', label: '銅鑼灣' },
        { key: '離島區', label: '離島／偏遠'},
        // { key: '大澳', label: '大澳' },
      ],
    },
  },
  // {
  //   id: 'yogaStyles',
  //   label: 'Yoga styles',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_yogaStyles'],
  //   config: {
  //     // Optional modes: 'has_all', 'has_any'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'ashtanga', label: 'Ashtanga' },
  //       { key: 'hatha', label: 'Hatha' },
  //       { key: 'kundalini', label: 'Kundalini' },
  //       { key: 'restorative', label: 'Restorative' },
  //       { key: 'vinyasa', label: 'Vinyasa' },
  //       { key: 'yin', label: 'Yin' },
  //     ],
  //   },
  // },
  {
    id: 'type',
    label: '體驗類型',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_type'],
    config: {
      options: [
        { key: '網上體驗', label: '網上體驗', isOnlineExperience:true },
        { key: '親身體驗', label: '親身體驗'}
      ],
    },
  },
  {
    id: 'promotedListing',
    label: '體驗推薦',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_promotedListing'],
    config: {
      options: [
        { key: 'landing', label: 'Dotcept 推薦'},
      ],
    },
  },
  // {
  //   id: 'certificate',
  //   label: 'Certificate',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_certificate'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
  //       { key: '200h', label: 'Registered yoga teacher 200h' },
  //       { key: '500h', label: 'Registered yoga teacher 500h' },
  //     ],
  //   },
  // },
  // {
  //   id: 'capacity',
  //   label: 'Capacity',
  //   type: 'SelectSingleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_capacity'],
  //   config: {
  //     options: [
  //       { key: '1', label: 'Only 1' },
  //       { key: '2to4', label: '2 to 4' },
  //       { key: '5to7', label: '5 to 7' },
  //       { key: '8plus', label: '8 plus' },
  //     ],
  //   },
  // },
];

export const hiddenFilters = [
    {
    id: 'language',
    label: '語言',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_language'],
    config: {
      searchMode: 'has_any',
      options: [
        { key: '廣東話', label: '廣東話' },
        { key: '普通話', label: '普通話' },
        { key: '英文', label: '英文' },
        { key: '日文', label: '日文' },
        { key: '韓文', label: '韓文' },
        { key: '泰文', label: '泰文' },
        { key: '西班牙文', label: '西班牙文' },
        { key: '葡萄牙文', label: '葡萄牙文' }
      ],
    },
  },
  {
    id: 'region',
    label: '熱門地點',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_region'],
    config: {
      // searchMode: 'has_all',
      options: [
        { key: '九龍', label: '--- 九龍 ---', mainRegion: '九龍區'},
        { key: '美孚', label: '美孚' , mainRegion: '九龍區'},
        { key: '旺角', label: '旺角' , mainRegion: '九龍區'},
        { key: '觀塘', label: '觀塘' , mainRegion: '九龍區'},
        { key: '彩虹', label: '彩虹' , mainRegion: '九龍區'},
        { key: '藍田', label: '藍田' , mainRegion: '九龍區'},
        { key: '油塘', label: '油塘' , mainRegion: '九龍區'},
        { key: '太子', label: '太子' , mainRegion: '九龍區'},
        { key: '佐敦', label: '佐敦' , mainRegion: '九龍區'},
        { key: '紅磡', label: '紅磡' , mainRegion: '九龍區'},
        { key: '樂富', label: '樂富' , mainRegion: '九龍區'},
        { key: '石硤尾', label: '石硤尾' , mainRegion: '九龍區'},
        { key: '鯉魚門', label: '鯉魚門' , mainRegion: '九龍區'},
        { key: '大角咀', label: '大角咀' , mainRegion: '九龍區'},
        { key: '土瓜灣', label: '土瓜灣' , mainRegion: '九龍區'},
        { key: '何文田', label: '何文田' , mainRegion: '九龍區'},
        { key: '九龍塘', label: '九龍塘' , mainRegion: '九龍區'},
        { key: '九龍灣', label: '九龍灣' , mainRegion: '九龍區'},
        { key: '九龍城', label: '九龍城' , mainRegion: '九龍區'},
        { key: '黃大仙', label: '黃大仙' , mainRegion: '九龍區'},
        { key: '慈雲山', label: '慈雲山' , mainRegion: '九龍區'},
        { key: '鑽石山', label: '鑽石山' , mainRegion: '九龍區'},
        { key: '油麻地', label: '油麻地' , mainRegion: '九龍區'},
        { key: '深水埗', label: '深水埗' , mainRegion: '九龍區'},
        { key: '荔枝角', label: '荔枝角' , mainRegion: '九龍區'},
        { key: '牛頭角', label: '牛頭角' , mainRegion: '九龍區'},
        { key: '新蒲崗', label: '新蒲崗' , mainRegion: '九龍區'},
        { key: '尖沙咀', label: '尖沙咀' , mainRegion: '九龍區'},
        { key: '長沙灣', label: '長沙灣' , mainRegion: '九龍區'},
        { key: '諾士佛臺', label: '諾士佛臺' , mainRegion: '九龍區'},
        { key: '新界', label: '--- 新界 ---' , mainRegion: '新界區'},
        { key: '元朗', label: '元朗' , mainRegion: '新界區'},
        { key: '西貢', label: '西貢' , mainRegion: '新界區'},
        { key: '寶琳', label: '寶琳' , mainRegion: '新界區'},
        { key: '屯門', label: '屯門' , mainRegion: '新界區'},
        { key: '深井', label: '深井' , mainRegion: '新界區'},
        { key: '葵涌', label: '葵涌' , mainRegion: '新界區'},
        { key: '荃灣', label: '荃灣' , mainRegion: '新界區'},
        { key: '青衣', label: '青衣' , mainRegion: '新界區'},
        { key: '馬灣', label: '馬灣' , mainRegion: '新界區'},
        { key: '葵芳', label: '葵芳' , mainRegion: '新界區'},
        { key: '羅湖', label: '羅湖' , mainRegion: '新界區'},
        { key: '上水', label: '上水' , mainRegion: '新界區'},
        { key: '大埔', label: '大埔' , mainRegion: '新界區'},
        { key: '太和', label: '太和' , mainRegion: '新界區'},
        { key: '粉嶺', label: '粉嶺' , mainRegion: '新界區'},
        { key: '火炭', label: '火炭' , mainRegion: '新界區'},
        { key: '沙田', label: '沙田' , mainRegion: '新界區'},
        { key: '大圍', label: '大圍' , mainRegion: '新界區'},
        { key: '坑口', label: '坑口' , mainRegion: '新界區'},
        { key: '流浮山', label: '流浮山' , mainRegion: '新界區'},
        { key: '將軍澳', label: '將軍澳' , mainRegion: '新界區'},
        { key: '天水圍', label: '天水圍' , mainRegion: '新界區'},
        { key: '馬鞍山', label: '馬鞍山' , mainRegion: '新界區'},
        { key: '落馬洲', label: '落馬洲' , mainRegion: '新界區'},
        { key: '港島', label: '--- 港島 ---' , mainRegion: '港島區'},
        { key: '中環', label: '中環' , mainRegion: '港島區'},
        { key: '灣仔', label: '灣仔' , mainRegion: '港島區'},
        { key: '西環', label: '西環' , mainRegion: '港島區'},
        { key: '赤柱', label: '赤柱' , mainRegion: '港島區'},
        { key: '太古', label: '太古' , mainRegion: '港島區'},
        { key: '石澳', label: '石澳' , mainRegion: '港島區'},
        { key: '北角', label: '北角' , mainRegion: '港島區'},
        { key: '柴灣', label: '柴灣' , mainRegion: '港島區'},
        { key: '天后', label: '天后' , mainRegion: '港島區'},
        { key: '半山', label: '半山' , mainRegion: '港島區'},
        { key: '山頂', label: '山頂' , mainRegion: '港島區'},
        { key: '上環', label: '上環' , mainRegion: '港島區'},
        { key: '金鐘', label: '金鐘' , mainRegion: '港島區'},
        { key: '大坑', label: '大坑' , mainRegion: '港島區'},
        { key: '薄扶林', label: '薄扶林' , mainRegion: '港島區'},
        { key: '西灣河', label: '西灣河' , mainRegion: '港島區'},
        { key: '香港仔', label: '香港仔' , mainRegion: '港島區'},
        { key: '筲箕灣', label: '筲箕灣' , mainRegion: '港島區'},
        { key: '數碼港', label: '數碼港' , mainRegion: '港島區'},
        { key: '深水灣', label: '深水灣' , mainRegion: '港島區'},
        { key: '跑馬地', label: '跑馬地' , mainRegion: '港島區'},
        { key: '淺水灣', label: '淺水灣' , mainRegion: '港島區'},
        { key: '鰂魚涌', label: '鰂魚涌' , mainRegion: '港島區'},
        { key: '鴨脷洲', label: '鴨脷洲' , mainRegion: '港島區'},
        { key: '銅鑼灣', label: '銅鑼灣' , mainRegion: '港島區'},
        { key: '杏花邨', label: '杏花邨' , mainRegion: '港島區'},
        { key: '西營盤', label: '西營盤' , mainRegion: '港島區'},
        { key: '堅尼地城', label: '堅尼地城' , mainRegion: '港島區'},
        { key: '離島', label: '--- 離島 ---' , mainRegion: '離島區'},
        { key: '大澳', label: '大澳' , mainRegion: '離島區'},
        { key: '長洲', label: '長洲' , mainRegion: '離島區'},
        { key: '坪洲', label: '坪洲' , mainRegion: '離島區'},
        { key: '東涌', label: '東涌' , mainRegion: '離島區'},
        { key: '愉景灣', label: '愉景灣' , mainRegion: '離島區'},
        { key: '赤鱲角', label: '赤鱲角' , mainRegion: '離島區'},
        { key: '大嶼山', label: '大嶼山' , mainRegion: '離島區'},
        { key: '蒲苔島', label: '蒲苔島' , mainRegion: '離島區'},
        { key: '南丫島', label: '南丫島' , mainRegion: '離島區'},
      ],
    },
  },
  {
    id: 'experienceTime',
    label: '體驗開始時間',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_experienceTime'],
    config: {
      options: [
        { key: '0', label: '無間斷' },
        { key: '1', label: '00分' },
        { key: '5', label: '05分' },
        { key: '15', label: '15分' },
        { key: '30', label: '30分' },
        { key: '45', label: '45分' }
      ],
    },
  },
  {
    id: 'gender',
    label: '性別',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_gender'],
    config: {
      options: [
        { key: 'Male', label: '男性' },
        { key: 'Female', label: '女性' },
        { key: 'Bigender', label: '雙性別' },
        { key: 'Transgender', label: '跨性別' },
        { key: 'Agender', label: '無性別' },
        { key: 'NA', label: '不願透露' }
      ],
    },
  },
  {
    id: 'cancellation',
    label: '取消政策',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_cancellation'],
    config: {
      options: [
        { key: '任何在預訂體驗確認後24小時內或在體驗開始前至少7天通知取消，均可獲得全額退款。', label: '預訂確認後24小時內或在體驗開始前至少7天取消均可退款（建議）' },
        { key: '任何在預訂體驗確認後24小時內通知取消，均可獲得全額退款。', label: '預訂確認後24小時內取消均可退款' },
        { key: '在預訂體驗確認後48小時內通知取消，均可獲得全額退款。', label: '預訂確認後48小時內取消均可退款' },
        { key: '在預訂體驗確認後72小時內通知取消，均可獲得全額退款。', label: '預訂確認後72小時內取消均可退款' },
        { key: '在體驗開始前至少1天通知取消，均可獲得全額退款。', label: '在體驗開始前至少1天取消均可退款' },
        { key: '在體驗開始前至少3天通知取消，均可獲得全額退款。', label: '在體驗開始前至少3天取消均可退款' },
        { key: '在體驗開始前至少7天通知取消，均可獲得全額退款。', label: '在體驗開始前至少7天取消均可退款' },
        // { key: '任何在預訂完成後，隨時可更改行程或獲得全額退款。', label: '免費取消，沒有時間限制' },
        { key: '此體驗在預訂確認後將不予退改。', label: '在預訂體驗確認後不予退改' }
      ],
    },
  },
  {
    id: 'duration',
    label: '體驗時長',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_duration'],
    config: {
      options: [
        { key: '0.75', label: '45分鐘' },
        { key: '1', label: '1小時' },
        { key: '1.25', label: '75分鐘' },
        { key: '1.5', label: '1.5小時' },
        { key: '1.75', label: '1小時45分鐘' },
        { key: '2', label: '2小時' },
        { key: '2.5', label: '2.5小時' },
        { key: '3', label: '3小時' },
        { key: '3.5', label: '3.5小時' },
        { key: '4', label: '4小時' },
        { key: '4.5', label: '4.5小時' },
        { key: '5', label: '5小時' },
        { key: '5.5', label: '5.5小時' },
        { key: '6', label: '6小時' },
        { key: '6.5', label: '6.5小時' },
        { key: '7', label: '7小時' },
        { key: '7.5', label: '7.5小時' },
        { key: '8', label: '8小時' },
        { key: '8.5', label: '8.5小時' },
        { key: '9', label: '9小時' },
        { key: '9.5', label: '9.5小時' },
        { key: '10', label: '10小時' },
        { key: '10.5', label: '10.5小時' },
        { key: '11', label: '11小時' },
        { key: '11.5', label: '11.5小時' },
        { key: '12', label: '半日' },
        { key: '12.5', label: '12.5小時' },
        { key: '13', label: '13小時' },
        { key: '13.5', label: '13.5小時' },
        { key: '14', label: '14小時' },
        { key: '14.5', label: '14.5小時' },
        { key: '15', label: '15小時' },
        { key: '15.5', label: '15.5小時' },
        { key: '16', label: '16小時' },
        { key: '24', label: '全日' }
      ],
    },
  },
  {
    id: 'cutOffTime',
    label: '截止時間',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_cutOffTime'],
    config: {
      options: [
        { key: '1mins', label: '體驗開始前1分鐘（真的嗎？）', value:'60000' },
        { key: '15mins', label: '體驗開始前15分鐘', value:'900000' },
        { key: '30mins', label: '體驗開始前30分鐘', value:'1800000' },
        { key: '1hr', label: '體驗開始前1小時', value:'3600000' },
        { key: '2hrs', label: '體驗開始前2小時', value:'7200000' },
        { key: '3hrs', label: '體驗開始前3小時', value:'10800000'  },
        { key: '6hrs', label: '體驗開始前6小時', value:'21600000'  },
        { key: '12hrs', label: '體驗開始前12小時', value:'43200000'  },
        { key: '1day', label: '體驗開始前1日（建議）', value:'86400000'  },
        { key: '2days', label: '體驗開始前2日', value:'172800000'  },
        { key: '3days', label: '體驗開始前3日', value:'259200000'  },
        { key: '5days', label: '體驗開始前5日', value:'432000000'  },
        { key: '7days', label: '體驗開始前1星期前', value:'604800000'  }
      ],
    },
  },
  {
    id: 'policyAgreement',
    label: '條款',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_policyAgreement'],
    config: {
      options: [
        { key: '我承諾持續管理體驗，在24小時內回覆預訂諮詢與申請，保持高回覆率。', label: '我承諾持續管理體驗，在24小時內回覆預訂諮詢與申請，保持高回覆率。' },
        { key: '我的體驗符合本地法例及有關法例規定（例如商業執照）。', label: '我的體驗符合本地法例及有關法例規定（例如商業執照）。' },
        { key: '提供的說明和相片為我本人所有，並能準確反映我的體驗內容。', label: '提供的說明和相片為我本人所有，並能準確反映我的體驗內容。' },
        { key: '我同意Dotcept體驗「服務條款」及「隱私權政策」。', label: '我同意Dotcept體驗「服務條款」及「隱私權政策」。' },
      ],
    },
  },
  {
    id: 'skillset',
    label: '我們的期望',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_service'],
    config: {
      options: [
        // {
        //   name:"下列哪一項最能準確描述你和你的體驗？",
        //   id:'q1',
        //   value:[{ name: 'q1', key: '我自己舉辦體驗', label: '我自己舉辦體驗' },
        //   { name: 'q1', key: '我的團隊幫助我舉辦體驗', label: '我的團隊幫助我舉辦體驗' }]
        // },
        // {
        //   name:"你計劃多經常舉辦這項體驗？",
        //   id:'q2',
        //   value:[{ name: 'q2', key: '一星期1-4日', label: '一星期1-4日' },
        //   { name: 'q2', key: '一星期5-7日', label: '一星期5-7日' },
        //   { name: 'q2', key: '每月1-3次', label: '每月1-3次' },
        //   { name: 'q2', key: '我還沒決定', label: '我還沒決定' }]
        // },    
        // {
        //   name:"你以前是否舉辦過此體驗？",
        //   id:'q3',
        //   value:[{ name: 'q3', key: '是，我曾專業地舉辦或教授此體驗', label: '是，我曾專業地舉辦或教授此體驗' },
        //   { name: 'q3', key: '是，我曾非正式地為好友或家人舉辦此體驗', label: '是，我曾非正式地為好友或家人舉辦此體驗' },
        //   { name: 'q3', key: '否，我以前從未舉辦過此體驗', label: '否，我以前從未舉辦過此體驗' }]
        // },  
        // {
        //   name:"你從事、鑽研或指導這項體驗的時間有多久？",
        //   id:'q4',
        //   value:[{ name: 'q4', key: '每年少於一次', label: '每年少於一次' },
        //   { name: 'q4', key: '1–4年', label: '1 – 4年' },
        //   { name: 'q4', key: '5年以上', label: '5年以上' }]
        // },  
        // {
        //   name:"以下哪項最能貼切描述體驗內容？",
        //   id:'q5',
        //   value:[{ name: 'q5', key: '這項活動非常獨特，參加者需要我的協助才能完成', label: '這項活動非常獨特，參加者需要我的協助才能完成' },
        //   { name: 'q5', key: '參加者可以單獨完成這項活動，但我會為活動提供獨特的見解', label: '參加者可以單獨完成這項活動，但我會為活動提供獨特的見解' },
        //   { name: 'q5', key: '參加者可以單獨完成這項活動，不用我的協助', label: '參加者可以單獨完成這項活動，不用我的協助' }]
        // },  
        {
          name:"你有興趣加入「KOL免費體驗列表」，讓我們為你邀請「體驗達人」作推廣嗎？",
          id:'q6',
          value:[{ name: 'q6', key: '願意。讓Dotcept全力推廣我的體驗', label: '願意。讓Dotcept全力推廣我的體驗' },
          { name: 'q6', key: '暫不需要', label: '暫不需要' }]
        },  
      ],
    },
  },
  {
    id: 'service',
    label: '提供物品',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_service'],
    config: {
      options: [
        { key: '食物', label: '食物', icon: 'utensils' },
        { key: '飲品', label: '飲品', icon: 'coffee' },
        { key: '門票', label: '門票', icon: 'ticket-alt' },
        { key: '交通', label: '交通', icon: 'bus' },
        { key: '裝備', label: '裝備', icon: 'palette' },
        { key: '保險', label: '保險', icon: 'hand-holding-medical' },
        { key: '導師', label: '導師', icon: 'user-tie' }
      ],
    },
  },
  {
    id: 'country',
    label: '國家／地區',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_country'],
    config: {
      options: [
        { key: '香港', label: '香港' },
        { key: '韓國', label: '韓國' },
        { key: '日本', label: '日本'},
        { key: '新加坡', label: '新加坡'},
        { key: '台灣', label: '台灣'},
        { key: '泰國', label: '泰國'},
        { key: '美國', label: '美國'},
        { key: '英國', label: '英國'},
        { key: '澳大利亞', label: '澳大利亞'},
        { key: '中國', label: '中國'},
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: '最新創建' },
    // { key: '-createdAt', label: '最早創建' },
    { key: 'pub_totalReviews', label: '熱門體驗' },
    { key: '-price', label: '最低價格' },
    { key: 'price', label: '最高價格' },
    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: '最相關', longLabel: '最相關（關鍵字搜索）' },
  ],
};
