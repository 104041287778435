import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form, LocationAutocompleteInput,FieldSelect,Button,FieldTextInput } from '../../components';
import IconHourGlass from './IconHourGlass';
import config from '../../config';

import css from './KeywordSearchForm.css';

const identity = v => v;

class KeywordSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    // this.onSubmit = this.onSubmit.bind(this);
    // this.searchInput = React.createRef();
  }

  // onSubmit(values) {
  //   this.props.onSubmit({ keywords: values.keywords, theme: values.theme });
  //   // blur search input to hide software keyboard
  //   if (this.searchInput.current) {
  //     this.searchInput.current.blur();
  //   }
  // }

  render() {
    return (
      <FinalForm
        {...this.props}
        // onSubmit={this.onSubmit}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            intl,
            themeOptions,
            handleSubmit,
          } = formRenderProps;
          const classes = classNames(rootClassName, className, css.mainContainer);
          
          return (
            <Form onSubmit={handleSubmit} className={classes}>
              <div className={css.formContainer}>
                <FieldTextInput
                className={css.keywordContainer}
                type="keywords"
                id="keywords"
                name="keywords"
                label={intl.formatMessage({ id: 'KeywordSearchForm.keywordLabel' })}
                placeholder={intl.formatMessage({ id: 'KeywordSearchForm.keywordPlaceholder' })}
              />
              {/* <Field name="keywords" render={({ input, meta }) => {
                  return (
                    <input
                      {...input}
                      id="keyword-search"
                      ref={this.searchInput}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: 'KeywordSearchForm.placeholder',
                      })}
                      autoComplete="off"
                    />
                  );
                }}
              /> */}
              <FieldSelect
                className={css.themeContainer}
                id="theme"
                name="theme"
                label={intl.formatMessage({ id: 'KeywordSearchForm.themeLabel' })}
              >
                <option value="">
                  {intl.formatMessage({ id: 'KeywordSearchForm.themePlaceholder' })}
                </option>
                { themeOptions ? themeOptions.map((item, index) => (
                  <option key={index} value={item.key}>
                    {item.label}
                  </option>
                )):null}
              </FieldSelect>

              <Button type="submit" className={css.buttonContainer}>
                <FormattedMessage id="KeywordSearchForm.browseButton" />
                <div className={css.iconContainer}>
                  <IconHourGlass />
                </div>
              </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

KeywordSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

KeywordSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const KeywordSearchForm = injectIntl(KeywordSearchFormComponent);

export default KeywordSearchForm;
