import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton,NamedLink } from '../../components';
import { ReviewRating } from '../../components';
import config from '../../config';

import css from './ListingPage.css';

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

const SectionHeading = props => {
  const {
    richTitle,
    listingCertificate,
    certificateOptions,
    showContactUser,
    onContactUser,
    publicData,
    reviews,
    viewCount,
    bookingsCount,
  } = props;

  const certificate = getCertificateInfo(certificateOptions, listingCertificate);
  const showCertificate = certificate && !certificate.hideFromListingInfo;
  const numberOfReviews = reviews ? reviews.length : 0;
  let avgReview = 0;
  let exectReview = 0;

  if (numberOfReviews > 0) {
    let totalStarCount = 0;
    reviews.forEach(review => {
      totalStarCount += review.attributes.rating;
    });
    avgReview = Math.round(totalStarCount / numberOfReviews);
    exectReview = Math.round(((totalStarCount / numberOfReviews) + Number.EPSILON) * 100) / 100;
  }
  exectReview = exectReview.toFixed(2);

  const defaultLocation = config.custom.defaultLocationBounds;
  const themes = publicData?.theme?.length > 2? publicData.theme.slice(0,2):publicData?.theme;
  const numberOfSolds = publicData?.numberOfSolds? Math.floor(publicData.numberOfSolds/10)*10:null;
  //const themeSearch = `${defaultLocation}&pub_theme=${theme}`;

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
        {showCertificate ? <span>{certificate.label}</span> : null}

        {numberOfReviews >0 ? 
          <React.Fragment>
            <ReviewRating rating={avgReview} reviewStarClassName={css.reviewRatingStar} className={css.reviewRating} />
            <span className={css.reviewCount}><span className={css.reviewAverageCount}>{exectReview}</span> ({numberOfReviews})</span>
            
          </React.Fragment>
         : null}

        {numberOfReviews <= 0 ? (
          <div className={css.badge}>
            <FormattedMessage id="ListingPage.newExperience" />
          </div>
        ): null}

        <span className={css.separator}>•</span>

        {/* {themes ? (
          <NamedLink name="SearchPage" to={{ search:`${defaultLocation}&pub_theme=${theme}` }} className={css.contactLink}>
            {theme}
          </NamedLink>
        ): null} */}

        {numberOfSolds? (
          <div className={css.totalSold}>
          <FormattedMessage id="ListingPage.numberOfSolds" values={{ numberOfSolds }} />
          </div>
        ):null}

        {themes && Array.isArray(themes) ? 
            themes.map(theme => (
            <NamedLink key={theme} name="SearchPage" to={{ search:`${defaultLocation}&pub_theme=${theme}` }} className={css.contactLink}>
              {theme}
            </NamedLink>
            )):null}

            {/* {themes && Array.isArray(themes) ? themes.map(theme => (
            <NamedLink key={theme} name="SearchPage" to={{ search:`${defaultLocation}&pub_theme=${theme}` }} className={css.contactLink}>
              {theme}
            </NamedLink>
            )):null} */}

          {/* <InlineTextButton rootClassName={css.contactLink} onClick={themeSearch}>
              {theme}
          </InlineTextButton> */}

          {/* {showCertificate ? <span>{certificate.label}</span> : null}
          {showContactUser ? (
            <span className={css.contactWrapper}>
              {showCertificate ? <span className={css.separator}>•</span> : null}
              <InlineTextButton rootClassName={css.contactLink} onClick={onContactUser}>
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null} */}
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
