import React from 'react';
import { array, shape, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUtensils, faBus, faHiking, faHandHoldingMedical, faCoffee, faTicketAlt, faIdBadge, faUserTie, faTools, faPalette } from '@fortawesome/free-solid-svg-icons'
import { richText } from '../../util/richText';
import config from '../../config';

import css from './ListingPage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionServiceMaybe = props => {
  const { 
    options,
    publicData
  } = props;

  if (!publicData || !publicData.service) {
    return null;
  }

  const serviceDetail = publicData && publicData.serviceDetail ? publicData.serviceDetail : null;
  const selectedServiceOptions = publicData && publicData.service ? publicData.service : null;
  const selectedConfigOptions = options.filter(o => selectedServiceOptions.find(s => s === o.key));

  if ((!serviceDetail || serviceDetail.length <= 0) && (!selectedConfigOptions || selectedConfigOptions.length <= 0)) {
    return null;
  }

  library.add(faUtensils, faBus, faHiking, faHandHoldingMedical, faCoffee, faTicketAlt, faIdBadge, faUserTie, faTools, faPalette)

  return (
    <div className={css.sectionRow}>
      <h2 className={css.sectionHeaderTitle}>
        <FormattedMessage id="ListingPage.serviceTitle" />
      </h2>
      {selectedConfigOptions? (
        <div>
          <ul className={css.serviceContainer}>
              {selectedConfigOptions.map(c => (
                <li key={c.key} value={c.key} className={css.serviceRow}>
                  <div className={css.serviceContentContainer}>
                    <div>
                      <FontAwesomeIcon icon={c.icon} className={css.serviceIcon} />
                    </div>
                    <div className={css.serviceTitle}>
                      {c.key}
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      ): null}
      <p className={css.sectionDescription}>
        {richText(serviceDetail, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>

    </div>
  );
};

// SectionServiceMaybe.propTypes = {
//   publicData: shape({
//     capacity: string,
//   }).isRequired,
// };

export default SectionServiceMaybe;