import { LOCATION_CHANGED } from '../ducks/Routing.duck';
import {isMobile} from 'react-device-detect';
// Create a Redux middleware from the given analytics handlers. Each
// handler should have the following methods:
//
// - trackPageView(url, name): called when the URL is changed
export const createMiddleware = handlers => () => next => action => {
  const { type, payload } = action;

  if (type === LOCATION_CHANGED) {
    const { canonicalUrl, name } = payload;
    handlers.forEach(handler => {
      handler.trackPageView(canonicalUrl, name, isMobile);
    });
  }else {
    handlers.forEach(handler => {
      handler.trackGeneralInformation(type,payload);
    });
  }

  next(action);
};
