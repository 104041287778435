import React, { Component, useEffect } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import { TopbarContainer } from '../../containers';
// import QrReader from 'react-qr-reader'
import { fetchTransactionData } from './ScannerPage.duck';

import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
  ModalAlert,
  ModalLoading,
  ModalQRCodeVerificationStatus,
  UserNav,
} from '../../components';

import css from './ScannerPage.css';

var QrReader;

export class ScannerPageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showQRCodeModal: false
        };
        this.onOpenQRCodeModal = this.onOpenQRCodeModal.bind(this);
        this.onCloseQRCodeModal = this.onCloseQRCodeModal.bind(this);
        this.onOpenAlertModal = this.onOpenAlertModal.bind(this);
        this.onCloseAlertModal = this.onCloseAlertModal.bind(this);

        try {
            if (typeof window != 'undefined') {
                QrReader = require('react-qr-reader');
            }
        } catch (e) {
            console.error(e);
        }
    }

    onOpenAlertModal() {
        this.setState({ showAlertModal: true });
    };
    
    onCloseAlertModal() {
        this.setState({ showAlertModal: false });
    };

    onOpenQRCodeModal() {
        this.setState({ showQRCodeModal: true });
      };
    
    onCloseQRCodeModal() {
        this.setState({ 
            scanningResult: null,
            showQRCodeModal: false });
    };
    
    handleScan = data => {
        const { 
            currentUser,
            onFetchTransactionData, 
            fetchTransactionInProgress,
            intl 
        } = this.props;

        if (data && this.state.showQRCodeModal == false && fetchTransactionInProgress == false) {

            onFetchTransactionData(data, currentUser.id.uuid)
            .then(response => {
                const { transactionVerification } = this.props;
                if(!transactionVerification) {
                    this.setState({ showAlertModal: true });
                }else {
                    this.setState({  
                        showQRCodeModal: true,
                        scanningResult: response
                     });
                }
            })
            .catch(e => {
                this.setState({ showAlertModal: true });
            });

        }
    }
    handleError = err => {
        console.error(err)
    }

    render() {
        const {
        currentUser,
        currentUserListing,
        scrollingDisabled,
        onManageDisableScrolling,
        intl,
        transactionVerification,
        fetchTransactionInProgress,
        fetchTransactionError,
        } = this.props;
        // prettier-ignore

        const title = intl.formatMessage({ id: 'ScannerPage.title' });

        return (
            <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer currentPage="ScannerPage" />
                    <UserNav selectedPageName="ScannerPage" />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain className={css.staticPageWrapper}>
                <QrReader
                    delay={300}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    className={css.scannerContainer}
                />
                <ModalQRCodeVerificationStatus
                    containerClassName={css.missingInformationModal}
                    onManageDisableScrolling={onManageDisableScrolling}
                    showQRCodeModal={this.state.showQRCodeModal}
                    onClose={this.onCloseQRCodeModal}
                    transactionVerification={transactionVerification}
                />
                <ModalAlert
                    containerClassName={css.missingInformationModal}
                    onManageDisableScrolling={onManageDisableScrolling}
                    showAlertModal={this.state.showAlertModal}
                    onClose={this.onCloseAlertModal}
                    title={intl.formatMessage({ id: 'ScannerPage.errorTitle' })}
                    description={intl.formatMessage({ id: 'ScannerPage.errorDescription' })}
                />
                <ModalLoading
                    onManageDisableScrolling={onManageDisableScrolling}
                    showLoadingModal={fetchTransactionInProgress}
                />
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
            </Page>
        );
    }
};

ScannerPageComponent.defaultProps = {
    currentUser: null,
    currentUserListing: null,
    transactionVerification: null,
    fetchTransactionError: null,
  };
  
  ScannerPageComponent.propTypes = {
    currentUser: propTypes.currentUser,
    currentUserListing: propTypes.ownListing,
    scrollingDisabled: bool.isRequired,
    transactionVerification: object,
    fetchTransactionInProgress:bool.isRequired,
    fetchTransactionError: propTypes.error,
    // from injectIntl
    intl: intlShape.isRequired,
  };
  
  const mapStateToProps = state => {
    const { currentUser, currentUserListing } = state.user;
    const {
        transactionVerification,
        fetchTransactionInProgress,
        fetchTransactionError
    } = state.ScannerPage;

    return {
      currentUser,
      currentUserListing,
      transactionVerification,
      fetchTransactionInProgress,
      fetchTransactionError,
      scrollingDisabled: isScrollingDisabled(state),
    };
  };
  
  const mapDispatchToProps = dispatch => ({
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onFetchTransactionData: (verificationCode, userId) =>
      dispatch(fetchTransactionData(verificationCode, userId)),
  });
  
  const ScannerPage = compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    injectIntl
  )(ScannerPageComponent);
  
  export default ScannerPage;
  