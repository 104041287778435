import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';
import { submitReferralInvitation } from '../../util/dotceptApi';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/LoyaltyPage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/LoyaltyPage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/LoyaltyPage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/LoyaltyPage/SHOW_USER_ERROR';

export const SEND_INVITATION_REQUEST = 'app/LoyaltyPage/SEND_INVITATION_REQUEST';
export const SEND_INVITATION_SUCCESS = 'app/LoyaltyPage/SEND_INVITATION_SUCCESS';
export const SEND_INVITATION_ERROR = 'app/LoyaltyPage/SEND_INVITATION_ERROR';

// ================ Reducer ================ //

const { UUID } = sdkTypes;

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  sendInvitationSuccess: false,
  sendInvitationInProgress: false,
  sendInvitationError: null,
};

export default function loyaltyPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };
    case SEND_INVITATION_REQUEST:
      return { ...state, sendInvitationInProgress: true, sendInvitationError: null, sendInvitationSuccess: false, };
    case SEND_INVITATION_SUCCESS:
      return { ...state, sendInvitationInProgress: false, sendInvitationSuccess: true };
    case SEND_INVITATION_ERROR:
      return { ...state, sendInvitationInProgress: false, sendInvitationSuccess: false, sendInvitationError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const sendInvitationRequest = () => ({ type: SEND_INVITATION_REQUEST });
export const sendInvitationSuccess = () => ({ type: SEND_INVITATION_SUCCESS });
export const sendInvitationError = e => ({ type: SEND_INVITATION_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const sendReferralInvitation = (email, customerId, integrationId, referralLink, firstName, referralBonus) => (dispatch, getState, sdk) => {
  dispatch(sendInvitationRequest());

    return submitReferralInvitation(email, customerId, integrationId, referralLink, firstName, referralBonus)
    .then(response => {
      dispatch(sendInvitationSuccess());
      return response;
    })
    .catch(e => {
      dispatch(sendInvitationError(storableError(e)));
      throw e;
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());
  return Promise.all([
    dispatch(fetchCurrentUser()),
  ]);
};
