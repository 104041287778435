import React from 'react';
import { compose } from 'redux';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import {intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink,ExternalLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';

import css from './EditListingDescriptionPanel.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    intl,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { title, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);
  const typeOptions = findOptionsForSelectFilter('type', config.custom.filters);
  const themeOptions = findOptionsForSelectFilter('theme', config.custom.filters);
  const languageOptions = findOptionsForSelectFilter('language', config.custom.hiddenFilters);

  const helpCenterUrl = intl.formatMessage({ id: 'EditListingDescriptionPanel.helpCenterUrl' });

  return (
    <div className={classes}>
        <div className={css.headerContainer}>
          <h1 className={css.title}>{panelTitle}</h1>
          <ExternalLink href={helpCenterUrl} className={css.helpCenter}>
            <FormattedMessage id="EditListingDescriptionPanel.helpCenterTitle" />
          </ExternalLink>
      </div>
      
      <EditListingDescriptionForm
        className={css.form}
        initialValues={{ 
          title, 
          certificate: publicData.certificate,
          type: publicData.type,
          theme: publicData.theme,
          language: publicData.language,
          quantity: publicData.quantity,
          duration: publicData.duration 
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { title, certificate, type , theme, language, quantity, duration } = values;
          const quantityValue = Number(quantity);
          const durationValue = Number(duration);
          const updateValues = {
            title: title.trim(),
            publicData: { certificate, type , theme, language, quantity:quantityValue, duration:durationValue },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        certificateOptions={certificateOptions}
        typeOptions={typeOptions}
        themeOptions={themeOptions}
        languageOptions={languageOptions}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,

  intl: intlShape.isRequired,
};

export default compose(injectIntl)(EditListingDescriptionPanel);
