import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {   OwnListingLink,NamedLink } from '../../components';
import { faUtensils, faBus, faHiking, faSnowboarding, faBolt,faThumbsUp } from '@fortawesome/free-solid-svg-icons'
// import { faThumbsUp } from '@fortawesome/free-regular-svg-icons'
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import config from '../../config';

import css from './SectionTopTheme.css';
import danceImage from './images/dance-theme.jpg';
import designImage from './images/design-theme.jpg';
import foodImage from './images/food-theme.jpg';
// import gameImage from './images/game-theme.jpg';
// import game2Image from './images/game2-theme.jpg';
import gameImage from './images/game5-theme.jpg';
import historyImage from './images/history-theme.jpg';
import lifestyleImage from './images/lifestyle2-theme.jpg';
import outdoorImage from './images/outdoor-theme.jpg';
import sportImage from './images/sport-theme.jpg';
import familyImage from './images/family-theme.jpg';
import allImage from './images/all3-theme.jpg';
import themeImage from './images/theme-park.jpg';

 class ThemeImage extends Component {
    render() {
      const { alt, ...rest } = this.props;
      return <img alt={alt} {...rest} />;
    }
  }
  const LazyImage = lazyLoadWithDimensions(ThemeImage, { loadAfterInitialRendering: 1000 });

  const themeLink = (id, title, image, searchQuery) => {
    // const nameText = <span className={css.locationName}>{name}</span>;
    return (
      <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location} key={id}>
        <div className={css.locationWrapper}>
          <div className={css.imageWrapper}>
            <div className={css.aspectWrapper}>
              <LazyImage src={image} className={css.locationImage} />
            </div>
          </div>
          <div className={css.linkText}>
            <div className={css.sectionTitle}>
              {/* <FormattedMessage id={title} /> */}
              {title}
            </div>
          </div>
        </div>
      </NamedLink>
    );
  };

  const SectionTopTheme = props => {

  const { 
    rootClassName, 
    className, 
    intl,
    themeOptions
   } = props;
  const classes = classNames(rootClassName, className, css.sectionRow);
  
  const defaultLocation = config.custom.defaultLocationBounds;
  // const themeOptions = findOptionsForSelectFilter('theme', config.custom.filters);
  // const themeSearch = `${defaultLocation}&pub_theme=${theme}`;

  themeOptions.forEach(obj => {
    switch(obj.image) {
      case 'dance':
        obj.displayImage = danceImage;
        return;
      case 'food':
        obj.displayImage = foodImage;
        return;
      case 'history':
        obj.displayImage = historyImage;
        return;
      case 'lifestyle':
        obj.displayImage = lifestyleImage;
        return;
      case 'sport':
        obj.displayImage = sportImage;
        return;
      case 'design':
        obj.displayImage = designImage;
        return;
      case 'game':
        obj.displayImage = gameImage;
        return;
      case 'theme':
        obj.displayImage = themeImage;
        return;
      case 'outdoor':
        obj.displayImage = outdoorImage;
        return;
      case 'family':
        obj.displayImage = familyImage;
        return;
      case 'all':
        obj.displayImage = allImage;
        return;
      default:
        obj.displayImage = allImage;
        return;
    }
   
  });

  const allTheme = config.custom.allTheme;

  // console.log(themeArray);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionTopTheme.topThemeTitle" />
      </div>
      <div className={css.locationsWrapper}>
        <div className={css.locations}>
          {themeOptions? themeOptions.map(obj => (
            themeLink(obj.id, obj.label, obj.displayImage,`${defaultLocation}&pub_theme=${obj.key}`)
          )):null}
          {themeOptions? (
            themeLink(allTheme.id, allTheme.label, allImage,`${defaultLocation}`)
          ):null}
        </div>
      </div>
    </div>
  );
};

SectionTopTheme.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionTopTheme.propTypes = {
  rootClassName: string,
  className: string,

    // from injectIntl
    intl: intlShape.isRequired,
};

export default injectIntl(SectionTopTheme);
