import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { ListingLink,ExternalLink } from '../../components';
import { EditListingCapacityForm } from '../../forms';
import config from '../../config.js';
import { types as sdkTypes } from '../../util/sdkLoader';

// Create this file using EditListingDescriptionPanel.css
// as a template.
import css from './EditListingCapacityPanel.css';

const { Money } = sdkTypes;

const EditListingCapacityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    intl,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, publicData } = currentListing.attributes;

  const panelTitle = currentListing.id ? (
    <FormattedMessage
      id="EditListingCapacityPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingCapacityPanel.createListingTitle" />
  );
  const capacityOptions = findOptionsForSelectFilter('capacity', config.custom.filters);
  const serviceOptions = findOptionsForSelectFilter('service', config.custom.hiddenFilters);
  const cutOffTimeOptions = findOptionsForSelectFilter('cutOffTime', config.custom.hiddenFilters);
  const cancellationOptions = findOptionsForSelectFilter('cancellation', config.custom.hiddenFilters);

  const helpCenterUrl = intl.formatMessage({ id: 'EditListingCapacityPanel.helpCenterUrl' });

  return (
    <div className={classes}>
      <div className={css.headerContainer}>
        <h1 className={css.title}>{panelTitle}</h1>
        <ExternalLink href={helpCenterUrl} className={css.helpCenter}>
            <FormattedMessage id="EditListingCapacityPanel.helpCenterTitle" />
          </ExternalLink>
      </div>
      
      <EditListingCapacityForm
        className={css.form}
        initialValues={{ 
          description, 
          capacity: publicData.capacity, 
          service: publicData.service,
          serviceDetail: publicData.serviceDetail,
          selfService: publicData.selfService,
          extraNote: publicData.extraNote,
          cutOffTime: publicData.cutOffTime,
          cancellation: publicData.cancellation
        }}
        onSubmit={values => {
          const { capacity,description, service,serviceDetail,selfService,extraNote, cutOffTime,cancellation  } = values;
          const updateValues = {
            description,
            publicData: {
              capacity,
              service,
              serviceDetail,
              selfService,
              extraNote,
              cutOffTime,
              cancellation
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateError={errors.updateListingError}
        updateInProgress={updateInProgress}
        capacityOptions={capacityOptions}
        serviceOptions={serviceOptions}
        cutOffTimeOptions={cutOffTimeOptions}
        cancellationOptions={cancellationOptions}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingCapacityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingCapacityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(EditListingCapacityPanel);