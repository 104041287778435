import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { OwnListingLink } from '../../components';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import css from './SectionPlatformValue.css';

import platformImage from './images/platform-value.jpg';

class PlatformImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(PlatformImage, { loadAfterInitialRendering: 1000 });

const SectionPlatformValue = props => {
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  const classes = classNames(rootClassName || css.root, className, css.sectionContainer);

  
  return (
    <div className={classes}>
      <div className={css.textContainer}>
        <div className={css.title}>
          <FormattedMessage id="SectionHowItWorks.titleLineOne" />
          {/* <br />
          <div className={css.subTitle}>
            <FormattedMessage id="SectionHowItWorks.titleLineTwo" />
          </div> */}
        </div>

        <div className={css.contentContainer}>
          <div className={css.steps}>
            <div className={css.step}>
              <h2 className={css.stepTitle}>
                <span className={css.stepNumber}>1</span><FormattedMessage id="SectionHowItWorks.part1Title" />
              </h2>
              <p className={css.stepDescription}>
                <FormattedMessage id="SectionHowItWorks.part1Text" />
              </p>
            </div>

            <div className={css.step}>
              <h2 className={css.stepTitle}>
                <span className={css.stepNumber}>2</span><FormattedMessage id="SectionHowItWorks.part2Title" />
              </h2>
              <p className={css.stepDescription}>
                <FormattedMessage id="SectionHowItWorks.part2Text" />
              </p>
            </div>

            <div className={css.step}>
              <h2 className={css.stepTitle}>
                <span className={css.stepNumber}>3</span><FormattedMessage id="SectionHowItWorks.part3Title" />
              </h2>
              <p className={css.stepDescription}>
                <FormattedMessage id="SectionHowItWorks.part3Text" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={css.stepsImageContainer}>
        <div className={css.contentWrapper}>
          <div className={css.imageWrapper}>
            <LazyImage src={platformImage} className={css.stepImage} />
          </div>
        </div>
      </div>
      {/* <div className={css.createListingLink}>
        <OwnListingLink listing={currentUserListing} listingFetched={currentUserListingFetched}>
          <FormattedMessage id="SectionHowItWorks.createListingLink" />
        </OwnListingLink>
      </div> */}
    </div>
  );
};

SectionPlatformValue.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionPlatformValue.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionPlatformValue;
