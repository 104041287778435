import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faLaptopHouse, faShoppingBag, faUsers, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faClock, faCommentAlt } from '@fortawesome/free-regular-svg-icons'
import config from '../../config';
import { MINUTE,hourToMinute } from '../../util/dates';
import css from './ListingPage.css';

const SectionBasicInformationMaybe = props => {
  const { 
    displayName,
    publicData,
    typeOptions,
    intl,
  } = props;
  if (!publicData) {
    return null;
  }

  const separator = config.custom.separator;
  const selectedServiceOptions = publicData && publicData.service ? publicData.service.join(separator) : null;
  const selectedTypeOptions = publicData && publicData.type ? publicData.type : null;
  //const selectedThemeOptions = publicData && publicData.theme ? publicData.theme : null;
  const selectedLanguageOptions = publicData && publicData.language ? publicData.language.join(separator) : null;
  const selectedQuantityOptions = publicData && publicData.quantity ? publicData.quantity : null;
  // const displayName = displayName? displayName: null;
  const duration = publicData && publicData.duration ? publicData.duration : null;
  // const selectedConfigOptions = options.filter(o => selectedOptions.find(s => s === o.key));
  
  const isMinute = (duration && duration < 1);
  const durationConvert = isMinute ? hourToMinute(duration):duration;

  const hourLabel = intl.formatMessage({ id: 'ListingPage.hour' });
  const minuteLabel = intl.formatMessage({ id: 'ListingPage.minute' });

  const locationAreaArray = publicData && publicData.region? publicData.region:null;
  const locationArea = locationAreaArray && locationAreaArray.length > 0? locationAreaArray[0]: null;

  const experienceType = publicData ? typeOptions.find(c => c.key === publicData.type) : null;
  const isOnlineExperience = experienceType && experienceType.isOnlineExperience ? true:false;

  const durationMessage = intl.formatMessage( 
    { id: 'ListingPage.duration' }, 
    { 
      duration: durationConvert,
      type: isMinute? minuteLabel:hourLabel
    });

  return (
    <div className={css.sectionRow}>
      <h2 className={css.sectionHeaderTitle}>
        <FormattedMessage id="ListingPage.basicInformationTitle" values={{ displayName: displayName?displayName:'' }} />
      </h2>
      <div className={css.basicInfoContainer}>
      {duration ? (
        <div className={css.basicInfoRow}>
          <div>
            <FontAwesomeIcon icon={faClock} className={css.basicInfoIcon} />
          </div>
          <div className={css.basicInfoTitle}>
            {durationMessage}
          </div>
        </div>
      ) : null}
      {selectedServiceOptions ? (
        <div className={css.basicInfoRow}>
          <div>
            <FontAwesomeIcon icon={faShoppingBag} className={css.basicInfoIcon} />
          </div>
          <div className={css.basicInfoTitle}>
            <FormattedMessage id="ListingPage.service" values={{ service: selectedServiceOptions }} />
          </div>
        </div>
      ) : null}
      {selectedTypeOptions ? (
        <div className={css.basicInfoRow}>
          <div>
            <FontAwesomeIcon icon={faLaptopHouse} className={css.basicInfoIcon} />
          </div>
          <div className={css.basicInfoTitle}>
            <FormattedMessage id="ListingPage.type" values={{ type: selectedTypeOptions }} />
          </div>
        </div>
      ) : null}
      {selectedQuantityOptions ? (
        <div className={css.basicInfoRow}>
          <div>
            <FontAwesomeIcon icon={faUsers} className={css.basicInfoIcon} />
          </div>
          <div className={css.basicInfoTitle}>
            <FormattedMessage id="ListingPage.quantity" values={{ quantity: selectedQuantityOptions }} />
          </div>
        </div>
      ) : null}
      {selectedLanguageOptions ? (
        <div className={css.basicInfoRow}>
          <div>
            <FontAwesomeIcon icon={faCommentAlt} className={css.basicInfoIcon} />
          </div>
          <div className={css.basicInfoTitle}>
            <FormattedMessage id="ListingPage.language" values={{ displayName: displayName?displayName:'', language: selectedLanguageOptions }} />
          </div>
        </div>
      ) : null}
      {!isOnlineExperience && locationArea ? (
        <div className={css.basicInfoRow}>
          <div>
            <FontAwesomeIcon icon={faMapMarkerAlt} className={css.basicInfoIcon} />
          </div>
          <div className={css.basicInfoTitle}>
            {locationArea}
          </div>
        </div>
      ) : null}
      </div>
      {/* <PropertyGroup
        id="ListingPage.yogaStyles"
        options={selectedConfigOptions}
        selectedOptions={selectedOptions}
        twoColumns={selectedConfigOptions.length > 5}
      /> */}
    </div>
  );
};

export default SectionBasicInformationMaybe;
