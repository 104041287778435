import React from 'react';
import { string, array, func, number,object } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import config from '../../config';
import {
  NamedLink,
  ListingCard
} from '../../components';

import css from './SectionExperienceListing.css';

const TOTAL_STAR_DISPLAY = [1];

const SectionExperienceListing = props => {
  const {     
    rootSectionRowClass,
    additionalSectionRowClass,
    rootSectionTitleContainerClass,
    rootHeaderContainerClass,
    rootSectionHeaderTitleClass,
    additionalButtonContainerClass,
    additionalListingsClass,
    additionalListingClass,
    additionalTitleContainerClass,
    additionalReadMoreClass,
    additionalHeaderTitleClass,
    additionalPaddingClass,
    minimumLength,
    rootFullPageReadMoreClass,
    additionalFullPageReadMoreClass,
    title,
    listings,
    currentListing,
    sectionId,
    searchLink,
    fullPageSearchLink,
  } = props;

  const finalListings = currentListing && listings ? (listings.reduce(function(filtered, obj) {
    if (currentListing?.id.uuid != obj.id.uuid) {
       filtered.push(obj);
    }
    return filtered;
  }, [])) : listings;

  if (!title || !finalListings || (finalListings.length < minimumLength)) {
    return null;
  }

  // if (currentListing && listings.length == 1 && (currentListing?.id.uuid == listings[0]?.id.uuid)) {
  //   return null;
  // }

  const sectionRowClass = classNames(rootSectionRowClass || css.sectionRow, additionalSectionRowClass);
  const sectionTitleContainerClass = classNames(rootSectionTitleContainerClass || css.sectionTitleContainer, additionalTitleContainerClass);
  const headerContainerClass = classNames(rootHeaderContainerClass || css.headerContainer);
  const sectionHeaderTitleClass = classNames(rootSectionHeaderTitleClass || css.sectionHeaderTitle, additionalHeaderTitleClass);

  const buttonContainerClass = classNames(additionalButtonContainerClass, css.buttonContainer);
  const listingsClass = classNames(additionalListingsClass, css.listings);
  const listingClass = classNames(additionalListingClass, css.listing);

  const readMoreClass = classNames(additionalReadMoreClass, css.readMoreContainer);
  const paddingClass = classNames(css.paddingContainer, additionalPaddingClass);

  const fullPageReadMoreClass = classNames(additionalFullPageReadMoreClass, rootFullPageReadMoreClass || css.fullPageSearchContainer);
  
  return (
    <div className={sectionRowClass}>
      <div className={sectionTitleContainerClass}>
        <div className={headerContainerClass}>
          <h2 className={sectionHeaderTitleClass}>
            {title}
          </h2>
        </div>
        <div className={readMoreClass}>
          {searchLink ? (
            <NamedLink name="SearchPage" to={{ search:searchLink }} className={css.searchLink}>
              <FormattedMessage id="SectionExperienceListing.readMoreTitle" />
            </NamedLink>
          ):null}
        </div>
        <div className={buttonContainerClass}>
          <div className={css.prevContainer}>
                <button className={css.prev} onClick={(e) => {
                  let imageListCarousel = document.getElementById(`${sectionId}`);
                  let listCard = document.getElementsByClassName(css.listing)[0];
                  imageListCarousel.scroll({ top: 0, left: imageListCarousel.scrollLeft-listCard.offsetWidth , behavior: 'smooth' });
              }} />
          </div>
          <div className={css.nextContainer}>
            <button className={css.next} onClick={(e) => {
                    let imageListCarousel = document.getElementById(`${sectionId}`);
                    let listCard = document.getElementsByClassName(css.listing)[0];
                    imageListCarousel.scroll({ top: 0, left: imageListCarousel.scrollLeft+listCard.offsetWidth , behavior: 'smooth' });
            }} />
          </div>
        </div>
        <div className={paddingClass}></div>
      </div>
      <ul className={listingsClass} id={sectionId}>
        {finalListings.map(l => (
          <li className={listingClass} key={l.id.uuid}>
            <ListingCard className={css.listingCard} listing={l} totalStar={TOTAL_STAR_DISPLAY} />
          </li>
        ))}
      </ul>
      {fullPageSearchLink? (
      <div className={fullPageReadMoreClass}>
        <div className={paddingClass}></div>
        <NamedLink name="SearchPage" to={{ search:fullPageSearchLink }} className={css.fullPageSearch}>
          <FormattedMessage id="SectionExperienceListing.readMoreTitle" />
        </NamedLink>
        <div className={paddingClass}></div>
      </div>
      ):null}
    </div>
  );
};

SectionExperienceListing.defaultProps = { 
  rootSectionRowClass : null,
  rootSectionTitleContainerClass : null,
  rootHeaderContainerClass : null,
  rootSectionHeaderTitleClass : null,
  additionalButtonContainerClass: null,
  additionalListingsClass: null,
  additionalHeaderTitleClass: null,
  additionalTitleContainerClass: null,
  additionalListingClass: null,
  additionalReadMoreClass: null,
  additionalSectionRowClass: null,
  additionalPaddingClass: null,
  additionalFullPageReadMoreClass: null,
  rootFullPageReadMoreClass: null,
  title : null,
  listings : null,
  sectionId : null,
  currentListing : null,
  searchLink: null,
  fullPageSearchLink: null,
  minimumLength: 1,
};

SectionExperienceListing.propTypes = {
  rootSectionRowClass : string,
  rootSectionTitleContainerClass : string,
  rootHeaderContainerClass : string,
  rootSectionHeaderTitleClass : string,
  additionalButtonContainerClass: string,
  additionalTitleContainerClass: string,
  additionalListingsClass: string,
  additionalListingClass: string,
  additionalHeaderTitleClass: string,
  additionalPaddingClass: string,
  additionalReadMoreClass: string,
  additionalSectionRowClass: string,
  additionalFullPageReadMoreClass: string,
  rootFullPageReadMoreClass: string,
  searchLink: string,
  fullPageSearchLink: string,
  title : object.isRequired,
  listings : array.isRequired,
  sectionId : string.isRequired,
  currentListing : object,
  minimumLength: number
};


export default SectionExperienceListing;
