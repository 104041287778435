import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';
import config from '../../config';
import css from './ListingPage.css';

const SectionFirstTrail = props => {
  const { 
    intl,
  } = props;

  return (
    <div className={css.firstTrailRow}>
      <div className={css.firstTrailContainer}>
        <h2 className={css.firstTrailTitle}>
        <FormattedMessage id="ListingPage.firstTrailtitle" />
        </h2>
        <div className={css.firstTrailDescription}>
        <FormattedMessage id="ListingPage.firstTrailDescription" />
        </div>
      </div>
    </div>
  );
};

export default SectionFirstTrail;
