import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { IconSpinner, Form, Button, PrimaryButton, FieldCheckbox, FieldTextInput,FieldSelect, NamedLink } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTicketAlt,faCalendarCheck, faShoppingCart, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faWallet } from '@fortawesome/free-solid-svg-icons'

import css from './BookingTimeForm.css';

const { Money } = sdkTypes;

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);
    const { onResetCode } = this.props;

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.isFullRedeem = this.isFullRedeem.bind(this);
    this.isThirdPartyPayment = this.isThirdPartyPayment.bind(this);
    if (typeof onResetCode === "function") {
      onResetCode();
    }
  }

  isFullRedeem(lineItems) {
    if(!lineItems || lineItems.length <= 0) {
      return false;
    }
    const isCustomerCommission = lineItems.some(obj => obj.code === config.custom.customerCommission);
    return !isCustomerCommission && !this.isThirdPartyPayment(lineItems);
  }

  isThirdPartyPayment(lineItems) {
    if(!lineItems || lineItems.length <= 0) {
      return false;
    }
    const array = lineItems.some(obj => config.custom.thirdPartyPayment.includes(obj.code));
    return array;
  }

  handleFormSubmit(e) {
    const {checkedCode,currentUser,listing, lineItems } = this.props;
    const isFullRedeem = this.isFullRedeem(lineItems);
    const isThirdPartyPayment = this.isThirdPartyPayment(lineItems);

    if (checkedCode && (this.prevDiscountChoice === config.custom.voucherCode)) {
      e.voucher = checkedCode;
      //e.voucher.isFullRedeem = isFullRedeem;
    }else if(this.prevDiscountChoice === config.custom.userCredit) {
      e.loyalty = currentUser? currentUser.attributes.loyalty:null;
    }
    e.userComission = listing?.author?.attributes?.profile?.publicData?.comissionRate;
    e.isFullRedeem = isFullRedeem;
    e.isThirdPartyPayment = isThirdPartyPayment;

    if(typeof window !== 'undefined') {
      window.mixpanelPageTracking(config.custom.mixpanelAction.goToCheckOutButton,lineItems);
    }
    
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { bookingStartTime, bookingEndTime, quantity, discountChoice, voucherCode, paymentChoice } = formValues.values;
    const {checkedCode, currentUser, onResetCode, form, listing} = this.props;

    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const addOn1 = formValues.values.addOn1 && formValues.values.addOn1.length > 0;
    const addOn2 = formValues.values.addOn2 && formValues.values.addOn2.length > 0;
    const addOn3 = formValues.values.addOn3 && formValues.values.addOn3.length > 0;

    if (this.prevVoucherCode !== voucherCode) {
      const tempCode = this.prevVoucherCode;
      this.prevVoucherCode = voucherCode;
      if((voucherCode && voucherCode.length > 0) || (tempCode && tempCode.length == 1 && !voucherCode)){
        return;
      }
    }

    const userLoyalty = currentUser? currentUser.attributes.loyalty:null;
    const loyalty = discountChoice === config.custom.userCredit ? userLoyalty:null;
    const voucher = discountChoice === config.custom.voucherCode && this.prevDiscountChoice === config.custom.voucherCode && checkedCode ? checkedCode : null;
    const userComission = listing?.author?.attributes?.profile?.publicData?.comissionRate;

    this.prevDiscountChoice = discountChoice;

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;
    // const isOverQuantity = hiddenSeat < parseInt(quantity);
    
    if (quantity && bookingStartTime && bookingEndTime && !this.props.fetchLineItemsInProgress && (bookingStartTime != bookingEndTime)) {

      if(typeof window !== 'undefined') {
        window.mixpanelPageTracking(config.custom.mixpanelAction.fetchTransactionLineItems, {
          quantity:quantity, 
          startDate:startDate? (startDate.toString()):'', 
          endDate:endDate? (endDate.toString()):'', 
          addOn1:addOn1, 
          addOn2:addOn2, 
          addOn3:addOn3,
          loyalty:loyalty,
          voucher:voucher,
          userComission:userComission,
          discountChoice:discountChoice,
        });
      }

      this.props.onFetchTransactionLineItems({
        bookingData: { 
          quantity, 
          startDate, 
          endDate, 
          addOn1, 
          addOn2, 
          addOn3,
          loyalty,
          voucher,
          userComission,
          paymentChoice
        },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            listing,
            isEnquired,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            submitButtonClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            addOn1Fee,
            addOn2Fee,
            addOn3Fee,
            cutOffTimeOptions,
            checkCodeInProgress,
            checkedCode,
            onCheckingVoucher,
            checkCodeErorr,
            onResetCode,
          } = fieldRenderProps;

          const { discountChoice } = values;

          // Add-On 1
          const formattedAddOn1Fee = addOn1Fee? formatMoney(intl, new Money(addOn1Fee.addOnPrice.amount, addOn1Fee.addOnPrice.currency)): null;
          const addOn1FeeLabel = intl.formatMessage(
            { id: 'BookingTimeForm.addOnLabel' },
            { title: addOn1Fee? (addOn1Fee.addOnTitle):null,
              amount: formattedAddOn1Fee }
          );
          const addOn1FeeMaybe = addOn1Fee ? (
            <FieldCheckbox
              className={css.addOn}
              id="addOn1"
              name="addOn1"
              label={addOn1FeeLabel}
              textClassName={css.addOnTtitle}
              value="addOn1"
            />
          ) : null;

          // Add-On 2
          const formattedAddOn2Fee = addOn2Fee? formatMoney(intl, new Money(addOn2Fee.addOnPrice.amount, addOn2Fee.addOnPrice.currency)): null;
          const addOn2FeeLabel = intl.formatMessage(
            { id: 'BookingTimeForm.addOnLabel' },
            { title: addOn2Fee? (addOn2Fee.addOnTitle):null,
              amount: formattedAddOn2Fee }
          );
          const addOn2FeeMaybe = addOn2Fee ? (
            <FieldCheckbox
              className={css.addOn}
              id="addOn2"
              name="addOn2"
              textClassName={css.addOnTtitle}
              label={addOn2FeeLabel}
              value="addOn2"
            />
          ) : null;

          // Add-On 3
          const formattedAddOn3Fee = addOn3Fee? formatMoney(intl, new Money(addOn3Fee.addOnPrice.amount, addOn3Fee.addOnPrice.currency)): null;
          const addOn3FeeLabel = intl.formatMessage(
            { id: 'BookingTimeForm.addOnLabel' },
            { title: addOn3Fee? (addOn3Fee.addOnTitle):null,
              amount: formattedAddOn3Fee }
          );
          const addOn3FeeMaybe = addOn3Fee ? (
            <FieldCheckbox
              className={css.addOn}
              id="addOn3"
              name="addOn3"
              label={addOn3FeeLabel}
              textClassName={css.addOnTtitle}
              value="addOn3"
            />
          ) : null;

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({ id: 'BookingTimeForm.bookingStartTitle' });
          const bookingEndLabel = intl.formatMessage({ id: 'BookingTimeForm.bookingEndTitle' });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          const paymentTitle = intl.formatMessage({ id: 'BookingTimeForm.paymentTitle' });
          const paymentCreditCard = intl.formatMessage({ id: 'BookingTimeForm.paymentCreditCard' });
          const paymentPayme = intl.formatMessage({ id: 'BookingTimeForm.paymentPayme' });
          const paymentFPS = intl.formatMessage({ id: 'BookingTimeForm.paymentFPS' });
          const paymentATM = intl.formatMessage({ id: 'BookingTimeForm.paymentATM' });

          const promotionTitle = intl.formatMessage({ id: 'BookingTimeForm.promotionTitle' });
          const noPromotionOption = intl.formatMessage({ id: 'BookingTimeForm.noPromotionOption' });
          const usingCreditOption = intl.formatMessage({ id: 'BookingTimeForm.usingCreditOption' });
          const usingVoucherOption = intl.formatMessage({ id: 'BookingTimeForm.usingVoucherOption' });

          const voucherTitle = intl.formatMessage({ id: 'BookingTimeForm.voucherTitle' });
          const voucherPlaceholder = intl.formatMessage({ id: 'BookingTimeForm.voucherPlaceholder' });
          const voucherButton = intl.formatMessage({ id: 'BookingTimeForm.voucherButton' });
          const voucherError = intl.formatMessage({ id: 'BookingTimeForm.voucherError' });

          const loyaltyTitle = intl.formatMessage({ id: 'BookingTimeForm.loyaltyTitle' });
          
         // const isFullRedeem = (checkedCode && checkedCode.isFullRedeem && checkedCode.isFullRedeem == true && (this.prevDiscountChoice === config.custom.voucherCode)) ;
         const isFullRedeem = this.isFullRedeem(lineItems);

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                  timeZone,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames( submitButtonWrapperClassName || css.submitButtonWrapper );
          const bookingButtonClasses = isFullRedeem? css.freeBookButton:css.bookButton;

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  if (discountChoice && discountChoice !== config.custom.voucherCode) {
                    if (typeof onResetCode === "function") {
                      onResetCode();
                    }
                    form.change('voucherCode', null);
                  }
                  this.handleOnChange(values);
                }}
              />
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listing={listing}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  cutOffTimeOptions={cutOffTimeOptions}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  showAvailableQuantity={config.custom.showAvailableQuantity}
                />
              ) : null}

              {!isEnquired?(
                <div className={css.fieldsPayment}>
                  <FieldSelect
                    // labelClassName={css.label}
                    id="paymentChoice"
                    name="paymentChoice"
                    label={paymentTitle}
                    // className={css.checkDiscount}
                    disabled={ !startDate || !endDate || fetchLineItemsInProgress || checkCodeInProgress}
                  >
                    <option value="none">{paymentCreditCard}</option>
                    <option value={config.custom.paymePayment}>{paymentPayme}</option>
                    <option value={config.custom.fpsPayment}>{paymentFPS}</option>
                    <option value={config.custom.atmPayment}>{paymentATM}</option>
                  </FieldSelect>
                </div>
              ):null}

              <div className={css.fieldsPromos}>
                <FieldSelect
                  // labelClassName={css.label}
                  id="discountChoice"
                  name="discountChoice"
                  label={promotionTitle}
                  // className={css.checkDiscount}
                  disabled={ !startDate || !endDate || fetchLineItemsInProgress || checkCodeInProgress}
                >
                  <option value="none">{noPromotionOption}</option>
                  <option value={config.custom.userCredit}>{usingCreditOption}</option>
                  <option value={config.custom.voucherCode}>{usingVoucherOption}</option>
                </FieldSelect>
                {discountChoice === config.custom.voucherCode && (
                  <React.Fragment>
                    <div className={css.voucherContainer}>
                      <FieldTextInput
                        id="voucherCode"
                        name="voucherCode"
                        className={css.voucherInput}
                        type="text"
                        label={voucherTitle}
                        placeholder={voucherPlaceholder}
                        disabled={fetchLineItemsInProgress || checkCodeInProgress}
                      />

                      <Button
                        className={css.voucherButton}
                        type="button"
                        inProgress={checkCodeInProgress}
                        disabled={ !values.voucherCode || checkCodeInProgress}
                        ready={!!checkedCode}
                        onClick={() => {
                          const { voucherCode, ...rest } = values;
                          const startDate = timestampToDate(rest.bookingStartTime);
                          const endDate = timestampToDate(rest.bookingEndTime);
                          onCheckingVoucher({
                            code: voucherCode,
                            listing: listing,
                            data: {
                              startDate,
                              endDate,
                            },
                          })
                          .then(response => {
                            if(response){
                              form.change('voucherCode', null);
                            }
                          });
                        }}
                      >
                        {voucherButton}
                      </Button>
                    </div>
                    {checkCodeErorr && (
                      <p className={css.voucherError}>{voucherError}</p>
                    )}
                  </React.Fragment>
                )}
              </div>

              {addOn1FeeMaybe}
              {addOn2FeeMaybe}
              {addOn3FeeMaybe}
              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <p className={css.smallPrint}>
                {isOwnListing? (
                  <FormattedMessage id= "BookingTimeForm.ownListing" />
                ):(
                  <React.Fragment>
                    <FontAwesomeIcon icon={faWallet} className={css.loyaltyIcon} />
                    <FormattedMessage id="BookingTimeForm.youWontBeChargedInfo" values={{ 
                    loyalty: <NamedLink name="LoyaltyPage">{loyaltyTitle}</NamedLink>
                    }}/>
                  </React.Fragment>
                )}
              </p>

              <div className={css.submitButtonWrapper}>
                <PrimaryButton type="submit" rootClassName={bookingButtonClasses}>
                  <div className={css.inlineBookButton} >
                    {isFullRedeem? (
                      <FormattedMessage id="BookingTimeForm.freeToBook" />
                    ):(
                      <FormattedMessage id="BookingTimeForm.requestToBook" />
                    )}
                  </div>
                  <FontAwesomeIcon className={css.bookIcon} icon={faCalendarAlt} />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  submitButtonClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,
  submitButtonClassName:string,
  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
