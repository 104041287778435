import DirectusSDK from "@directus/sdk-js";
import config from '../config';
import axios from 'axios';
import aes256 from 'aes256';

export const directus = new DirectusSDK({
    url: config.cmsURL,
    project: '/api',
    // storage: (typeof window !== 'undefined')? window.localStorage:null
});

export function verifyTransaction(verificationCode, userId, language=config.locale) {
    return axios.post(`${config.apiURL}/transaction-verification`, {
        apiUrl:config.apiURL,
        transaction: verificationCode,
        userId: userId,
    })
}

export function createCustomer(customerEmail, integrationId, referralCode, clientId=config.sdk.clientId, language=config.locale) {

    const cipher = aes256.createCipher(clientId);
    const data = {
        apiUrl:config.apiURL,
        customerEmail:customerEmail,
        integrationId:integrationId,
        referralCode:referralCode,
        language: language,
    };
    const encryptedData = data? cipher.encrypt(JSON.stringify(data)):null;

    return axios.post(`${config.apiURL}/create-customer`, {
        data:encryptedData
    })
}

export function getLandingData (language=config.locale, fields="*.*", collection="landing") {
    // return axios.get("https://cloudflare.dotcept.workers.dev")
    // .then(res => res.data && res.data.length > 0? res.data[res.data.length - 1]:null)
    return directus.getItems(collection, {
        filter: {
            language: language,
        },
        fields: fields
        })
        .then(res => res.data && res.data.length > 0? res.data[res.data.length - 1]:null)
}

export function getCouponData (couponCode, customerId, providerId, listingId, clientId=config.sdk.clientId, language=config.locale) {
    
    const cipher = aes256.createCipher(clientId);
    const data = {
        apiUrl:config.apiURL,
        customerId: customerId,
        language: language,
        couponCode: couponCode,
        providerId: providerId,
        listingId: listingId,
    };
    const encryptedData = data? cipher.encrypt(JSON.stringify(data)):null;

    return axios.post(`${config.apiURL}/coupon-verification`, {
        data: encryptedData
    })
}

export function getCurrentUserLoyalty (customerId, integrationId, clientId=config.sdk.clientId, language=config.locale) {
    
    const cipher = aes256.createCipher(clientId);
    const data = {
        apiUrl:config.apiURL,
        customerId: customerId,
        integrationId:integrationId,
        language: language,
    };
    const encryptedData = data? cipher.encrypt(JSON.stringify(data)):null;

    return axios.post(`${config.apiURL}/user-profile`, {
        data: encryptedData
    })
}

export function reviewedTransaction (customerId, providerId, listingId, transactionId, clientId=config.sdk.clientId, language=config.locale) {
    
    const cipher = aes256.createCipher(clientId);
    const data = {
        apiUrl:config.apiURL,
        customerId: customerId,
        providerId:providerId,
        listingId:listingId,
        transactionId:transactionId,
        language: language,
    };
    const encryptedData = data? cipher.encrypt(JSON.stringify(data)):null;

    return axios.post(`${config.apiURL}/reviewed-transaction`, {
        data: encryptedData
    })
}

export function preTransaction (customerId, providerId, transactionId, clientId=config.sdk.clientId, language=config.locale) {
    
    const cipher = aes256.createCipher(clientId);
    const data = {
        apiUrl:config.apiURL,
        customerId: customerId,
        providerId:providerId,
        transactionId:transactionId,
        language: language,
    };
    const encryptedData = data? cipher.encrypt(JSON.stringify(data)):null;

    // console.log(config.apiURL);
    // console.log(data);
    return axios.post(`${config.apiURL}/pre-transaction`, {
        data: encryptedData
    })
}

export function getLocalData (type) {
    if(typeof window === 'undefined') {
        return;
    }

    const localCacheCmsData = localStorage.getItem(type);
    if(localCacheCmsData && typeof localCacheCmsData == 'string') {
        if(IsJsonString(localCacheCmsData)){
            return JSON.parse(localCacheCmsData);
        }else {
            return localCacheCmsData;
        }
    }else {
        return null;
    }
}

export function IsJsonString(string) {
    try {
        JSON.parse(string);
    } catch (e) {
        return false;
    }
    return true;
}

export function saveLocalData (value, type) {
    if(typeof window === 'undefined' || !type) {
        return;
    }

    if(typeof value == 'object') {
        localStorage.setItem(type, JSON.stringify(value));
    }else if (typeof value == 'string') {
        localStorage.setItem(type, value);
    }else {
        localStorage.setItem(type, value);
    }
    
}

export function submitNewsletterSubscription (email, clientId=config.sdk.clientId, language=config.locale) {
    const cipher = aes256.createCipher(clientId);
    const data = {
        apiUrl:config.apiURL,
        email:email,
        language: language,
    };
    const encryptedData = data? cipher.encrypt(JSON.stringify(data)):null;

    return axios.post(`${config.apiURL}/newsletter-subscription`, {
        data: encryptedData
    })
}

export function submitReferralInvitation (email, customerId, integrationId, referralLink, firstName, referralBonus, clientId=config.sdk.clientId, language=config.locale) {
    const cipher = aes256.createCipher(clientId);
    const data = {
        apiUrl:config.apiURL,
        customerId: customerId,
        email:email,
        integrationId:integrationId,
        referralLink:referralLink,
        language: language,
        firstName: firstName, 
        referralBonus: referralBonus
    };
    const encryptedData = data? cipher.encrypt(JSON.stringify(data)):null;

    return axios.post(`${config.apiURL}/referral-invitation`, {
        data: encryptedData
    })
}

export function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function login(email, password) {
    return directus.login({
        email: email,
        password: password,
    })
    .then(res => res.data.user)
}

function logout() {
    return directus.logout();
}

function getMe() {
    return directus.getMe();
}

export default { login, logout, getMe }