import React, { Component } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  InlineTextButton,
  ExternalLink,
} from '../../components';
import config from '../../config';

import QuoteImage from './shayon_quote.jpg';
import QuoteImage2X from './shayon_quote@2x.jpg';
import TooltipImg from './tooltip-img.png';

import css from './SubscriptionPage.css';

const PLAN_BASIC = {
  monthly: {
    price: 29,
    changeLinkText: 'Monthly billing',
    billed: 'Billed monthly',
    switchTo: 'monthly',
    chargebeeLink: 'https://studiotimeio.chargebee.com/hosted_pages/plans/basic-monthly',
  },
  semiannual: {
    price: 23,
    changeLinkText: 'Semi-annual billing - save 20%',
    billed: 'Billed every 6 months',
    switchTo: 'semiannual',
    chargebeeLink: 'https://studiotimeio.chargebee.com/hosted_pages/plans/basic-semi-annual',
  },
  annual: {
    price: 20,
    changeLinkText: 'Yearly billing - save 30%',
    billed: 'Billed every year',
    switchTo: 'annual',
    chargebeeLink: 'https://studiotimeio.chargebee.com/hosted_pages/plans/basic-annual',
  },
};

const PLAN_PREMIUM = {
  monthly: {
    price: 199,
    changeLinkText: 'Monthly Billing',
    billed: 'Billed monthly',
    switchTo: 'monthly',
    chargebeeLink: 'https://studiotimeio.chargebee.com/hosted_pages/plans/premium-monthly',
  },
  semiannual: {
    price: 159,
    changeLinkText: 'Semi-annual billing - save 20%',
    billed: 'Billed every 6 months',
    switchTo: 'semiannual',
    chargebeeLink: 'https://studiotimeio.chargebee.com/hosted_pages/plans/premium-semi-annual',
  },
  annual: {
    price: 139,
    changeLinkText: 'Yearly billing - save 30%',
    billed: 'Billed every year',
    switchTo: 'annual',
    chargebeeLink: 'https://studiotimeio.chargebee.com/hosted_pages/plans/premium-annual',
  },
};

class SubscriptionPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      planBasic: 'semiannual',
      planPremium: 'semiannual',
    };
  }

  render() {
    // prettier-ignore
    const selectPlanClasses = plan => {
      return classNames(css.linkBillingSwitch, { [css.activeBilling]: this.state.planPremium === plan });
    }

    return (
      <StaticPage
        title="List your music studio on Studiotime"
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Website',
          description: 'Why list your music studio on Studiotime?',
          name: 'About page',
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain className={css.staticPageWrapper}>
            <div className={css.sectionOffer}>
              <div className={css.sectionOfferContent}>
                <h1 className={css.pageHeader}>Studio marketing that’s right for you</h1>
                <p className={css.pageSubheader}>
                  From home studios to top-line studios, we help you generate more bookings and
                  increase your revenue.
                </p>

                <div className={css.plansContainer}>
                  <div className={css.billingOptions}>
                    <InlineTextButton
                      className={selectPlanClasses(PLAN_PREMIUM.monthly.switchTo)}
                      onClick={() => {
                        this.setState({
                          planBasic: PLAN_BASIC.monthly.switchTo,
                          planPremium: PLAN_PREMIUM.monthly.switchTo,
                        });
                      }}
                    >
                      {PLAN_BASIC.monthly.changeLinkText}
                    </InlineTextButton>
                    <InlineTextButton
                      className={selectPlanClasses(PLAN_PREMIUM.semiannual.switchTo)}
                      onClick={() => {
                        this.setState({
                          planBasic: PLAN_BASIC.semiannual.switchTo,
                          planPremium: PLAN_PREMIUM.semiannual.switchTo,
                        });
                      }}
                    >
                      {PLAN_PREMIUM.semiannual.changeLinkText}
                    </InlineTextButton>
                    <InlineTextButton
                      className={selectPlanClasses(PLAN_PREMIUM.annual.switchTo)}
                      onClick={() => {
                        this.setState({
                          planBasic: PLAN_BASIC.annual.switchTo,
                          planPremium: PLAN_PREMIUM.annual.switchTo,
                        });
                      }}
                    >
                      {PLAN_PREMIUM.annual.changeLinkText}
                    </InlineTextButton>
                  </div>
                  <div className={css.billingOptionsMobile}>
                    <h3>Choose billing period</h3>
                    <select
                      value={this.state.planPremium}
                      onChange={e => {
                        const value = e.target.value;
                        this.setState({
                          planBasic: PLAN_BASIC[value].switchTo,
                          planPremium: PLAN_PREMIUM[value].switchTo,
                        });
                      }}
                    >
                      <option value={PLAN_PREMIUM.monthly.switchTo}>
                        {PLAN_PREMIUM.monthly.changeLinkText}
                      </option>
                      <option value={PLAN_PREMIUM.semiannual.switchTo}>
                        {PLAN_PREMIUM.semiannual.changeLinkText}
                      </option>
                      <option value={PLAN_PREMIUM.annual.switchTo}>
                        {PLAN_PREMIUM.annual.changeLinkText}
                      </option>
                    </select>
                  </div>
                  <div className={css.columns}>
                    <div className={css.leftColumn}>
                      <div className={css.planContainer}>
                        <div className={css.planName}>Basic Plan</div>
                        <div className={css.price}>
                          <span className={css.priceValue}>
                            ${PLAN_BASIC[this.state.planBasic].price}
                          </span>{' '}
                          /month
                        </div>
                        <ul className={css.features}>
                          <li className={css.feature}>
                            <b>One Studio</b> Listing
                          </li>
                          <li className={css.feature}>
                            <b>Services Add-Ons</b> For Listing
                          </li>
                          <li className={css.feature}>
                            <b>Direct User Messaging & Booking Request</b> Inbox
                          </li>
                          <li className={css.feature}>
                            <b>Booking Managment Tools</b>
                          </li>
                        </ul>
                        <div className={css.actions}>
                          <div className={css.buttonContainer}>
                            <ExternalLink
                              href={PLAN_BASIC[this.state.planBasic].chargebeeLink}
                              className={css.buyButton}
                            >
                              GET BASIC
                            </ExternalLink>
                          </div>
                          <div className={css.priceDetails}>
                            {PLAN_BASIC[this.state.planBasic].billed}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={css.rightColumn}>
                      <div className={classNames(css.planContainer, css.highlightedPlan)}>
                        <div className={classNames(css.planName, css.highlightedPlanName)}>
                          Premium Plan
                        </div>
                        <div className={css.price}>
                          <span className={css.priceValue}>
                            ${PLAN_PREMIUM[this.state.planPremium].price}
                          </span>{' '}
                          /month
                        </div>
                        <ul className={classNames(css.features, css.highlightedFeatures)}>
                          <li className={css.feature}>
                            <b>All Basic Features</b>
                          </li>
                          <li className={css.feature}>
                            <b>Premium Verified</b> Tag{' '}
                            <span
                              className={css.tooltip}
                              data-tip={`<div class="${css.tooltipWrapper}">
                                <span class="${css.tooltipTitle}">+45% Booking Engagement!</span>
                                <img class="${css.tooltipImg}" src="${
                                config.canonicalRootURL
                              }${TooltipImg}" />
                              </div>`}
                              data-html={true}
                            >
                              ?
                            </span>
                          </li>
                          <li className={css.feature}>
                            <b>Priority</b> Search Results
                          </li>
                          <li className={css.feature}>
                            <b>Multiple Studio</b> Listings
                          </li>
                          <li className={css.feature}>
                            <b>Marketing</b> Reach
                          </li>
                          <li className={css.feature}>
                            <b>Social Media</b> Reach
                          </li>
                          <li className={css.feature}>
                            <b>Dedicated Service &amp; Support</b>
                          </li>
                        </ul>

                        <div className={css.actions}>
                          <div className={css.buttonContainer}>
                            <ExternalLink
                              href={PLAN_PREMIUM[this.state.planPremium].chargebeeLink}
                              className={css.premiumButton}
                            >
                              GET PREMIUM
                            </ExternalLink>
                          </div>
                          <div className={css.priceDetails}>
                            {PLAN_PREMIUM[this.state.planPremium].billed}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={css.sectionHowItWorks}>
              <div className={css.sectionHowItWorksContent}>
                <h2 className={css.sectionHeader}>How Studiotime works for studios</h2>
                <p className={css.subheader}>
                  We’re the largest and most trusted online community of music studios. From home
                  studios to top-line studios, we’re relied on by studios to increase awareness and
                  generate bookings.
                </p>

                <div className={css.reasons}>
                  <div className={css.reason}>
                    <div className={css.reasonLowestFees} />
                    <h3 className={css.reasonTitle}>Lowest fees</h3>
                    <p className={css.reasonDescription}>
                      Add your studio for just $20/month*. No other booking fees or hidden fees.
                      We're the best marketing return for studios looking to generate continual
                      booking revenue.
                    </p>
                  </div>
                  <div className={css.reason}>
                    <div className={css.reasonCommunication} />
                    <h3 className={css.reasonTitle}>Communication is easy</h3>
                    <p className={css.reasonDescription}>
                      We make it easy to communicate with users directly on our platform. Screen
                      booking requests, accept or deny requests, and confirm all the details through
                      our platform for the best customer experience possible.
                    </p>
                  </div>
                  <div className={css.reason}>
                    <div className={css.reasonControl} />
                    <h3 className={css.reasonTitle}>You’re in control</h3>
                    <p className={css.reasonDescription}>
                      You can list your studio directly, add additional service offerings, and list
                      your pricing on your own terms. We put you in control of your studio listing
                      on Studiotime and how you market your studio and generate revenue.
                    </p>
                  </div>
                  <div className={css.reason}>
                    <div className={css.reasonCare} />
                    <h3 className={css.reasonTitle}>We care</h3>
                    <p className={css.reasonDescription}>
                      We’re here to provide the best service possible to both you and users that
                      book your studio. You can reach out to our team directly at any time for help!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={css.sectionQuote}>
              <div className={css.sectionQuoteContent}>
                <div className={css.sectionQuoteWrapper}>
                  <img
                    className={css.quoteImage}
                    src={QuoteImage}
                    srcSet={`${QuoteImage}, ${QuoteImage2X} 2x`}
                    alt="Mike"
                  />
                  <div className={css.quoteWrapper}>
                    <p className={css.quote}>
                      “It was a breeze getting our studio setup and within the first day I had a
                      session booked with an artist that was visting LA! Studiotime gives me peace
                      of mind for bookings and I would highly recommend it to other studio owners.”
                    </p>
                    <p className={css.author}>
                      <span className={css.authorName}>Shayon,</span>
                      <br />Jenga Studios
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={css.sectionFAQ}>
              <div className={css.sectionFAQContent}>
                <h2 className={classNames(css.sectionHeader, css.faqHeader)}>
                  Frequently Asked Questions
                </h2>

                <h3 className={css.faqQuestion}>How does Studiotime work?</h3>
                <p className={css.faqAnswer}>
                  Studiotime is a marketplace for booking music studios, ranging from home studios
                  to some of the most unique and exclusive studios in the world.
                </p>
                <p className={css.faqAnswer}>
                  Studios can easily create a listing in minutes and offer it to our community of
                  artists that are looking to book music studios.
                </p>
                <p className={css.faqAnswer}>
                  Artists can search our studios to find the right one, request to book, and easily
                  confirm through our platform. Studios get to keep the entire booking payment and
                  have the peace of mind that comes with using our platform messaging, booking
                  management tools, and a dedicated support team.
                </p>

                <h3 className={css.faqQuestion}>Is Studiotime right for my studio?</h3>
                <p className={css.faqAnswer}>
                  Studiotime is the largest and most trusted online community of music studios in
                  the world. What makes Studiotime unique is the range of studios that are offered
                  on Studiotime and the artists that use Studiotime to book studios.
                </p>
                <p className={css.faqAnswer}>
                  If you’re a music studio that is looking to offer your studio to others,
                  Studiotime is right for you. We take pride in helping artists find the right
                  studios and have an experience that is unique to each studio on Studiotime. If
                  you’re still unsure if your studio is a fit for Studiotime, please reach out to
                  our team and we’d love to chat.
                </p>

                <h3 className={css.faqQuestion}>How do I get started?</h3>
                <p className={css.faqAnswer}>
                  Adding your studio to Studiotime is easy and takes minutes. Once you’ve created a
                  Studiotime account, you can select a subscription above. After you select a
                  subscription, simply click “Add your studio” at the top right. Please note that
                  our team reviews all listings to ensure the safety of our community.
                </p>

                {/* <NamedLink name="HelpCenterPage" className={css.faqLink}>
                  View all FAQ
                </NamedLink> */}
              </div>
            </div>
            <div className={css.sectionGetStarted}>
              <div className={css.sectionGetStartedContent}>
                <div className={css.getStartedWrapper}>
                  <h2 className={css.sectionHeader}>
                    List your studio and increase your bookings.
                  </h2>
                  <NamedLink name="NewListingPage" className={css.getStartedLink}>
                    GET STARTED
                  </NamedLink>
                </div>
              </div>
            </div>
            <ReactTooltip place="right" type="light" effect="solid" html={true} />
          </LayoutWrapperMain>

          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </StaticPage>
    );
  }
}

export default SubscriptionPage;