import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import arrayMutators from 'final-form-arrays';
import { Form, Button, FieldTextInput, FieldSelect, FieldCheckboxGroup, FieldPhoneNumberInput } from '../../components';

import css from './EditListingPoliciesForm.css';

export const EditListingPoliciesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        policyAgreementOptions,
        fetchErrors,
        skillsetOptions
      } = formRenderProps;

      // General
      const generalPlaceholderMessage = intl.formatMessage({id: 'EditListingPoliciesForm.generalPlaceholder' });
      const generalRequiredMessage = intl.formatMessage({ id: 'EditListingPoliciesForm.generalRequired'});

      // Planning
      const planningLabelMessage = intl.formatMessage({ id: 'EditListingPoliciesForm.planning' });
      const planningPlaceholderMessage = intl.formatMessage({id: 'EditListingPoliciesForm.planningPlaceholder' });
      const planningRequiredMessage = intl.formatMessage({ id: 'EditListingPoliciesForm.planningRequired'});

      // Phone
      const phoneLabelMessage = intl.formatMessage({ id: 'EditListingPoliciesForm.phoneLabel' });
      const phonePlaceholderMessage = intl.formatMessage({id: 'EditListingPoliciesForm.phonePlaceholder' });
      const phoneRequiredMessage = intl.formatMessage({ id: 'EditListingPoliciesForm.phoneRequired'});

      // Social Media
      const socialMediaLabelMessage = intl.formatMessage({ id: 'EditListingPoliciesForm.socialMedia' });
      const socialMediaPlaceholderMessage = intl.formatMessage({id: 'EditListingPoliciesForm.socialMediaPlaceholder' });
      // const socialMediaRequiredMessage = intl.formatMessage({ id: 'EditListingPoliciesForm.socialMediaRequired'});

      // General
      const policyAgreementMessage = intl.formatMessage({id: 'EditListingPoliciesForm.policyAgreement' });
      const policyAgreementRequiredMessage = intl.formatMessage({ id: 'EditListingPoliciesForm.policyAgreementRequired'});

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

        
          {/* <FieldPhoneNumberInput
            className={css.policy}
            name="phoneNumber"
            id="phoneNumber"
            label={phoneLabelMessage}
            placeholder={phonePlaceholderMessage}
            validate={composeValidators(required(phoneRequiredMessage))}
          /> */}
          
          <FieldTextInput
            id="socialMedia"
            name="socialMedia"
            className={css.policy}
            type="textarea"
            label={socialMediaLabelMessage}
            placeholder={socialMediaPlaceholderMessage}
            // validate={composeValidators(required(planningRequiredMessage))}
          />

          {skillsetOptions ? skillsetOptions.map(question => (

          <FieldSelect
            className={css.title}
            name={question.id}
            id={question.id}
            label={question.name}
            key={question.id}
            validate={composeValidators(required(generalRequiredMessage))}
            >
            <option value="">{generalPlaceholderMessage}</option>
            {question.value.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>
          )):null}

          <FieldCheckboxGroup 
            className={css.title} 
            id="policyAgreement"
            name="policyAgreement"
            label={policyAgreementMessage}
            options={policyAgreementOptions}
            validate={required(policyAgreementRequiredMessage)}
            />
{/* 
          <FieldTextInput
            id="planning"
            name="planning"
            className={css.policy}
            type="textarea"
            label={planningLabelMessage}
            placeholder={planningPlaceholderMessage}
            validate={composeValidators(required(planningRequiredMessage))}
          /> */}
{/* 
          <p className={css.tip}>
            <FormattedMessage id="EditListingPoliciesForm.planningTip" />
          </p> */}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  policyAgreementOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
