import React from 'react';
import AddressLinkMaybe from './AddressLinkMaybe';

import css from './TransactionPanel.css';

// Functional component as a helper to build detail card headings
const DetailCardHeadingsMaybe = props => {
  const {
    showDetailCardHeadings,
    listingTitle,
    subTitle,
    location,
    geolocation,
    googleMap,
    showAddress,
    onlineMeetingLink,
    isOnlineExperience 
  } = props;

  return showDetailCardHeadings ? (
    <div className={css.detailCardHeadings}>
      <h2 className={css.detailCardTitle}>{listingTitle}</h2>
      <p className={css.detailCardSubtitle}>{subTitle}</p>
      <AddressLinkMaybe 
        location={location} 
        geolocation={geolocation} 
        showAddress={showAddress}
        googleMap={googleMap}  
        onlineMeetingLink={onlineMeetingLink}
        isOnlineExperience={isOnlineExperience}
      />
    </div>
  ) : null;
};

export default DetailCardHeadingsMaybe;
