import config from '../config';

// export function verifyTransaction(verificationCode, userId, language=config.locale) {
//     return axios.post("https://api.dotcept.com/transaction-verification", {
//         transaction: verificationCode,
//         userId: userId,
//     })
// }

export const SHARE_TO_WHATSAPP = "whatsapp";
export const SHARE_TO_TWITTER = "twitter";
export const SHARE_TO_LINK = "link";
export const SHARE_TO_EMAIL = "email";
export const SHARE_TO_URL = "url";

export function getReferralLink(referralCode, referralBonus, socialMediaLink) {

    if(!referralCode || !referralBonus || !socialMediaLink) {
        return "";
    }

    var socialLink;
    const referralLink = `${config.canonicalRootURL}${config.custom.referralLink}${referralCode}`;

    switch(socialMediaLink) {
        case SHARE_TO_WHATSAPP:
            socialLink = config.custom.shareToWhatsAppReferralLink;
            break;
        case SHARE_TO_TWITTER:
            socialLink = config.custom.shareToTwitterReferralLink;
            break;
        case SHARE_TO_EMAIL:
            socialLink = config.custom.shareToMailReferralLink;
            break;
        case SHARE_TO_URL:
            return referralLink;
            break;
        default:
            socialLink = config.custom.shareToClipboardReferralLink;
            break;
    }
    socialLink = socialLink.replace(config.custom.referralBonusPlaceholder, referralBonus);
    return `${socialLink}${referralLink}`;
}

export function getShareListingLink(listing, shareableLink, socialMediaLink, currentUser) {
    if(!listing || !listing.attributes || !listing.author || !shareableLink) {
        return "";
    }

    var socialLink;
    const listingName = listing.attributes.title? listing.attributes.title:"";
    const listingLocation = listing.attributes.publicData && listing.attributes.publicData.googleMap ? listing.attributes.publicData.googleMap:"";
    const listingProviderName = listing.author.attributes.profile && listing.author.attributes.profile.displayName? listing.author.attributes.profile.displayName:"";
    
    switch(socialMediaLink) {
        case SHARE_TO_WHATSAPP:
            socialLink = config.custom.shareToWhatsAppLink;
            break;
        case SHARE_TO_TWITTER:
            socialLink = config.custom.shareToTwitterLink;
            break;
        case SHARE_TO_EMAIL:
            socialLink = config.custom.shareToMailLink;
            break;
        default:
            socialLink = config.custom.shareToClipboardLink;
            break;
    }

    socialLink = socialLink.replace(config.custom.listingUrlPlaceholder, shareableLink);
    socialLink = socialLink.replace(new RegExp(config.custom.listingNamePlaceholder, "g"), listingName);
    socialLink = socialLink.replace(config.custom.listingLocationPlaceholder, listingLocation);
    socialLink = socialLink.replace(config.custom.providerNamePlaceholder, listingProviderName);

    if(!currentUser || !currentUser.attributes || !currentUser.attributes.loyalty || !currentUser.attributes.loyalty.membership) {
        return socialLink;
    }

    const referralBonus = currentUser.attributes.loyalty.membership.referralBonus.toLocaleString();
    const referralCode = currentUser.attributes.loyalty.referralCode;
    const separator = socialMediaLink == SHARE_TO_LINK? config.custom.clipboardReferralSeparator:config.custom.referralSeparator;
    const referralLink = getReferralLink(referralCode, referralBonus, SHARE_TO_LINK);

    return `${socialLink}${separator}${referralLink}`;
}