import config from '../config';

export const getRegion = (publicData) => {
    const regionArray = publicData && publicData.region ? publicData.region: null;
    const region = regionArray && typeof regionArray === 'object' && regionArray.length > 0? regionArray[0]:regionArray;
    return region;
}

export const getMainRegion = (regionOptions, region) => {
    const result = regionOptions.find(obj => {
      return obj.key === region
    });

    if (result && result.mainRegion) {
      return result.mainRegion;
    }else {
      return '';
    } 
  }