import React from 'react';
import { string,array } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import { KeywordSearchForm } from '../../forms';
import css from './SectionHero.css';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';

import landingVideo from './videos/landing-modified.webm';
import landingVideoMp4 from './videos/landing-modified.mp4';


const SectionHero = props => {
  const { 
    rootClassName, 
    className,
    themeOptions,
    history,
    defaultAddress,
    hashtag,
    sectionHeroTitle,
    sectionHeroSubtitle,
    isMobileSafari,
    defaultBounds
  } = props;

  const handleSearchSubmit = values => {
    const { keywords, theme } = values;
    const searchParams = {};

    searchParams["bounds"] = defaultBounds;
    searchParams["address"] = defaultAddress;
    
    if (keywords && keywords.length > 0) {
      searchParams["keywords"] = keywords;
    }
    if (theme && theme.length > 0) {
      searchParams["pub_theme"] = theme;
    }
  
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  const classes = classNames(rootClassName || css.root, className);
  
  return (
    <div className={classes}>
        <video playsInline loop autoPlay muted className={css.videoContainer}>
          <source src={landingVideo} type="video/webm" />
          <source src={landingVideoMp4} type="video/mp4" />
        </video>

      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" />
          {/* {sectionHeroTitle} */}
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
          {/* {sectionHeroSubtitle} */}
        </h2>
        <KeywordSearchForm 
          onSubmit={handleSearchSubmit} 
          themeOptions={themeOptions}
        />
        <div className={css.keywordContainer}>
          <div className={css.keywordTitleContainer}>
            <FormattedMessage id="SectionHero.hotKeyword" />
          </div>
          <div className={css.separaterContainer}><span>|</span></div>
          <div className={css.keywordListContainer}>
            {hashtag? hashtag.map(obj => (
              <NamedLink key={obj} name="SearchPage"  to={{ search: `keywords=${obj}` }} className={css.keywordBadge}>
                {obj}
              </NamedLink>
            )):null}
          </div>
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { 
  rootClassName: null, 
  className: null,
  hashtag:null,
  sectionHeroTitle:null,
  sectionHeroSubtitle:null,
};

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  hashtag:array,
  sectionHeroTitle:string,
  sectionHeroSubtitle:string,
};

export default SectionHero;
