import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage,injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '..';
import config from '../../config';
import css from './ModalQRCodeVerificationStatus.css';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

class ModalQRCodeVerificationStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceClose: false,
    }
  }

  render() {
    const {
      rootClassName,
      className,
      containerClassName,
      onManageDisableScrolling,
      showQRCodeModal,
      onClose,
      transactionVerification,
      intl
    } = this.props;
    
    const classes = classNames(rootClassName || css.root, className);
    const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

    const isValidVerification = transactionVerification? transactionVerification.status == "200": true;

    const format = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      month: 'short',
      day: 'numeric',
    };

    const endTimeFormat = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    const listingTitle = transactionVerification? transactionVerification.listing.title: null;
    const verificationTime = transactionVerification && transactionVerification?.verification.createdOn? intl.formatDate(transactionVerification?.verification.createdOn, format):null;
    const startTime = transactionVerification? intl.formatDate(transactionVerification?.booking.startTime, format):null;
    const endTime = transactionVerification? intl.formatDate(transactionVerification?.booking.endTime, endTimeFormat):null;

    const quantity = transactionVerification? transactionVerification.booking.seats:null;
    const customerName = transactionVerification? transactionVerification.customer.displayName:null;
    const providerName = transactionVerification? transactionVerification.provider.displayName:null;
    const payTotal = transactionVerification? formatMoney(intl, new Money(transactionVerification.payInTotal.amount, transactionVerification.payInTotal.currency)): null;
  
    const separator = config.custom.separator;
    const lineItems = transactionVerification? transactionVerification.lineItems.join(separator):null;

    const content = (
      <div className={css.container}>
        <h1 className={classNames(css.header, isValidVerification? null:css.failedHeader)}>
          {isValidVerification ?(
            <FormattedMessage id="ModalQRCodeVerificationStatus.verifiedTitle"/>
          ):(
            <FormattedMessage id="ModalQRCodeVerificationStatus.verificationFailedTitle"/>
          )}
            
        </h1>
        <h2 className={css.message}>
          {isValidVerification ?(
            <FormattedMessage id="ModalQRCodeVerificationStatus.verifiedDescription"/>
          ):(
            <FormattedMessage id="ModalQRCodeVerificationStatus.failedDescription"/>
          )}
        </h2>
        <div className={css.text} >
          <FormattedMessage id="ModalQRCodeVerificationStatus.listingTitle"/>
          {listingTitle}
        </div>
        <div className={css.text} >
          <FormattedMessage id="ModalQRCodeVerificationStatus.providerTitle"/>
          {providerName}
        </div>
        <div className={css.text} >
          <FormattedMessage  id="ModalQRCodeVerificationStatus.bookingTitle" />
          {startTime} - {endTime}
        </div>

        <div className={css.text} >
          <FormattedMessage id="ModalQRCodeVerificationStatus.customerTitle"/>
          {customerName}
        </div>
        <div className={css.text} >
          <FormattedMessage id="ModalQRCodeVerificationStatus.bookingSeats"/>
          {quantity}
        </div>
        {lineItems && lineItems.length > 0 ? (
          <div className={css.text} >
            <FormattedMessage id="ModalQRCodeVerificationStatus.lineItemTitle"/>
            {lineItems}
          </div>
        ):null}
        <div className={css.text} >
          <FormattedMessage id="ModalQRCodeVerificationStatus.totalTitle"/>
          {payTotal}
        </div>
        {verificationTime? (
          <div className={css.text} >
            <FormattedMessage id="ModalQRCodeVerificationStatus.verificationTitle"/>
            {verificationTime}
          </div>
        ):null}
      </div>
    );

    const closeButtonMessage = <FormattedMessage id="ModalQRCodeVerificationStatus.close" />;

    return (
      <Modal
        id="ModalQRCodeVerificationStatus"
        containerClassName={containerClassName}
        isOpen={showQRCodeModal}
        disableCloseBtn={false}
        onClose={onClose}
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
        forceClose={false}
      >
        {content}
      </Modal>
    );
  }
}

ModalQRCodeVerificationStatus.defaultProps = {
  className: null,
  rootClassName: null,
  forceClose: false,
  shareLink: null,
  transactionVerification: null,
};

ModalQRCodeVerificationStatus.propTypes = {
  className: string,
  rootClassName: string,
  containerClassName: string,
  // transactionVerification: string.isRequired,
  onManageDisableScrolling: func.isRequired,
  intl: intlShape.isRequired,
  onClose: func.isRequired
};

ModalQRCodeVerificationStatus.displayName = 'ModalQRCodeVerificationStatus';

export default injectIntl(ModalQRCodeVerificationStatus);
