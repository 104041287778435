import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import arrayMutators from 'final-form-arrays';
import { Form, Button, FieldTextInput, FieldSelect, FieldCheckboxGroup } from '../../components';
import CustomCertificateSelectFieldMaybe from './CustomCertificateSelectFieldMaybe';

import css from './EditListingDescriptionForm.css';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        certificateOptions,
        typeOptions,
        themeOptions,
        languageOptions,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      // Title
      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.titlePlaceholder'});
      const titleRequiredMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.titleRequired'});
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      // Type
      const typeMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.type'});
      const typePlaceholderMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.typePlaceholder'});
      const typeRequiredMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.typeRequired'});

      // Theme
      const themeMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.theme'});
      const themePlaceholderMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.themePlaceholder'});
      const themeRequiredMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.themeRequired'});

      // Language
      const languageMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.language'});
      const languageRequiredMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.languageRequired'});

      // // Quantity
      const quantityMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.quantity'});
      const quantityPlaceholderMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.quantityPlaceholder'});
      const quantityRequiredMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.quantityRequired'});

      // Duration
      const durationMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.duration'});
      const durationPlaceholderMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.durationPlaceholder'});
      const durationRequiredMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.durationRequired'});


      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />

          <FieldTextInput
            id="quantity"
            name="quantity"
            className={css.title}
            type="number"
            step="1"
            label={quantityMessage}
            placeholder={quantityPlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(quantityRequiredMessage))}
          />

          <FieldTextInput
            id="duration"
            name="duration"
            className={css.title}
            type="number"
            step=".01"
            label={durationMessage}
            placeholder={durationPlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(durationRequiredMessage))}
          />

          <FieldSelect
            className={css.title}
            name="type"
            id="type"
            label={typeMessage}
            validate={required(typeRequiredMessage)}
          >
            <option value="">{typePlaceholderMessage}</option>
            {typeOptions ?typeOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            )):null}
          </FieldSelect>

          {/* <FieldSelect
            className={css.title}
            name="theme"
            id="theme"
            label={themeMessage}
            validate={required(themeRequiredMessage)}
          >
            <option value="">{themePlaceholderMessage}</option>
            {themeOptions? themeOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            )):null}
          </FieldSelect> */}

          <FieldCheckboxGroup 
            className={css.title} 
            id="theme"
            name="theme"
            label={themeMessage}
            options={themeOptions}
            validate={required(themeRequiredMessage)}
            />

          <FieldCheckboxGroup 
            className={css.title} 
            id="language"
            name="language"
            label={languageMessage}
            options={languageOptions}
            validate={required(languageRequiredMessage)}
            />

          {/* <CustomCertificateSelectFieldMaybe
            id="certificate"
            name="certificate"
            certificateOptions={certificateOptions}
            intl={intl}
          /> */}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  typeOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  themeOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  languageOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  )
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
