/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import axios from 'axios';
import { array, arrayOf, bool, func, object, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  Modal,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  ModalSharingListing,
  SectionExperienceListing
} from '../../components';
import { EnquiryForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';

import {
  sendEnquiry,
  loadData,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  resetCode,
  checkCode,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionCapacity from './SectionCapacity';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionMapMaybe from './SectionMapMaybe';
import SectionHostMaybe from './SectionHostMaybe';
import SectionBasicInformationMaybe from './SectionBasicInformationMaybe';
import SectionFirstTrail from './SectionFirstTrail';
import SectionServiceMaybe from './SectionServiceMaybe';
import SectionNoticeMaybe from './SectionNoticeMaybe';
// import SectionHostExpericnceMaybe from './SectionHostExpericnceMaybe';
// import SectionRecommendationsMaybe from './SectionRecommendationsMaybe';
import SectionImageList from './SectionImageList';
import css from './ListingPage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      bookingsCount: 0,
      viewCount: 0,
      isPopular: false,
      imageCarouselOpen: false,
      imageListCarouselOpen: false,
      imageListCarouselIndex: -1,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);

    // const [showShareModal, setShowShareModal] = useState(false);
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingStartTime, bookingEndTime, ...restOfValues } = values;
    const bookingStart = timestampToDate(bookingStartTime);
    const bookingEnd = timestampToDate(bookingEndTime);

    const bookingData = {
      // quantity: calculateQuantityFromHours(bookingStart, bookingEnd),
      quantity:values.quantity,
      ...restOfValues,
    };
    
    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart,
        bookingEnd,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  componentWillReceiveProps(prevProps, prevState) {
    // let path = window.location.pathname;
    // let splitPath = path.split('/');
    // let id = splitPath.length >= 3 ? splitPath[splitPath.length - 1] : null;
    // const self = this;
    // const urlPrefix = window.location.hostname === 'localhost' ? 'http://localhost:3000' : '';

    // const { incrementedCount } = this.state;
    // const isPopular = config.custom.isPopular;

    //send tracking call only after all needed info is available
    //POST info about the current view as well as receiving an updated view count

    // if (!incrementedCount && this.props.currentUser) {
    //   const urlPrefix = window.location.hostname === 'localhost' ? 'http://localhost:3000' : '';
    //   const userId = this.props.currentUser ? this.props.currentUser.id.uuid : 'anon';

    //   axios.get(`${urlPrefix}/viewcount/${id}/${userId}`).then(function(response) {
    //     const vCount = response.data.visitorCount;

    //     if (self.state.viewCount !== vCount) {
    //       console.log('updated viewCount to', vCount);
    //       self.setState({
    //         viewCount: vCount,
    //         isPopular: Boolean(vCount > isPopular),
    //       });
    //     }
    //   });

    //   this.setState({
    //     incrementedCount: true,
    //   });
    // }

    // /getBookingCount/:countType/:id
    // get the count of bookings for this listing and update the UI
    // axios.get(`${urlPrefix}/getBookingCount/listing/${id}`).then(function(response) {
    //   const bCount = response.data.listingBookings;

    //   if (self.state.bookingsCount !== bCount) {
    //     console.log('updated bookingsCount to', bCount);
    //     self.setState({
    //       bookingsCount: bCount,
    //     });
    //   }
    // });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      listings,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      sameThemeListings,
      onFetchTimeSlots,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      monthlyTimeSlots,
      filterConfig,
      customFilterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      checkedCode,
      checkCodeInProgress,
      checkCodeErorr,
      onCheckingVoucher,
      onResetCode,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    // const userId = new UUID();
    // const listings = getListings(currentListing?.author?.id);

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    let isPopular = this.state.isPopular;
    if (
      currentListing &&
      currentListing.attributes &&
      currentListing.attributes.publicData &&
      currentListing.attributes.publicData.forcePopularTag
    ) {
      isPopular = true;
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };

    const handleImageItemClick = (e, index) => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageListCarouselIndex: index,
        imageListCarouselOpen: true,
      });
    };

    const generateListingLink = () => {
      const routes = routeConfiguration();
  
      const link =
        currentListing && currentListing.id
          ? createResourceLocatorString(
              'ListingPage',
              routes,
              { id: currentListing.id.uuid, slug: createSlug(currentListing.attributes.title) },
              {}
            )
          : '/';
      return `${config.canonicalRootURL}${link}`;
    };
    
    const onOpenSharingModal = e => {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        showShareModal: true
      });
      // setShowShareModal(() => true);
    };

    const onCloseSharingModal = e => {
      e.preventDefault();
      e.stopPropagation();
      // setShowShareModal(() => false);
      this.setState({
        showShareModal: false
      });
    };

    // const onShareToFacebookTimeline = message => {
    //   if (!window) return;
    //   const link = generateListingLink();
  
    //   window.FB.ui(
    //     {
    //       method: 'share',
    //       quote: message,
    //       href: link,
    //     },
    //     function(response) {}
    //   );
    // };
  
    // const onShareToMessenger = () => {
    //   if (!window) return;
    //   const link = generateListingLink();
    //   window.FB.ui({
    //     method: 'send',
    //     link: link,
    //   });
    // };

    // const loadFbSdk = () => {
    //   if (window) {
    //     window.fbAsyncInit = function() {
    //       window.FB.init({
    //         appId: config.facebookAppId,
    //         cookie: true, // enable cookies to allow the server to access
    //         // the session
    //         autoLogAppEvents: true,
    //         xfbml: true, // parse social plugins on this page
    //         version: 'v3.0',
    //       });
    //     };
  
    //     // Load the SDK asynchronously
    //     (function(d, s, id) {
    //       var js,
    //         fjs = d.getElementsByTagName(s)[0];
    //       if (d.getElementById(id)) return;
    //       js = d.createElement(s);
    //       js.id = id;
    //       js.src = '//connect.facebook.net/en_US/sdk.js';
    //       fjs.parentNode.insertBefore(js, fjs);
    //     })(document, 'script', 'facebook-jssdk');
    //   }
    // };

    //loadFbSdk();
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    const shareableLink = generateListingLink();
    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const yogaStylesOptions = findOptionsForSelectFilter('yogaStyles', filterConfig);
    const certificateOptions = findOptionsForSelectFilter('certificate', filterConfig);
    const capacityOptions = findOptionsForSelectFilter('capacity', filterConfig);
    const typeOptions = findOptionsForSelectFilter('type', filterConfig);
    const serviceOptions = findOptionsForSelectFilter('service', customFilterConfig);
    const theme = publicData && publicData.theme && publicData.theme.length > 0? publicData.theme[0]:null;
    const isNoRecommendation = publicData?.isNoRecommendation;

    const authorExperienceTitle = (<FormattedMessage id="ListingPage.hostExperienceTitle" values={{ displayName: authorDisplayName }} />);
    const authorExperienceSectionId = "ListingPage.authorExperienceSection.imageListCarousel";
    const authorExperienceListingMinimumLength = 1;

    const recommendationTitle = (<FormattedMessage id="ListingPage.recommendationTitle" values={{ theme: theme }} />);
    const recommendationSectionId = "ListingPage.recommendationSection.imageListCarousel";
    const recommendationListingMinimumLength = 1;

    // DescriptionMaybe = Basic Information
    // CapacityMaybe = Detail Information

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              <SectionImages
                title={title}
                listing={currentListing}
                isOwnListing={isOwnListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingType,
                  tab: listingTab,
                }}
                imageCarouselOpen={this.state.imageCarouselOpen}
                onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                handleViewPhotosClick={handleViewPhotosClick}
                onOpenSharingModal={onOpenSharingModal}
                onManageDisableScrolling={onManageDisableScrolling}
              />
              <div className={css.contentContainer}>
                <SectionAvatar user={currentAuthor} params={params} />
                <div className={css.mainContent}>
                  <SectionHeading
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    richTitle={richTitle}
                    listingCertificate={publicData ? publicData.certificate : null}
                    certificateOptions={certificateOptions}
                    hostLink={hostLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                    publicData={publicData}
                    reviews={reviews}
                    viewCount={this.state.viewCount}
                    bookingsCount={this.state.bookingsCount}
                  />

                  {(reviews != null && reviews.length <= 0)? (<SectionFirstTrail intl={intl} />) :null}

                  <SectionBasicInformationMaybe 
                    displayName={authorDisplayName} 
                    publicData={publicData} 
                    intl={intl}
                    typeOptions={typeOptions}
                  />

                  <SectionDescriptionMaybe description={description} />
                  <SectionImageList
                    className={css.sectionImageList}
                    listing={currentListing}
                    intl={intl}
                    images={currentListing.images}
                    handleViewPhotosClick={handleImageItemClick}
                    imageCarouselOpen={this.state.imageListCarouselOpen}
                    imageListCarouselIndex={this.state.imageListCarouselIndex}
                    onImageCarouselClose={() => this.setState({
                      imageListCarouselOpen: false,
                      imageListCarouselIndex: -1
                    })}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                  <SectionServiceMaybe options={serviceOptions} publicData={publicData} />
                  {/* <SectionFeaturesMaybe options={yogaStylesOptions} publicData={publicData} /> */}
                  {/* <SectionCapacity publicData={publicData} options={capacityOptions} /> */}
                  <SectionMapMaybe
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                  />
                </div>
                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  currentUser={currentUser}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  checkedCode={checkedCode}
                  checkCodeInProgress={checkCodeInProgress}
                  checkCodeErorr={checkCodeErorr}
                  onCheckingVoucher={onCheckingVoucher}
                  onResetCode={onResetCode}
                />
              </div>
              <div className={css.secondaryContainer}>
                <div className={css.secondaryContent}>
                  <SectionNoticeMaybe 
                    publicData={publicData} 
                    intl={intl}
                  />
                  <SectionHostMaybe 
                    title={title}
                    listing={currentListing}
                    intl={intl}
                    // listings={hostListings}
                    authorDisplayName={authorDisplayName}
                    onContactUser={this.onContactUser}
                    isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                    onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    onSubmitEnquiry={this.onSubmitEnquiry}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                    publicData={publicData}
                    views={this.state.viewCount}
                    isPopular={isPopular}
                  />
                  {(reviews && reviews.length > 0)?
                  (<SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />):null}

                  {(listings && listings.length >= authorExperienceListingMinimumLength)? (
                    <SectionExperienceListing
                      title={authorExperienceTitle}
                      listings={listings}
                      sectionId={authorExperienceSectionId}
                      currentListing={currentListing}
                      minimumLength={authorExperienceListingMinimumLength}
                    />
                  ):null}

                  {(!isNoRecommendation && sameThemeListings && sameThemeListings.length >= recommendationListingMinimumLength)? (
                    <SectionExperienceListing
                      title={recommendationTitle}
                      listings={sameThemeListings}
                      sectionId={recommendationSectionId}
                      currentListing={currentListing}
                      minimumLength={recommendationListingMinimumLength}
                  />
                  ):null}

                </div>
              </div>
            </div>
            <Modal
              id="ListingPage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && this.state.enquiryModalOpen}
              onClose={() => this.setState({ enquiryModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <EnquiryForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendEnquiryError}
                onSubmit={this.onSubmitEnquiry}
                inProgress={sendEnquiryInProgress}
              />
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
        <ModalSharingListing
          containerClassName={css.missingInformationModal}
          onManageDisableScrolling={onManageDisableScrolling}
          showShareModal={this.state.showShareModal}
          currentUser={currentUser}
          // onShareFacebookTimeline={onShareToFacebookTimeline}
          // onShareFacebookMessenger={onShareToMessenger}
          currentListing={currentListing}
          onClose={onCloseSharingModal}
          shareableLink={shareableLink}
        />
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  customFilterConfig: config.custom.hiddenFilters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  // getListings: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  customFilterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    sameThemeListings,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    userListingRefs,
    checkedCode,
    checkCodeInProgress,
    checkCodeErorr,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const listings = getMarketplaceEntities(state, userListingRefs);

  // const getListings = id => {
  //   if (!id) {
  //     return null;
  //   }
  //   const ref = { id, type: 'listing' };
  //   const listings = getMarketplaceEntities(state, [ref]);
  //   console.log(listings);
  //   return listings.length > 1 ? listings : null;
  // };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    listings,
    getOwnListing,
    sameThemeListings,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    checkedCode,
    checkCodeInProgress,
    checkCodeErorr,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onCheckingVoucher: ({ code, listing, data }) => dispatch(checkCode({ code, listing, data })),
  onResetCode: () => dispatch(resetCode()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ListingPage.loadData = loadData;

// ListingPage.loadData = params => {
//   console.log(params);
//   // const id = new UUID(params.id);
//   // return loadData(id);
//   return loadData;
// };

export default ListingPage;
