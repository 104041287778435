import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import { ModalInMobile, Button } from '../../components';
import { BookingTimeForm } from '../../forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faTicketAlt,faCalendarCheck, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './BookingPanel.css';

const { Money } = sdkTypes;


// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location, listing) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;

    if(typeof window !== 'undefined') {
      window.mixpanelPageTracking(config.custom.mixpanelAction.selectBookingButton, listing);
    }

    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    isEnquired,
    titleClassName,
    currentUser,
    containerClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    customFilterConfig,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    checkCodeInProgress,
    checkedCode,
    onCheckingVoucher,
    checkCodeErorr,
    onResetCode,
  } = props;

  const price = listing.attributes.price;
  const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingTimeForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isBook = !!parse(location.search).book;

  const publicData = listing.attributes.publicData;

  const isValidOriginalPrice = publicData.originalPrice && publicData.originalPrice.amount > price.amount;
  const originalPrice = publicData.originalPrice && publicData.originalPrice.currency? (new Money(publicData.originalPrice.amount, publicData.originalPrice.currency)):null;
  const formattedOriginalPrice = (originalPrice || price)? formatMoney(intl, originalPrice?originalPrice:price):null;

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const customUnitKey = publicData && publicData.perUnit? (config.custom.unitSeparator + publicData.perUnit):null;
  
  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  const addOn1 = publicData.addOn?.addOn1;
  const addOn2 = publicData.addOn?.addOn2;
  const addOn3 = publicData.addOn?.addOn3;

  const addOn1Fee = (addOn1?.addOnTitle && addOn1?.addOnPrice?.amount && addOn1?.addOnPrice?.currency)? addOn1:null;
  const addOn2Fee = (addOn2?.addOnTitle && addOn2?.addOnPrice?.amount && addOn2?.addOnPrice?.currency)? addOn2:null;
  const addOn3Fee = (addOn3?.addOnTitle && addOn3?.addOnPrice?.amount && addOn3?.addOnPrice?.currency)? addOn3:null;

  const cutOffTimeOptions = findOptionsForSelectFilter('cutOffTime', customFilterConfig);
  const classes = classNames(rootClassName || css.root, className);
  // const titleClasses = classNames(titleClassName || css.bookingTitle);
  const titleClasses = classNames(css.bookingTitle);
  const containerClasses = classNames(containerClassName || css.modalContainer);
  const orderTitle = intl.formatMessage({ id: 'BookingPanel.orderTitle' });

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={containerClasses}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
        </div>
        {formattedPrice? (
        <div className={css.bookingHeading}>
          <div className={css.desktopPriceContainer}>
            <div className={css.desktopPriceValue} title={priceTitle}>
              {formattedPrice}
            </div>
            {isValidOriginalPrice? (
              <div className={css.desktopOriginalPrice}>
                {formattedOriginalPrice}
              </div>
            ):null}
            <div className={css.desktopPerUnit}>
              {customUnitKey? (
                <React.Fragment>
                  {customUnitKey}
                </React.Fragment>
              ):(
                <FormattedMessage id={unitTranslationKey} />
              )}    
            </div>
          </div>
          <div className={css.bookingHeadingContainer}>
            <h2 className={titleClasses}>
              <FontAwesomeIcon className={css.bookingIcon} icon={faTicketAlt} />
              {/* {title} */}
              {orderTitle}
            </h2>
            {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
          </div>
        </div>
        ):null}

        {showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.submitButtonWrapper}
            submitButtonClassName={css.bookButton}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            currentUser={currentUser}
            listing={listing}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            addOn1Fee={addOn1Fee}
            addOn2Fee={addOn2Fee}
            addOn3Fee={addOn3Fee}
            cutOffTimeOptions={cutOffTimeOptions}
            onResetCode={onResetCode}
            checkedCode={checkedCode}
            checkCodeErorr={checkCodeErorr}
            checkCodeInProgress={checkCodeInProgress}
            onCheckingVoucher={onCheckingVoucher}
            isEnquired={isEnquired}
          />
        ) : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.subPriceContainer}>
          {isValidOriginalPrice? (
            <div className={css.originalPrice}>
              {formattedOriginalPrice}
            </div>
          ):null}
          <div className={css.perUnit}>
              {customUnitKey? (
                <React.Fragment>
                  {customUnitKey}
                </React.Fragment>
              ):(
                <FormattedMessage id={unitTranslationKey} />
              )}
          </div>
          </div>
        </div>

        {showBookingTimeForm ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location, listing)}
          >
            <div className={css.mobileBookButton}>
              <FormattedMessage id="BookingPanel.ctaButtonMessage" />
            </div>
            <FontAwesomeIcon className={css.bookIcon} icon={faCalendarAlt} />

          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  containerClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  customFilterConfig: config.custom.hiddenFilters,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  containerClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  customFilterConfig: array,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
