import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { intlShape, injectIntl,FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingPoliciesForm } from '../../forms';
import config from '../../config.js';
import { findOptionsForSelectFilter } from '../../util/search';
import css from './EditListingPoliciesPanel.css';

const EditListingPoliciesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    intl,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData, privateData, protectedData } = currentListing.attributes;
  const skillsetOptions = findOptionsForSelectFilter('skillset', config.custom.hiddenFilters);
  const policyAgreementOptions = findOptionsForSelectFilter('policyAgreement', config.custom.hiddenFilters);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPoliciesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPoliciesPanel.createListingTitle" />
  );

  const basicRequirement = intl.formatMessage({ id: 'EditListingPoliciesPanel.basicRequirement' });
  const basicRequirementUrl = intl.formatMessage({ id: 'EditListingPoliciesPanel.basicRequirementUrl' });

  const experienceStandard = intl.formatMessage({ id: 'EditListingPoliciesPanel.experienceStandard' });
  const experienceStandardUrl = intl.formatMessage({ id: 'EditListingPoliciesPanel.experienceStandardUrl' });
  const prohibit = intl.formatMessage({ id: 'EditListingPoliciesPanel.prohibit' });
  const prohibitUrl = intl.formatMessage({ id: 'EditListingPoliciesPanel.prohibitUrl' });
  const sharePrice = intl.formatMessage({ id: 'EditListingPoliciesPanel.sharePrice' });
  const sharePriceUrl = intl.formatMessage({ id: 'EditListingPoliciesPanel.sharePriceUrl' });

  const approval = intl.formatMessage({ id: 'EditListingPoliciesPanel.approval' });
  const approvalUrl = intl.formatMessage({ id: 'EditListingPoliciesPanel.approvalUrl' });
  
  const editProfile = intl.formatMessage({ id: 'EditListingPoliciesPanel.editProfile' });
  const editProfileUrl = intl.formatMessage({ id: 'EditListingPoliciesPanel.editProfileUrl' });
  const tips = intl.formatMessage({ id: 'EditListingPoliciesPanel.tips' });
  const tipsUrl = intl.formatMessage({ id: 'EditListingPoliciesPanel.tipsUrl' });
  const verification = intl.formatMessage({ id: 'EditListingPoliciesPanel.verification' });
  const verificationUrl = intl.formatMessage({ id: 'EditListingPoliciesPanel.verificationUrl' });

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <div className={css.reminderContainer}>
        <div>
        <FormattedMessage id="EditListingPoliciesPanel.description" values={{ 
            basicRequirement: <a href={basicRequirementUrl} target="_blank">{basicRequirement}</a>,
            experienceStandard: <a href={experienceStandardUrl} target="_blank">{experienceStandard}</a>,
            prohibit: <a href={prohibitUrl} target="_blank">{prohibit}</a>,
            sharePrice: <a href={sharePriceUrl} target="_blank">{sharePrice}</a>
          }}/>
        </div>
        <div>
          <div className={css.stepTitle}>
          <FormattedMessage id="EditListingPoliciesPanel.approvalTitle" />
          </div>
        <ul className={css.stepContainer}>
          <li>
          <FormattedMessage id="EditListingPoliciesPanel.approvalStepOne" values={{ 
            editProfile: <a href={editProfileUrl} target="_blank">{editProfile}</a>,
            tips: <a href={tipsUrl} target="_blank">{tips}</a>
          }}/>
          </li>
          <li>
          <FormattedMessage id="EditListingPoliciesPanel.approvalStepTwo" values={{ 
            verification: <a href={verificationUrl} target="_blank">{verification}</a>
          }}/>
          </li>
          {/* <li>
          <FormattedMessage id="EditListingPoliciesPanel.approvalStepThree" />
          </li> */}
        </ul>
        </div>
        <div>
        <FormattedMessage id="EditListingPoliciesPanel.approvalFooter" values={{ 
            approval: <a href={approvalUrl} target="_blank">{approval}</a>
          }}/>
        </div>

      </div>
      <EditListingPoliciesForm
        className={css.form}
        initialValues={{ 
          skillset: privateData.skillset,
          q1: privateData.q1,
          // q2: privateData.q2,
          q3: privateData.q3,
          q4: privateData.q4,
          q5: privateData.q5,
          q6: privateData.q6,
          // phoneNumber: privateData.phoneNumber,
          socialMedia: privateData.socialMedia,
          policyAgreement: privateData.policyAgreement,
          // planning: privateData.planning
        }}
        onSubmit={values => {
          const { q1, q3, q4, q5, q6, socialMedia, policyAgreement  } = values;
          const updateValues = {
            privateData: {
              q1,
              q3,
              q4,
              q5,
              q6,
              // phoneNumber,
              socialMedia,
              policyAgreement
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        skillsetOptions={skillsetOptions}
        disabled={disabled}
        ready={ready}
        policyAgreementOptions={policyAgreementOptions}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPoliciesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPoliciesPanel.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default compose(injectIntl)(EditListingPoliciesPanel);
