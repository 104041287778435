import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage,injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '..';
import config from '../../config';

import css from './ModalAlert.css';

class ModalAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceClose: false,
    }
  }

  render() {
    const {
      rootClassName,
      className,
      containerClassName,
      titleClassName,
      onManageDisableScrolling,
      showAlertModal,
      onClose,
      title,
      description,
      intl
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const content = (
      <div className={css.container}>
        <h1 className={classNames(css.header, titleClassName)}>
            {title}
        </h1>
        <h2 className={css.message}>
            {description}
        </h2>
      </div>
    );

    const closeButtonMessage = <FormattedMessage id="ModalAlert.close" />;

    return (
      <Modal
        id="ModalAlert"
        containerClassName={containerClassName}
        isOpen={showAlertModal}
        disableCloseBtn={false}
        onClose={onClose}
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
        forceClose={false}
      >
        {content}
      </Modal>
    );
  }
}

ModalAlert.defaultProps = {
  className: null,
  rootClassName: null,
  forceClose: false,
  shareLink: null,
  titleClassName: null,
  title: null,
  description: null,
};

ModalAlert.propTypes = {
  className: string,
  rootClassName: string,
  containerClassName: string,
  titleClassName: string,
  title: string.isRequired,
  description: string.isRequired,
  onManageDisableScrolling: func.isRequired,
  intl: intlShape.isRequired,
  onClose: func.isRequired
};

ModalAlert.displayName = 'ModalAlert';

export default injectIntl(ModalAlert);
