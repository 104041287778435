import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './SectionImageList.css';
import { ImageCarousel, Modal, ResponsiveImage } from '../../components';
import {isMobile} from 'react-device-detect';

const SectionImageList = ({
  rootClassName,
  className,
  images,
  intl,
  listing,
  handleViewPhotosClick,
  imageCarouselOpen,
  imageListCarouselIndex,
  onImageCarouselClose,
  onManageDisableScrolling,
}) => {
  // const classes = classNames(rootClassName || (isMobile ? css.mobileRoot:css.root), className);
  // const columnClass = isMobile? css.mobileColumn:css.column;
  const classes = classNames(rootClassName || css.mobileRoot, className);
  const columnClass = css.mobileColumn;
  const videoSource = listing?.attributes?.publicData?.videoSource;

  return (
    <div className={classes}>
      <div className={columnClass}>
        {images.map((image, index) => (
          <div className={css.imageWrapper} key={index} >
            {/* <img 
              // onClick={(e) => handleViewPhotosClick(e, index * 3 + 1)}
              src={image.attributes.variants['scaled-medium'] && image.attributes.variants['scaled-medium'].url} 
              className={css.image} /> */}

          <ResponsiveImage
            className={css.image}
            // onClick={(e) => handleViewPhotosClick(e, index * 3 + 1)}
            alt={intl.formatMessage( { id: 'ImageCarousel.imageAltText' }, { index: index, count: images.length })}
            image={image}
            variants={['scaled-medium']}
            // sizes="(max-width: 767px) 100vw, 80vw"
          />
          </div>
        ))}
      {videoSource != null? (
        <video controls playsInline muted className={css.imageWrapper} preload="metadata">
            <source src={`${videoSource}#t=0.1`} type="video/mp4" />
        </video>
      ):null}
      </div>
      {/* <div className={columnClass}>
        {images.filter((_, index) => index % 3 === 0).map((image, index) => (
          <div className={css.imageWrapper} key={index}>
            <img 
              onClick={(e) => handleViewPhotosClick(e, index * 3)}
              src={image.attributes.variants['scaled-medium'] && image.attributes.variants['scaled-medium'].url} 
              className={css.image} />
          <ResponsiveImage
            className={css.image}
            onClick={(e) => handleViewPhotosClick(e, index * 3)}
            alt={intl.formatMessage( { id: 'ImageCarousel.imageAltText' }, { index: index, count: images.length })}
            image={image}
            variants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
            sizes="(max-width: 767px) 100vw, 80vw"
          />
          </div>
        ))}
      </div>
      <div className={columnClass}>
        {images.filter((_, index) => index % 3 === 1).map((image, index) => (
          <div className={css.imageWrapper} key={index} >
            <img 
              onClick={(e) => handleViewPhotosClick(e, index * 3 + 1)}
              src={image.attributes.variants['scaled-medium'] && image.attributes.variants['scaled-medium'].url} 
              className={css.image} />

          <ResponsiveImage
            className={css.image}
            onClick={(e) => handleViewPhotosClick(e, index * 3 + 1)}
            alt={intl.formatMessage( { id: 'ImageCarousel.imageAltText' }, { index: index, count: images.length })}
            image={image}
            variants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
            sizes="(max-width: 767px) 100vw, 80vw"
          />
          </div>
        ))}
      </div>
      <div className={columnClass}>
        {images.filter((_, index) => index % 3 === 2).map((image, index) => (
          <div className={css.imageWrapper} key={index}>
            <img 
              onClick={(e) => handleViewPhotosClick(e, index * 3 + 2)}
              src={image.attributes.variants['scaled-medium'] && image.attributes.variants['scaled-medium'].url} 
              className={css.image} />

          <ResponsiveImage
            className={css.image}
            onClick={(e) => handleViewPhotosClick(e, index * 3 + 2)}
            alt={intl.formatMessage( { id: 'ImageCarousel.imageAltText' }, { index: index, count: images.length })}
            image={image}
            variants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
            sizes="(max-width: 767px) 100vw, 80vw"
          />
          </div>
        ))}
      </div> */}
      <Modal
        id="ListingPage.imageListCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {imageListCarouselIndex >= 0 &&
          <ImageCarousel images={images} selectedImageIndex={imageListCarouselIndex}/>
        }
      </Modal>
    </div>
  )
}

SectionImageList.defaultList = {
  className: null,
  rootClassName: null,
  images: []
}

SectionImageList.propTypes = {
  className: PropTypes.string,
  rootClassName: PropTypes.string,
  images: PropTypes.array
}

export default SectionImageList;