import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink } from '../../components';
import classNames from 'classnames';

import css from './WeatherReminder.css';

class WeatherReminder extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.onAcceptCookies = this.onAcceptCookies.bind(this);
    this.saveCookieConsent = this.saveCookieConsent.bind(this);
  }

  componentDidMount() {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies.weatherAccepted !== '1') {
      this.setState({ show: true });
    }
  }

  onAcceptCookies() {
    this.saveCookieConsent();
    this.setState({ show: false });
  }

  saveCookieConsent() {
    // We create date object and modify it to show date 1 day into the future.
    let expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);
    // Save the cookie with expiration date
    document.cookie = 'weatherAccepted=1; path=/; expires=' + expirationDate.toGMTString();
  }

  render() {
    const { 
      className, 
      rootClassName, 
      weatherData,
      landingCmsData
    } = this.props;

    const isServer = typeof window === 'undefined';

    // Server side doesn't know about cookie consent
    if (isServer || !this.state.show) {
      return null;
    } else {
      const cookieLink = (
        <ExternalLink href="https://www.hko.gov.hk/tc/wxinfo/currwx/fnd.htm" className={css.cookieLink}>
          <FormattedMessage id="WeatherReminder.cookieLink" />
        </ExternalLink>
      );

      // const weatherForecast = weatherData? weatherData.forecastDesc.split('。'):null;
      // const weatherInfo = ((weatherForecast.length >= 2) ? (weatherForecast[0] + '。'):'') + ((weatherForecast.length >= 3) ? (weatherForecast[1] + '。'):'');
      // const weatherInfomation = weatherInfo && weatherInfo.length > 0 ? weatherInfo:(
      //   <FormattedMessage id="WeatherReminder.noMessage" />
      // );

      const weatherInfomation = landingCmsData && landingCmsData.promotion_message && landingCmsData.promotion_message.length > 0? landingCmsData.promotion_message:null;
      const classes = classNames(rootClassName || css.root, className);

      if(!weatherInfomation) {
        return null;
      }

      return (
        <div className={classes}>
          <div className={css.message}>
            <FormattedMessage id="WeatherReminder.message" values={{weatherInfomation, cookieLink }} />
          </div>
          <button className={css.continueBtn} onClick={this.onAcceptCookies}>
            <FormattedMessage id="WeatherReminder.continue" />
          </button>
        </div>
      );
    }
  }
}

const { string } = PropTypes;

WeatherReminder.defaultProps = {
  className: null,
  rootClassName: null,
  weatherData: null,
};

WeatherReminder.propTypes = {
  className: string,
  rootClassName: string,
  weatherData: object,
};

export default WeatherReminder;
