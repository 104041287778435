import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
//import config from '../../config';
import arrayMutators from 'final-form-arrays';
import { Form, Button, FieldTextInput, FieldSelect, FieldCheckboxGroup } from '../../components';

// Create this file using EditListingFeaturesForm.css
// as a template.
import css from './EditListingCapacityForm.css';

export const EditListingCapacityFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateError,
        updateInProgress,
        capacityOptions,
        cancellationOptions,
        serviceOptions,
        cutOffTimeOptions
      } = fieldRenderProps;

        // const capacityPlaceholder = intl.formatMessage({ id: 'EditListingCapacityForm.capacityPlaceholder' });
        // const capacityRequired = required( intl.formatMessage({ id: 'EditListingCapacityForm.capacityRequired' }));

        const errorMessage = updateError ? (
            <p className={css.error}>
            <FormattedMessage id="EditListingCapacityForm.updateFailed" />
            </p>
        ) : null;

        // Description
        const descriptionMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.description'});
        const descriptionPlaceholderMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.descriptionPlaceholder'});
        const descriptionRequiredMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.descriptionRequired'});

        // Service
        const serviceMessage = intl.formatMessage({ id: 'EditListingCapacityForm.service'});
        //const serviceRequiredMessage = intl.formatMessage({ id: 'EditListingCapacityForm.serviceRequired'});

        // Service Detail
        const serviceDetailMessage = intl.formatMessage({ id: 'EditListingCapacityForm.serviceDetail'});
        const serviceDetailPlaceholderMessage = intl.formatMessage({ id: 'EditListingCapacityForm.serviceDetailPlaceholder'});
        //const serviceDetailRequiredMessage = intl.formatMessage({ id: 'EditListingCapacityForm.serviceDetailRequired'});

        // Self Service
        const selfServiceMessage = intl.formatMessage({ id: 'EditListingCapacityForm.selfService'});
        const selfServicePlaceholderMessage = intl.formatMessage({ id: 'EditListingCapacityForm.selfServicePlaceholder'});
        //const selfServiceRequiredMessage = intl.formatMessage({ id: 'EditListingCapacityForm.selfServiceRequired'});

        // Extra Note
        const extraNoteMessage = intl.formatMessage({ id: 'EditListingCapacityForm.extraNote'});
        const extraNotePlaceholderMessage = intl.formatMessage({ id: 'EditListingCapacityForm.extraNotePlaceholder'});
        //const extraNoteRequiredMessage = intl.formatMessage({ id: 'EditListingCapacityForm.extraNoteRequired'});

        // Cut Off Time
        const cutOffTimeMessage = intl.formatMessage({ id: 'EditListingCapacityForm.cutOffTime'});
        const cutOffTimePlaceholderMessage = intl.formatMessage({ id: 'EditListingCapacityForm.cutOffTimePlaceholder'});
        const cutOffTimeRequiredMessage = intl.formatMessage({ id: 'EditListingCapacityForm.cutOffTimeRequired'});

        // Cancellation
        const cancellationMessage = intl.formatMessage({ id: 'EditListingCapacityForm.cancellation'});
        const cancellationPlaceholderMessage = intl.formatMessage({ id: 'EditListingCapacityForm.cancellationPlaceholder'});
        const cancellationRequiredMessage = intl.formatMessage({ id: 'EditListingCapacityForm.cancellationRequired'});

        const classes = classNames(css.root, className);
        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}

        <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
            autoFocus
          /> 

        <FieldCheckboxGroup 
            className={css.title} 
            id="service"
            name="service"
            label={serviceMessage}
            options={serviceOptions}
            // validate={composeValidators(required(serviceRequiredMessage))}
            />

        <FieldTextInput
            id="serviceDetail"
            name="serviceDetail"
            className={css.description}
            type="textarea"
            label={serviceDetailMessage}
            placeholder={serviceDetailPlaceholderMessage}
            // validate={composeValidators(required(serviceDetailRequiredMessage))}
          /> 

        <FieldTextInput
            id="selfService"
            name="selfService"
            className={css.description}
            type="textarea"
            label={selfServiceMessage}
            placeholder={selfServicePlaceholderMessage}
            // validate={composeValidators(required(selfServiceRequiredMessage))}
          /> 

        <FieldTextInput
            id="extraNote"
            name="extraNote"
            className={css.description}
            type="textarea"
            label={extraNoteMessage}
            placeholder={extraNotePlaceholderMessage}
            // validate={composeValidators(required(extraNoteRequiredMessage))}
          /> 


        <FieldSelect
            className={css.description}
            name="cutOffTime"
            id="cutOffTime"
            label={cutOffTimeMessage}
            validate={composeValidators(required(cutOffTimeRequiredMessage))}
          >
            <option value="">{cutOffTimePlaceholderMessage}</option>
            {cutOffTimeOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>

          <FieldSelect
            className={css.description}
            name="cancellation"
            id="cancellation"
            label={cancellationMessage}
            validate={composeValidators(required(cancellationRequiredMessage))}
          >
            <option value="">{cancellationPlaceholderMessage}</option>
            {cancellationOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>

          {/* <FieldSelect
            className={css.capacity}
            name="capacity"
            id="capacity"
            validate={capacityRequired}
          >
            <option value="">{capacityPlaceholder}</option>
            {capacityOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect> */}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingCapacityFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingCapacityFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  capacityOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  serviceOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  cancellationOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  cutOffTimeOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export default compose(injectIntl)(EditListingCapacityFormComponent);