import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink,ExternalLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.css';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    intl,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;

  const originalPrice = publicData.originalPrice && publicData.originalPrice.currency? (new Money(publicData.originalPrice.amount, publicData.originalPrice.currency)):null;

  const addOn1 = publicData.addOn?.addOn1;
  const addOn2 = publicData.addOn?.addOn2;
  const addOn3 = publicData.addOn?.addOn3;

  const addOnPrice1 = addOn1?.addOnPrice.currency? (new Money(addOn1?.addOnPrice?.amount, addOn1?.addOnPrice?.currency)):null;
  const addOnPrice2 = addOn2?.addOnPrice.currency? (new Money(addOn2?.addOnPrice?.amount, addOn2?.addOnPrice?.currency)):null;
  const addOnPrice3 = addOn3?.addOnPrice.currency? (new Money(addOn3?.addOnPrice?.amount, addOn3?.addOnPrice?.currency)):null;

  const helpCenterUrl = intl.formatMessage({ id: 'EditListingPricingPanel.helpCenterUrl' });

  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={{ 
        price,
        originalPrice,
        perUnit: publicData.perUnit,
        addOnTitle1: addOn1?.addOnTitle,
        addOnPrice1: addOnPrice1, 
        addOnTitle2: addOn2?.addOnTitle,
        addOnPrice2: addOnPrice2, 
        addOnTitle3: addOn3?.addOnTitle,
        addOnPrice3: addOnPrice3, 
      }}
      onSubmit={values => {
        const { addOnTitle1, addOnPrice1, addOnTitle2 , addOnPrice2, addOnTitle3, addOnPrice3, price, originalPrice, perUnit } = values;
        const updateValues = {
          price: price,
          publicData: { 
            perUnit:perUnit,
            originalPrice: {
              currency: originalPrice?.currency,
              amount: originalPrice?.amount,
            },
            addOn : {
              addOn1 : {
                addOnTitle: addOnTitle1,
                addOnPrice: {
                  currency: addOnPrice1?.currency,
                  amount: addOnPrice1?.amount
                }
              },
              addOn2 : {
                addOnTitle: addOnTitle2,
                addOnPrice: {
                  currency: addOnPrice2?.currency,
                  amount: addOnPrice2?.amount
                }
              },
              addOn3 : {
                addOnTitle: addOnTitle3,
                addOnPrice: {
                  currency: addOnPrice3?.currency,
                  amount: addOnPrice3?.amount
                }
              }
            }
          },
        };

        onSubmit(updateValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <div className={css.headerContainer}>
        <h1 className={css.title}>{panelTitle}</h1>
        <ExternalLink href={helpCenterUrl} className={css.helpCenter}>
            <FormattedMessage id="EditListingPricingPanel.helpCenterTitle" />
          </ExternalLink>
      </div>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,

  intl: intlShape.isRequired,
};

export default compose(injectIntl)(EditListingPricingPanel);
