import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { denormalisedResponseEntities } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getLandingData } from '../../util/dotceptApi';
import axios from 'axios';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/LandingPage/SET_INITIAL_STATE';

export const FETCH_LISTINGS_REQUEST = 'app/LandingPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/LandingPage/FETCH_LISTINGS_ERROR';

export const FETCH_CMS_SUCCESS = 'app/LandingPage/FETCH_CMS_SUCCESS';

export const FETCH_WEATHER_SUCCESS = 'app/LandingPage/FETCH_WEATHER_SUCCESS';

export const FETCH_PROMOTE_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_PROMOTE_LISTINGS_SUCCESS';
export const FETCH_NEW_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_NEW_LISTINGS_SUCCESS';
export const FETCH_HANDMADE_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_HANDMADE_LISTINGS_SUCCESS';

export const HOT_LISTING = 'hot-listing';
export const PROMOTE_LISTING = 'promote-listing';
export const NEW_LISTING = 'new-listing';
export const HANDMADE_LISTING = 'handmade-listing';
export const LANDING_CMS_DATA = 'landingCmsData';

const PROMOTE_LISTING_SEARCH = {pub_promotedListing: 'landing', per_page: 8};
const HOT_LISTING_SEARCH = {sort: 'createdAt', per_page: 12};
const HANDMADE_LISTING_SEARCH = {sort: 'createdAt', per_page: 12, pub_theme:'手工DIY'};

// ================ Reducer ================ //

const initialState = {
  fetchListingsError: null,
  currentPageResults: [],
  newListingRef: [],
  promoteListingRef: [],
  onlineListingRef: [],
  cmsData: null,
  weatherData: null,
};

const resultIds = data => data.data.data.map(l => l.id);

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsError: null,
      };
    case FETCH_LISTINGS_SUCCESS:
      return { ...state, currentPageResults: payload.listings };
    case FETCH_PROMOTE_LISTINGS_SUCCESS:
      return { ...state, promoteListingRef: resultIds(payload.listings) };
    case FETCH_CMS_SUCCESS:
      return { ...state, cmsData: payload.cmsData };
    case FETCH_WEATHER_SUCCESS:
      return { ...state, weatherData: payload.weatherData };
    case FETCH_NEW_LISTINGS_SUCCESS:
      return { ...state, newListingRef: resultIds(payload.listings) };
    case FETCH_HANDMADE_LISTINGS_SUCCESS:
      return { ...state, onlineListingRef: resultIds(payload.listings) };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchListingsError: payload };

    default:
      return state;
  }
};

export default landingPageReducer;

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const fetchListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
});

export const fetchListingsSuccess = (listings, meta) => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { listings, meta },
});

export const fetchPromoteListingsSuccess = (listings, meta) => ({
  type: FETCH_PROMOTE_LISTINGS_SUCCESS,
  payload: { listings, meta },
});

export const fetchHandmadeListingsSuccess = (listings, meta) => ({
  type: FETCH_HANDMADE_LISTINGS_SUCCESS,
  payload: { listings, meta },
});

export const fetchCMSSuccess = (cmsData) => ({
  type: FETCH_CMS_SUCCESS,
  payload: { cmsData },
});

export const fetchWeatherSuccess = (weatherData) => ({
  type: FETCH_WEATHER_SUCCESS,
  payload: { weatherData },
});

export const fetchNewListingsSuccess = (listings, meta) => ({
  type: FETCH_NEW_LISTINGS_SUCCESS,
  payload: { listings, meta },
});

export const fetchListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchCMSData = (locale) => (dispatch) => {
  return getLandingData(locale)
  .then(data => {
    dispatch(fetchCMSSuccess(data));
  })
  .catch(e => {
  });
}

export const fetchWeatherData = (l) => (dispatch) => {
  return axios.get("https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=flw&lang=tc")
  .then(data => {
    dispatch(fetchWeatherSuccess(data.data));
  })
  .catch(e => {
  });
}

export const fetchListings = (searchParams,type) => (dispatch, getState, sdk) => {
  dispatch(fetchListingsRequest());

  const { ...rest } = searchParams;
  const params = {
    ...rest,
    page: 1,
    // per_page: 8,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  return sdk.listings
    .query(params)
    .then(response => {

      dispatch(addMarketplaceEntities(response));

      if (type === NEW_LISTING) {
        dispatch(fetchNewListingsSuccess(response, response.data.meta));
      }else if(type === PROMOTE_LISTING) {
        dispatch(fetchPromoteListingsSuccess(response, response.data.meta));
      }else if(type == HANDMADE_LISTING) {
        dispatch(fetchHandmadeListingsSuccess(response, response.data.meta));
      }
      else {
        dispatch(fetchListingsSuccess(response, response.data.meta));
      }
      
      return response; 

    })
    .catch(e => {
      dispatch(fetchListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = userId => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());
  return Promise.race([
    // dispatch(fetchWeatherData()),
    dispatch(fetchListings(HOT_LISTING_SEARCH,NEW_LISTING)),
    dispatch(fetchListings(PROMOTE_LISTING_SEARCH,PROMOTE_LISTING)),
    dispatch(fetchListings(HANDMADE_LISTING_SEARCH,HANDMADE_LISTING)),
    dispatch(fetchCMSData()),
  ]);
};