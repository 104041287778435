import React from 'react';
import classNames from 'classnames';
import { ExternalLink } from '../../components';

import css from './TransactionPanel.css';

// Functional component as a helper to build AddressLinkMaybe
const AddressLinkMaybe = props => {
  const { 
    className, 
    rootClassName, 
    location, 
    geolocation, 
    showAddress, 
    googleMap,
    onlineMeetingLink,
    isOnlineExperience 
  } = props;

  const { address, building } = location || {};
  const { lat, lng } = geolocation || {};
  const hrefToGoogleMaps = googleMap
    ? `https://maps.google.com/?q=${encodeURIComponent(googleMap)}`
    : geolocation
    ? `https://maps.google.com/?q=${lat},${lng}`
    : address
    ? `https://maps.google.com/?q=${encodeURIComponent(address)}`
    : null;

  //const fullAddress = typeof building === 'string' && building.length > 0 ? `${building}, ${address}` : address;
  const googleMapAddress = `${googleMap}`;
  const rootClass= classNames(rootClassName || css.descriptionContainer);
  const classes = classNames(css.address, className);
  const highlightClasses = classNames(css.googleMap, className);

  return showAddress && hrefToGoogleMaps && !isOnlineExperience ? (
    <div className={rootClass}>
    <p className={highlightClasses}>
      <ExternalLink href={hrefToGoogleMaps}>{googleMapAddress}</ExternalLink>
    </p>
    <p className={classes}>
      {building}
    </p>
    </div>
  )
  : showAddress && isOnlineExperience? (
    <div className={rootClass}>
    <p className={highlightClasses}>
      <ExternalLink href={onlineMeetingLink}>{onlineMeetingLink}</ExternalLink>
    </p>
    <p className={classes}>
      {building}
    </p>
    </div>
  )
  : null;
};

export default AddressLinkMaybe;
